<template>
  <section class="w-full flex justify-center pt-4 pb-12 dark:text-gray-100">
    <div>
      <article class="mx-4">
        <h2 class="font-medium py-3" v-text="$t('about.aboutUs')" />
        <YoutubeVideo videoId="64K8v6cF18E" class="max-w-xs sm:max-w-xl md:max-w-full" />
      </article>
      <article>
        <h2 class="font-medium pt-6 pb-3 mx-4" v-text="$t('about.story')" />
        <div class="flex flex-wrap justify-between">
          <div class="max-w-xl mx-4">
            <h5 class="pb-2" v-text="$t('about.storyHeader')" />
            <p class="mb-6" v-text="$t('about.text1')" />
            <p class="mb-6" v-text="$t('about.text2')" />
            <p class="mb-6" v-text="$t('about.text3')" />
          </div>
          <div style="max-width: 11rem" class="space-y-6 mx-4">
            <h6 class="mb-6" v-text="$t('about.cooperation')" />
            <div
              v-for="url in [
                'aboutUs/eu.jpg',
                'aboutUs/almi.png',
                'aboutUs/vinnova.png',
                'aboutUs/VGR.png',
              ]"
              :key="url"
            >
              <img class="w-full" :src="imageUrl(url)" />
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>
<script>
import imgixClient from "../services/imgixClient";
import YoutubeVideo from "../components/shared/YoutubeVideo.vue";

export default {
  name: "About",
  components: {
    YoutubeVideo,
  },
  methods: {
    imageUrl(url) {
      return imgixClient.buildURL(url, {
        auto: "format,compress",
        ch: "DPR",
        q: 45,
        w: 176,
        fit: "clip",
      });
    },
  },
};
</script>
