<template>
  <article
    class="mb-3 w-full flex items-end"
    style="min-height: 42px;"
  >
    <!-- Filter menu -->
    <div
      class="lg:hidden h-full flex flex-col fixed -left-100 w-full top-0 bottom-0 bg-white dark:bg-gray-800 z-50 transform duration-100 ease-in"
      :class="{
        'translate-x-full': showFilter
      }"
      style="padding-bottom: 70px"
    >
      <div class="flex-grow overflow-y-scroll p-4">
        <FilterMenu
          :createURL="createURL"
          :metaData="metaData"
          :searchState="searchState"
          :showFilter="showFilter"
          :attributes="attributes"
          v-on:show-filter="showFilter = $event"
        />
      </div>
      <div class="flex-shrink-0 border-t-2 border-gray-300">
        <div v-if="activeFilters" class="p-4">
          <div
            v-text="$t('shop.activeFilters')"
            class="font-bold tracking-wider text-gray-700 dark:text-white text-xs uppercase border-b mb-1 pb-1"
          />
          <FilterMenuCurrentRefinements
            :searchState="searchState"
            :createURL="createURL"
            :attributes="attributes"
            class="border-gray-400"
          />
        </div>
        <div class="px-6">
          <button
            class="w-full main-button"
            @click="showFilter = false"
          >
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- Filter button -->
    <div class="lg:hidden flex-grow">
      <button
        class="flex items-center py-1 px-3 text-sm font-bold text-gray-700 dark:bg-gray-600 dark:border-transparent dark:text-gray-100 border outline-none rounded-md"
        @click.prevent="showFilter = true"
      >
        <svg class="w-4 h-4 fill-current mr-1" viewBox="0 0 24 24">
          <path d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
        </svg>
        {{ $t("shop.filters") }}
        <div
          class="flex justify-center items-center rounded-md ml-2"
          :class="{
            'text-gray-100 bg-gray-600': activeFilters,
            'text-gray-800 bg-gray-200': !activeFilters
          }"
          style="padding: 0 0.3rem;"
        >
          <div class="text-xs font-normal" v-text="activeFilters.toLocaleString()" />
        </div>
      </button>
    </div>
    <!-- Refinements -->
    <FilterMenuCurrentRefinements
      :searchState="searchState"
      :createURL="createURL"
      :attributes="attributes"
      class="hidden lg:flex lg:flex-grow"
    />
    <!-- Hits -->
    <div class="hidden sm:block mr-2 flex-shrink-0" v-if="!searching">
      <div class="text-sm dark:text-white">
        {{ $tc("shop.hitsResults", nbHits, { nbHits }) }}
      </div>
    </div>
    <!-- Sort -->
    <div class="flex-shrink-0 relative dark:text-white">
      <select
        class="block text-sm appearance-none bg-transparent border px-4 py-1 pr-8 rounded-md leading-tight focus:outline-none outline-none"
        :value="searchState.sort"
        @change="$router.push(createURL({ sort: $event.target.value }))"
      >
        <option
          v-for="value in sortOptions"
          :key="value"
          :value="value"
          class="text-gray-700"
          v-text="$t(`shop.sort${value.substr(11)}`)"
        />
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
    </div>
    <!-- List -->
    <div class="flex-shrink-0 hidden sm:inline-flex rounded ml-2">
      <router-link
        :to="createURL({ grid: 'gallery' })"
        v-slot="{ navigate }"
        custom
      >
        <button
          class="rounded-l-md no-underline p-1 border-t border-l border-b border-gray-300 bg-gray-100 text-gray-600 text-center outline-none focus:outline-none hover:cursor-pointer hover:bg-gray-200"
          :class="{
            'bg-gray-300': grid === 'gallery',
            'dark:bg-gray-900 dark:text-white': grid !== 'gallery'
          }"
          aria-label="gallery"
          @click="navigate"
        >
          <svg class="h-5 w-5 focus:outline-none fill-current" viewBox="0 0 24 24">
            <path d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3" />
          </svg>
        </button>
      </router-link>
      <router-link
        :to="createURL({ grid: 'list' })"
        v-slot="{ navigate }"
        custom
      >
        <button
          class="no-underline p-1 border border-gray-300 bg-gray-100 text-gray-600 text-center outline-none focus:outline-none hover:cursor-pointer hover:bg-gray-200"
          :class="{
            'bg-gray-300': grid === 'list',
            'dark:bg-gray-900 dark:text-white': grid !== 'list'
          }"
          aria-label="list"
          @click="navigate"
        >
          <svg class="h-5 w-5 focus:outline-none fill-current" viewBox="0 0 24 24">
            <path
              d="M2 14H8V20H2M16 8H10V10H16M2 10H8V4H2M10 4V6H22V4M10 20H16V18H10M10 16H22V14H10"
            />
          </svg>
        </button>
      </router-link>
      <router-link
        :to="createURL({ grid: 'compact' })"
        v-slot="{ navigate }"
        custom
      >
        <button
          class="rounded-r-md no-underline p-1 border-t border-r border-b border-gray-300 bg-gray-100 text-gray-600 text-center outline-none focus:outline-none hover:cursor-pointer hover:bg-gray-200"
          :class="{
            'bg-gray-300': grid === 'compact',
            'dark:bg-gray-900 dark:text-white': grid !== 'compact'
          }"
          aria-label="compact"
          @click="navigate"
        >
          <svg class="h-5 w-5 focus:outline-none fill-current" viewBox="0 0 24 24">
            <path
              d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z"
            />
          </svg>
        </button>
      </router-link>
    </div>
  </article>
</template>
<script>
import FilterMenuCurrentRefinements from './FilterMenuCurrentRefinements.vue'
import FilterMenu from './FilterMenu.vue'

export default {
  name: 'SearchBar',
  components: {
    FilterMenu,
    FilterMenuCurrentRefinements,
  },
  props: {
    metaData: {
      type: Object,
      required: true,
    },
    searchState: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
    searching: {
      type: Boolean,
      required: true,
    },
    baseIndex: {
      type: String,
      required: true,
    },
    sortIndexes: {
      type: Array,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      showFilter: false
    }
  },
  computed: {
    grid() {
      return this.searchState.grid
    },
    activeFilters() {
      const { query, sales, category, toggle, ...attributes } = this.searchState
      return (query ? 1 : 0) +
        (category.length ? 1 : 0) +
        toggle.length +
        sales.length +
        this.attributes.reduce((c, attribute) => c + attributes[attribute].length, 0)
    },
    nbHits() {
      return this.metaData.nbHits || 0
    },
    sortOptions() {
      return [this.baseIndex, ...this.sortIndexes]
    },
  },
};
</script>