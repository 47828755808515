<template>
  <ProductCard
    style="height: 130px"
    class="w-full block relative pr-4 border mb-1 z-10 hover:shadow-md rounded-md flex items-center bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
    :product="product"
    :index="index"
    :listId="listId"
    :listName="listName"
  >
    <template
      v-slot="{
        product: {
          thumbnail: { src, description, bgColor },
          texts: { primaryAttribute, secondaryAttribute, remainingAttributes },
          msrp,
          discount,
          bullets,
        },
        displayLabels,
      }"
    >
      <div
        class="relative flex p-2 justify-center items-center flex-shrink-0 mr-4 rounded-l-md"
        style="height: 128px; width: 150px"
        :style="!!showThumbnailBgColor && !!bgColor ? `background: ${bgColor}` : ''"
      >
        <img
          :src="image(src)"
          class="object-contain"
          :alt="`Add North 3D filament ${description}`"
        />
        <div
          v-for="({ text, classes }, index) in displayLabels"
          :key="text"
          class="absolute left-0 top-0"
          :style="`margin-top: ${index * 2}rem`"
        >
          <div
            class="h-0 relative"
            :class="classes"
            :style="`width: ${
              9 - index
            }rem; border-top-width: 28px; border-right: 10px solid transparent;`"
          >
            <div
              style="top: -29px"
              class="text-sm absolute inset-0 py-1 pl-4 overflow-visible font-medium uppercase"
              v-text="text"
            />
          </div>
        </div>
      </div>
      <div class="flex-grow whitespace-nowrap truncate overflow-hidden">
        <div class="flex items-center truncate">
          <h4 class="font-medium text-lg leading-tight" v-html="primaryAttribute" />
          <h4
            class="font-medium truncate text-lg leading-tight ml-1"
            v-if="secondaryAttribute"
            v-html="` - ${secondaryAttribute}`"
          />
        </div>
        <h5 class="font-light text-sm" v-if="remainingAttributes" v-html="remainingAttributes" />
        <div v-if="bullets.length" class="mb-2 flex-shrink flex-grow">
          <div
            class="flex flex-shrink items-center text-sm"
            v-for="(bullet, index) in bullets"
            :key="`${index}${bullet}`"
          >
            <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
              <path
                d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"
              />
            </svg>
            <p
              class="w-full whitespace-nowrap truncate overflow-hidden"
              style="min-width: 0"
              v-html="bullet"
            />
          </div>
        </div>
      </div>
      <div>
        <ProductPrice
          class="flex-shrink-0"
          :msrp="msrp"
          :currency="$store.state.store.currency"
          :discount="discount"
          :showWithVat="$store.state.store.showWithVat"
          :quantity="1"
        />
        <button
          style="min-width: 100px"
          class="mt-2 w-full main-button flex-shrink-0"
          v-text="$t('shop.view')"
        />
      </div>
    </template>
  </ProductCard>
</template>
<script>
import ProductCard from "./ProductCard.vue";
import ProductPrice from "./ProductPrice.vue";
import imgixClient from "../../services/imgixClient";

export default {
  name: "ProductCardHorizontal",
  components: {
    ProductCard,
    ProductPrice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showThumbnailBgColor: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  methods: {
    image(path) {
      return imgixClient.buildURL(path, {
        auto: "format,compress",
        ch: "DPR",
        q: 45,
        w: 150,
        fit: "clip",
      });
    },
  },
};
</script>
