<template>
  <section class="dark:text-white">
    <h2 class="font-bold mb-4" v-text="$t('account.settings')" />
    <div class="grid grid-cols-2 gap-y-10 sm:gap-y-0">
      <Login class="col-span-2 sm:col-span-1" />
      <AccountSelector
        v-if="this.$store.getters['account/multipleAccounts']"
        class="col-span-2 sm:col-span-1"
      />
    </div>
    <div v-if="$store.getters['account/loggedIn']">
    
    </div>
  </section>
</template>
<script>
import Login from './Login.vue'
import AccountSelector from './AccountSelector.vue'

export default {
  name: 'Settings',
  components: {
    Login,
    AccountSelector
  }
};
</script>
