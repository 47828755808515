<template>
  <article class="flex justify-center mt-8" v-if="!searching && pages.length">
    <div class="paginate-wrapper">
      <router-link
        v-if="!pages.includes(0)"
        :to="createURL({ page: 1 })"
        v-text="'‹‹'"
      />
      <router-link
        v-for="page in pages"
        :key="page"
        :to="createURL({ page: page + 1 })"
        :class="{
          'selected': page === currentPage
        }"
        v-text="page + 1"
      />
      <router-link
        v-if="!pages.includes(nbPages - 1)"
        :to="createURL({ page: nbPages })"
        v-text="'››'"
      />
    </div>
  </article>
</template>
<script>
export default {
  name: 'KnowledgeNavigationPagination',
  props: {
    metaData: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
    searching: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    currentPage() {
      return this.metaData.page
    },
    nbPages() {
      return this.metaData.nbPages
    },
    pages() {
      const qty = Math.min(5, this.nbPages)
      let offset = 0
      if(this.currentPage > 2 && (this.currentPage + 3) <= this.nbPages) {
        offset = this.currentPage - 2
      } else if(this.currentPage > 2 && (this.currentPage + 2) === this.nbPages) {
        offset = this.currentPage - 3
      } else if(this.currentPage > 2 && (this.currentPage + 1) === this.nbPages) {
        offset = this.currentPage - 4
      }
      return [...Array(qty).keys()].map((_, index) => index + offset)
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  jsonld() {
    const itemListElement = this.pages.map((page) => ({
      '@type': 'SiteNavigationElement',
      url: this.createURL({ page: page + 1 })
    }));
    if (!this.pages.includes(0)) {
      itemListElement.unshift({
        '@type': 'SiteNavigationElement',
        url: this.createURL({ page: 1 })
      })
    }
    if (!this.pages.includes(this.nbPages - 1)) {
      itemListElement.push({
        '@type': 'SiteNavigationElement',
        url: this.createURL({ page: this.nbPages })
      })
    }
    const jsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'ItemList',
      itemListElement: itemListElement.map((i, position) => ({ ...i, position }))
    }
    return jsonLd;
  }
};
</script>
<style scoped>
.paginate-wrapper {
  @apply inline-flex;
  @apply rounded-md;
}
.paginate-wrapper > a {
  @apply block;
  @apply no-underline;
  @apply border-t;
  @apply border-l;
  @apply border-b;
  @apply border-gray-300;
  @apply bg-gray-100;
  @apply text-gray-600;
  @apply text-center;
  width: 44px;
  padding: 0.5rem 0;
}
.dark .paginate-wrapper > a {
  @apply bg-gray-700;
  @apply text-gray-100;
}
.paginate-wrapper > a:hover {
  @apply bg-gray-200;
}
.dark .paginate-wrapper > a:hover {
  @apply bg-gray-600;
}
.paginate-wrapper > a.selected {
  @apply bg-gray-300;
  @apply font-medium;
}
.dark .paginate-wrapper > a.selected {
  @apply bg-gray-500;
}
.paginate-wrapper > a:first-child {
  @apply rounded-l-md;
}
.paginate-wrapper > a:last-child {
  @apply rounded-r-md;
  @apply border-r;
}
</style>
