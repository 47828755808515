<template>
  <section class="accordions dark:text-white">
    <div class="my-6 pt-6" v-if="overview">
      <button class="flex items-center w-full" @click="expand('filamentOverview')">
        <div>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isExpanded('filamentOverview')" d="M19,13H5V11H19V13Z" />
            <path v-else d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
        <h5 class="ml-4 font-medium" v-text="$t('product.filamentOverview')" />
      </button>
      <TransitionExpand v-if="visualOverview">
        <article v-if="isExpanded('filamentOverview')" key="filamentOverview" class="sm:px-12 pt-4">
          <div class="flex flex-wrap">
            <div>
              <p
                class="font-bold border-b pb-2"
                v-text="getSpecificationTranslated('materialProperties')"
              />
              <div class="relative" style="width: 345px; height: 260px">
                <RadarChart
                  v-if="visualOverviewRadar"
                  class="absolute inset-0"
                  :data="visualOverviewRadar"
                  :comparableData="compareVisualOverview"
                />
              </div>
            </div>
            <div class="flex-grow max-w-2xl" style="min-width: 350px">
              <p
                class="font-bold border-b pb-2"
                v-text="getSpecificationTranslated('visualProperties')"
              />
              <div class="flex mt-6 text-xs items-center w-full" v-if="visualOverview.matteness">
                <div
                  v-text="getSpecificationTranslated('glossy')"
                  class="text-right"
                  style="width: 110px"
                />
                <div class="mx-2 h-8 bg-gray-100 border border-gray-300 flex-grow relative">
                  <div
                    class="absolute top-0 bottom-0 border border-blue-400"
                    :style="`background: rgba(66,153,225, 0.3);left: ${
                      visualOverview.matteness[0] * 10
                    }%; width: ${
                      (visualOverview.matteness[1] - visualOverview.matteness[0] + 1) * 10
                    }%`"
                  />
                  <div
                    v-if="
                      comparableProduct &&
                      comparableProduct.overview &&
                      comparableProduct.overview.visualOverview.matteness
                    "
                    class="absolute top-0 bottom-0 border border-green-400"
                    :style="`background: rgba(119,211,143, 0.3);left: ${
                      comparableProduct.overview.visualOverview.matteness[0] * 10
                    }%; width: ${
                      (comparableProduct.overview.visualOverview.matteness[1] -
                        comparableProduct.overview.visualOverview.matteness[0] +
                        1) *
                      10
                    }%`"
                  />
                </div>
                <p v-text="getSpecificationTranslated('matte')" style="width: 110px" />
              </div>
              <div class="flex mt-6 text-xs items-center w-full" v-if="visualOverview.colorDepth">
                <div
                  v-text="getSpecificationTranslated('lowColorDepth')"
                  class="text-right"
                  style="width: 110px"
                />
                <div class="mx-2 h-8 bg-gray-100 border border-gray-300 flex-grow relative">
                  <div
                    class="absolute top-0 bottom-0 border border-blue-400"
                    :style="`background: rgba(66,153,225, 0.3); left: ${
                      visualOverview.colorDepth[0] * 10
                    }%; width: ${
                      (visualOverview.colorDepth[1] - visualOverview.colorDepth[0] + 1) * 10
                    }%`"
                  />
                  <div
                    v-if="
                      comparableProduct &&
                      comparableProduct.overview &&
                      comparableProduct.overview.visualOverview.colorDepth
                    "
                    class="absolute top-0 bottom-0 border border-green-400"
                    :style="`background: rgba(119,211,143, 0.3);left: ${
                      comparableProduct.overview.visualOverview.colorDepth[0] * 10
                    }%; width: ${
                      (comparableProduct.overview.visualOverview.colorDepth[1] -
                        comparableProduct.overview.visualOverview.colorDepth[0] +
                        1) *
                      10
                    }%`"
                  />
                </div>
                <p v-text="getSpecificationTranslated('highColorDepth')" style="width: 110px" />
              </div>
              <div
                class="flex mt-6 text-xs items-center w-full"
                v-if="visualOverview.layerVisibility"
              >
                <div
                  v-text="getSpecificationTranslated('layerVisibilityLow')"
                  class="text-right"
                  style="width: 110px"
                />
                <div class="mx-2 h-8 bg-gray-100 border border-gray-300 flex-grow relative">
                  <div
                    class="absolute top-0 bottom-0 border border-blue-400"
                    :style="`background: rgba(66,153,225, 0.3); left: ${
                      visualOverview.layerVisibility[0] * 10
                    }%; width: ${
                      (visualOverview.layerVisibility[1] - visualOverview.layerVisibility[0] + 1) *
                      10
                    }%`"
                  />
                  <div
                    v-if="
                      comparableProduct &&
                      comparableProduct.overview &&
                      comparableProduct.overview.visualOverview.layerVisibility
                    "
                    class="absolute top-0 bottom-0 border border-green-400"
                    :style="`background: rgba(119,211,143, 0.3);left: ${
                      comparableProduct.overview.visualOverview.layerVisibility[0] * 10
                    }%; width: ${
                      (comparableProduct.overview.visualOverview.layerVisibility[1] -
                        comparableProduct.overview.visualOverview.layerVisibility[0] +
                        1) *
                      10
                    }%`"
                  />
                </div>
                <p
                  v-text="getSpecificationTranslated('layerVisibilityHigh')"
                  style="width: 110px"
                />
              </div>
            </div>
          </div>
          <div class="mt-4">
            <p class="font-bold mb-1" v-text="$t('product.compareWith')" />
            <div class="styled-form">
              <select
                name="material"
                class="rounded-lg appearance-none border"
                placeholder=" "
                :value="compareWith"
                @change="compareWith = $event.target.value"
              >
                <option v-if="!compareWith" v-text="$t('help.selectMaterial')" :value="null" />
                <option
                  v-for="product in comparableProducts"
                  :key="product.id"
                  :value="product.id"
                  v-text="product.name"
                  :selected="compareWith === product.id"
                />
              </select>
              <label for="material" class="floating-label" v-text="$t('help.selectMaterial')" />
            </div>
          </div>
        </article>
      </TransitionExpand>
    </div>
    <div class="my-6 pt-6" v-if="specifications">
      <button class="flex items-center w-full" @click="expand('specifications')">
        <div>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isExpanded('specifications')" d="M19,13H5V11H19V13Z" />
            <path v-else d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
        <h5 class="ml-4 font-medium" v-text="$t('product.specifications')" />
      </button>
      <TransitionExpand>
        <article
          v-if="isExpanded('specifications')"
          class="sm:px-12 pt-4 specifications"
          key="specifications"
        >
          <table>
            <tbody>
              <tr v-for="{ attribute, text } in specifications" :key="attribute">
                <td v-text="getSpecificationTranslated(attribute) || ''" />
                <td v-html="formatSpecification(text)" />
              </tr>
            </tbody>
          </table>
          <!-- <div
            v-for="{ attribute, text } in specifications"
            :key="attribute"
            class="flex mb-3 pb-3"
          >
            <p v-text="getSpecificationTranslated(attribute) || ''" />
            <p v-html="formatSpecification(text)" />
          </div> -->
        </article>
      </TransitionExpand>
    </div>
    <div class="my-6 pt-6" v-if="useCases.length">
      <button class="flex items-center w-full" @click="expand('useCases')">
        <div>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isExpanded('useCases')" d="M19,13H5V11H19V13Z" />
            <path v-else d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
        <h5 class="ml-4 font-medium" v-text="$t('knowledgeBase.useCasesText')" />
      </button>
      <TransitionExpand>
        <Carousel
          v-if="isExpanded('useCases')"
          :items="useCases"
          listName="Product Use cases"
          listId="product_use_cases"
          key="product_use_cases"
          type="article"
        >
          <template v-slot:item="{ item, index, listName, listId }">
            <KnowledgeCardDefault
              :key="item.slug"
              :article="item"
              :index="index"
              :listId="listId"
              :listName="listName"
              class="mx-2"
            />
          </template>
        </Carousel>
      </TransitionExpand>
    </div>
    <div class="my-6 pt-6" v-if="supportArticles.length">
      <button class="flex items-center w-full" @click="expand('supportArticles')">
        <div>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isExpanded('supportArticles')" d="M19,13H5V11H19V13Z" />
            <path v-else d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
        <h5 class="ml-4 font-medium" v-text="$t('product.supportArticles')" />
      </button>
      <TransitionExpand>
        <article
          v-if="isExpanded('supportArticles')"
          class="sm:px-12 pt-4 flex flex-wrap"
          key="supportArticles"
        >
          <KnowledgeCardDefault
            v-for="article in supportArticles"
            :key="article.slug"
            :article="article"
            class="m-2"
          />
        </article>
      </TransitionExpand>
    </div>
    <div class="my-6 pt-6" v-if="files.length">
      <button class="flex items-center w-full" @click="expand('files')">
        <div>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isExpanded('files')" d="M19,13H5V11H19V13Z" />
            <path v-else d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
        <h5 class="ml-4 font-medium" v-text="$t('product.files')" />
      </button>
      <TransitionExpand>
        <article v-if="isExpanded('files')" class="sm:px-12 pt-4" key="files">
          <ul class="border border-gray-300 rounded-md w-full">
            <li v-for="file in files" :key="file.name" class="pl-3 pr-4 py-3 text-sm leading-5">
              <div class="flex w-full flex-wrap md:flex-nowrap items-center justify-between">
                <div class="flex-shrink flex items-center w-full">
                  <svg class="flex-shrink-0 h-4 w-4 text-gray-500 fill-current" viewBox="0 0 24 24">
                    <path
                      d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
                    />
                  </svg>
                  <div class="flex-shrink truncate" v-text="file.name" />
                </div>
                <a
                  :href="imgixFileSrc(file)"
                  target="_blank"
                  class="md:ml-4 flex-shrink-0 font-medium text-gray-700 dark:text-gray-100 dark:hover:text-gray-200 hover:text-gray-800 transition duration-150 ease-in-out"
                  v-text="$t('product.download')"
                  :download="file.fileName"
                  :type="file.mimeType"
                />
              </div>
              <div class="text-xs" v-text="$t(`languages.${file.language}`)" />
              <div v-if="file.description" class="text-xs italic" v-text="file.description" />
            </li>
          </ul>
        </article>
      </TransitionExpand>
    </div>
  </section>
</template>
<script>
import TransitionExpand from "../products/TransitionExpand.vue";
import RadarChart from "../products/RadarChart.vue";
import KnowledgeCardDefault from "../knowledge/KnowledgeCardDefault.vue";
import Article from "@/services/article.js";
import Carousel from "../store/Carousel.vue";

const specificationBadges = {
  fda: "FDA",
  fsc: "FSC",
  reach: "REACH",
  rohs: "ROHS",
  bphFree: "No BPH",
};

export default {
  name: "Accordions",
  components: {
    TransitionExpand,
    RadarChart,
    KnowledgeCardDefault,
    Carousel,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    variation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded: ["filamentOverview", "specifications", "useCases", "supportArticles", "files"],
      compareWith: null,
      specificationBadges,
    };
  },
  computed: {
    articles() {
      return this.$store.state.content.articles
        .filter((i) => i.tags.includes(this.product.name))
        .map((a) => new Article(a));
    },
    comparableProducts() {
      return this.$store.state.content.products.filter(
        (i) =>
          i.id !== this.product.id &&
          i.overview &&
          i.overview.visualOverview &&
          !i.name.includes("Bundle")
      );
    },
    size() {
      return this.variation && this.variation.attributes ? this.variation.attributes.size : null;
    },
    type() {
      return this.variation ? this.variation.type : null;
    },
    overview() {
      return Object.keys(this.product.overview || {}).length > 0 ? this.product.overview : null;
    },
    visualOverview() {
      if (!this.overview || !this.overview.visualOverview) return null;
      return this.overview.visualOverview;
    },
    visualOverviewRadar() {
      if (!this.visualOverview) return null;
      const {
        warpResistance,
        layerAdhesion,
        impactResistance,
        heatDeflection,
        easeOfUse,
      } = this.visualOverview;
      return { warpResistance, layerAdhesion, impactResistance, heatDeflection, easeOfUse };
    },
    comparableProduct() {
      return this.comparableProducts.find((i) => i.id === this.compareWith);
    },
    compareVisualOverview() {
      if (!this.comparableProduct) return null;
      const {
        warpResistance,
        layerAdhesion,
        impactResistance,
        heatDeflection,
        easeOfUse,
      } = this.comparableProduct.overview.visualOverview;
      return { warpResistance, layerAdhesion, impactResistance, heatDeflection, easeOfUse };
    },
    specifications() {
      const specs = {};
      if (this.type === "filament") {
        Object.assign(specs, this.product.filamentSpecifications);
      }
      Object.assign(specs, this.product.otherSpecifications || {});
      if (!Object.values(specs).length) return null;
      const specificSpecifications = Object.entries(specs)
        .filter(([_, text]) => !!text || text === 0)
        .map(([attribute, text]) => ({ attribute, text }));
      if (this.product.filamentSpecifications) {
        specificSpecifications.unshift(
          { attribute: "diameterTolerance", text: "±0.025mm" },
          {
            attribute: "spoolSize",
            text: `
              200 x 52 x 41 mm (<=500 g)<br>
              200 x 52 x 53 mm (750 g)<br>
              200 x 52 x 67 mm (1000 g)<br>
              300 x 51.5 x 103 mm (2300 g)<br>
              350 x 52 x 101 mm (5000 g)<br>
              355 x 36 x 167 mm (8000g)
            `,
          },
          {
            attribute: "emptyWeight",
            text: `
              175 g (<=500 g)<br>
              207 g (750 g)<br>
              238 g (1000 g)<br>
              600 g (2300 g)<br>
              780 g (5000 g)<br>
              1030 g (8000g)
            `,
          },
          {
            attribute: "spoolMaterial",
            text: `
              HIPS (up to ~80°C)
            `,
          }
        );
        if (this.size)
          specificSpecifications.splice(2, 0, {
            attribute: "weightFilament",
            text: `${this.size}g`,
          });
      }
      return specificSpecifications;
    },
    supportArticles() {
      return this.articles.filter(({ type }) => ["Cheat sheets", "Print guides"].includes(type));
    },
    useCases() {
      return this.articles.filter(({ type }) => type === "Use cases");
    },
    files() {
      return this.product.assets.filter(({ type }) => type === "file");
    },
  },
  methods: {
    imgixFileSrc(file) {
      return `https://storage.googleapis.com/addnorth-com.appspot.com/${file.fullPath}`;
    },
    isExpanded(item) {
      return this.expanded.includes(item);
    },
    expand(view) {
      const index = this.expanded.findIndex((i) => i === view);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(view);
      }
    },
    getSpecificationTranslated(text) {
      if (this.specificationBadges[text]) return this.specificationBadges[text];
      const foundText = this.$te(`specifications.${text}`);
      return foundText ? this.$t(`specifications.${text}`) : text;
    },
    formatSpecification(text) {
      if (typeof text === "number") {
        const val = {
          1: this.getSpecificationTranslated("low"),
          2: this.getSpecificationTranslated("medium"),
          3: this.getSpecificationTranslated("high"),
        };
        return val[text];
      }
      if (typeof text === "boolean") {
        return text
          ? this.getSpecificationTranslated("yes")
          : this.getSpecificationTranslated("no");
      }
      return this.getSpecificationTranslated(text);
    },
  },
  watch: {
    product: {
      immediate: true,
      handler() {
        this.compareWith = null;
      },
    },
  },
};
</script>
<style scoped lang="scss">
.accordions {
  > div:not(:first-of-type) {
    @apply border-t;
  }

  table > tbody {
    tr:not(:last-of-type) {
      @apply border-b;
    }

    td {
      @apply py-3;
    }

    tr > td:first-child {
      @apply mr-4;
      @apply text-gray-500;
    }

    tr > td:last-child {
      @apply whitespace-nowrap;
    }
  }
}
</style>
