<script>
import imgixClient from "../../services/imgixClient";

export default {
  name: "DynamicContent",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    listId: {
      type: String,
      required: true,
    },
    listName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    publishedDate: {
      type: Number,
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  render(h) {
    const dynamic = this.content.map((i) => this.dynamicRender(i, h));
    return h(
      "article",
      {
        attrs: {
          id: "article",
        },
        class: {
          relative: true,
        },
      },
      [...this.baseContent(h), ...dynamic]
    );
  },
  methods: {
    baseContent(h) {
      const baseContent = [
        h(
          "div",
          {
            class: {
              absolute: true,
              "right-0": true,
              "top-0": true,
              "no-print": true,
              hidden: true,
              "lg:block": true,
            },
          },
          [
            h(
              "button",
              {
                class: {
                  "outline-none": true,
                  "focus:outline-none": true,
                  "object-contain": true,
                  "rounded-full": true,
                  "hover:bg-gray-200": true,
                  "dark:hover:bg-gray-500": true,
                  "p-2": true,
                },
                on: {
                  click: this.print,
                },
              },
              [
                h(
                  "svg",
                  {
                    class: {
                      "w-8": true,
                      "h-8": true,
                      "text-gray-700": true,
                      "dark:text-gray-100": true,
                      "fill-current": true,
                      "rounded-full": true,
                    },
                    attrs: {
                      viewBox: "0 0 24 24",
                    },
                  },
                  [
                    h("path", {
                      attrs: {
                        d:
                          "M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ];
      if (this.title) {
        baseContent.push(
          h(
            "h1",
            {
              class: {
                "text-center": true,
                "font-medium": true,
                "text-3xl": true,
                "sm:text-4xl": true,
                "lg:mx-10": true,
                article: true,
              },
            },
            this.title
          )
        );
      }
      if (this.publishedDate) {
        baseContent.push(
          h(
            "h5",
            {
              class: {
                "text-center": true,
                "font-light": true,
                "pb-2": true,
                article: true,
              },
            },
            new Date(this.publishedDate).toISOString().substr(0, 10)
          )
        );
      }
      if (this.tags) {
        baseContent.push(
          h(
            "div",
            {
              class: {
                flex: true,
                "flex-wrap": true,
                "justify-center": true,
                "pb-8": true,
              },
            },
            this.tags.map((tag) =>
              h(
                "router-link",
                {
                  key: tag,
                  class: {
                    "text-xs": true,
                    "font-medium": true,
                    uppercase: true,
                    "rounded-lg": true,
                    "bg-gray-200": true,
                    "hover:bg-gray-300": true,
                    "text-gray-700": true,
                    "dark:bg-gray-500": true,
                    "dark:hover:bg-gray-600": true,
                    "dark:text-white": true,
                    "px-2": true,
                    "py-1": true,
                    "m-1": true,
                    article: true,
                  },
                  attrs: {
                    rel: "nofollow",
                    to: `/knowledge/browse?tags=${tag}`,
                  },
                },
                tag
              )
            )
          )
        );
      }
      return baseContent;
    },
    print() {
      let el = document.getElementById("article");
      while (el.tagName !== "BODY") {
        el.classList.add("print");
        el = el.parentNode;
      }
      window.print();
    },
    dynamicRender({ type, text = "", content = [], attrs = {}, marks = [] }, h) {
      if (type === "heading") {
        const opts = {
          class: {
            article: true,
          },
        };
        let textEl = content.find((i) => i.type === "text");
        if (textEl) {
          opts.attrs = { id: textEl.text.replace(/[^\w]/, "") };
        }
        return h(`h${attrs.level}`, this.format(attrs, type), [
          h(
            "a",
            opts,
            content.map((c) => this.dynamicRender(c, h))
          ),
        ]);
      }
      if (type === "image") {
        return h(
          "figure",
          {
            class: { relative: true, "h-auto": true, "mx-auto": true, table: true, article: true },
          },
          [
            h(
              "img",
              {
                attrs: {
                  title: attrs.title,
                  alt: attrs.alt,
                  src: imgixClient.buildURL(attrs.src.substr(27), {
                    auto: "format,compress",
                    ch: "Width,Viewport-Width,DPR",
                    q: 45,
                    "max-h": 600,
                  }),
                },
                style: { "max-height": "600px" },
              },
              []
            ),
            attrs.caption
              ? h(
                  "figcaption",
                  {
                    class: { absolute: true, "top-full": true, "left-0": true, "right-0": true },
                  },
                  attrs.caption
                )
              : null,
          ]
        );
      }
      if (type === "paragraph")
        return h(
          "p",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "bulletList")
        return h(
          "ul",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "orderedList")
        return h(
          "ul",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "listItem")
        return h(
          "li",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "blockquote")
        return h(
          "blockquote",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "horizontalRule")
        return h(
          "hr",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "hardBreak")
        return h(
          "br",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "table")
        return h(
          "table",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "tableRow")
        return h(
          "tr",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "tableHeader")
        return h(
          "th",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "tableCell")
        return h(
          "td",
          this.format(attrs, type),
          content.map((c) => this.dynamicRender(c, h))
        );
      if (type === "iframe" || type === "youtube") {
        return h("div", { class: { flex: true, "justify-center": true } }, [
          h(
            "div",
            {
              class: { relative: true, "h-0": true, "w-4/5": true },
              style: { "padding-bottom": "56.25%" },
            },
            [
              h("iframe", {
                class: {
                  absolute: true,
                  "h-full": true,
                  "w-full": true,
                  "top-0": true,
                  "left-0": true,
                },
                attrs,
              }),
            ]
          ),
        ]);
      }
      // if (type === 'mention') {
      //   console.log('here')
      //   return this.$api2.get(`catalog/variations/${attrs.id}`, {
      //     params: {
      //       language: process.env.VUE_APP_LANGUAGE
      //     }
      //   })
      //   .then(({ data: variation }) => {
      //     console.log(variation)
      //     return h(ProductCardDefault, {
      //       props: {
      //         product: new Variation(this.$store, variation),
      //         listName: this.listName,
      //         listId: this.listId,
      //         index: 0
      //       },
      //       class: {
      //         'text-left': true,
      //         'product-card': true,
      //         article: true
      //       }
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     return ''
      //   })
      // }
      if (type === "text") return this.formatText(h, text, marks);
    },
    formatText(h, text, marks) {
      const link = marks.find(({ type }) => type === "link");
      if (link && link.attrs && link.attrs.href) {
        const { attrs } = link;
        if (/^http/.test(attrs.href)) {
          return h(
            "a",
            { attrs, class: { underline: true, "font-medium": true, article: true } },
            text
          );
        } else if (attrs.href.startsWith("#")) {
          return h(
            "a",
            {
              attrs: { href: attrs.href },
              class: { underline: true, "font-medium": true, article: true },
            },
            text
          );
        } else {
          return h(
            "router-link",
            {
              attrs: { to: attrs.href },
              class: { underline: true, "font-medium": true, article: true },
            },
            text
          );
        }
      } else {
        const opts = {
          class: {
            article: true,
          },
        };
        marks.forEach(({ type: markType }) => {
          if (markType === "bold") opts.class["font-bold"] = true;
          if (markType === "italic") opts.class["italic"] = true;
          if (markType === "strike") opts.class["line-through"] = true;
        });
        return h("span", opts, text);
      }
    },
    format(attrs, type) {
      const opts = {
        class: {
          article: true,
        },
        attrs: {},
        style: {},
      };
      if (attrs.textAlign === "left") {
        opts.class["text-left"] = true;
      }
      if (attrs.textAlign === "center") {
        opts.class["text-center"] = true;
      }
      if (attrs.textAlign === "right") {
        opts.class["text-right"] = true;
      }
      if (attrs.textAlign === "justify") {
        opts.class["text-justify"] = true;
      }
      if (type === "bulletList") {
        opts.class["list-inside"] = true;
        opts.class["list-disc"] = true;
      }
      if (type === "orderedList") {
        opts.class["list-inside"] = true;
        opts.class["list-decimal"] = true;
      }
      if (type === "blockquote") {
        opts.class["font-medium"] = true;
      }
      if (attrs.colspan > 1) {
        opts.attrs.colspan = attrs.colspan;
      }
      if (attrs.rowspan > 1) {
        opts.attrs.rowspan = attrs.rowspan;
      }
      return opts;
    },
  },
};
</script>
<style>
h2.article,
h3.article,
h4.article,
h5.article,
h6.article {
  @apply leading-relaxed;
  page-break-after: avoid;
}

p.article,
ul.article {
  @apply pb-4;
}
ul.article {
  page-break-inside: avoid;
}
table.article p {
  @apply pb-0;
}
li.article {
  @apply pb-2;
}

blockquote.article {
  @apply my-10;
  @apply py-2;
}

figure.article {
  @apply mt-6;
  @apply mb-12;
}

figure.article > figcaption {
  @apply text-sm;
  @apply font-medium;
}

.product-card.article {
  @apply my-4;
}

table.article {
  @apply bg-gray-200;
  @apply rounded-md;
  @apply mt-1;
  @apply mb-6;
  page-break-inside: avoid;
}
.dark table.article {
  @apply bg-gray-600;
}
table.article tr:first-child > th,
table.article tr:first-child > td {
  @apply pt-2;
}
table.article tr:first-child > th {
  @apply border-b;
}
table.article tr:last-child > td {
  @apply pb-2;
}
table.article tr > th,
table.article tr > td {
  @apply p-1;
  @apply pr-4;
}
table.article tr > th:first-child,
table.article tr > td:first-child {
  @apply pl-4;
}
ul.article p {
  @apply inline;
}

@page {
  size: A4 portrait;
}

@media print {
  body *:not(.print, #article, #article *) {
    @apply hidden;
  }
  #article * {
    @apply bg-transparent;
    @apply text-black;
  }
  #article *.no-print {
    @apply hidden;
  }
}
</style>
