<template>
  <router-link
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
    :to="getURL"
    v-slot="{ href, navigate }"
    custom
  >
    <a
      itemprop="url"
      rel="nofollow"
      :href="href"
      @click="navigate"
      class="text-sm flex items-center align-center text-gray-700 dark:text-gray-100"
      :class="{
        'font-bold': isRefined,
      }"
    >
      <div v-text="sale" class="mr-1 overflow-hidden truncate" />
      <div
        class="flex justify-center items-center rounded-md text-gray-600 bg-gray-200"
        style="padding: 0 0.3rem;"
      >
        <div class="text-xs font-normal" v-text="(count || 0).toLocaleString()" />
      </div>
    </a>
  </router-link>
</template>
<script>
export default {
  name: 'FilterMenuSales',
  props: {
    count: {
      type: Number,
      required: true,
    },
    sale: {
      type: String,
      required: true,
    },
    searchState: {
      type: Object,
      required: true
    },
    createURL: {
      type: Function,
      required: true
    },
  },
  computed: {
    isRefined() {
      return this.searchState.sales.includes(this.sale)
    },
    getURL() {
      if (this.isRefined) {
        const sales = [...this.searchState.sales]
        const index = sales.findIndex(i => i === this.toggle)
        sales.splice(index, 1)
        return this.createURL({ sales, page: 1 })
      }
      return this.createURL({ sales: [...this.searchState.sales, this.sale], page: 1 })
    },
  },
};
</script>
