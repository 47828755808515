<template>
  <section class="text-gray-500">
    <div
      v-if="metaData.hierarchy.length"
      v-text="$t('shop.categories')"
      class="font-bold tracking-wider text-gray-700 dark:text-white text-xs uppercase border-b mb-1 pb-1"
    />
    <FilterMenuHierarchy
      :hierarchy="metaData.hierarchy"
      :createURL="createURL"
      :selectedCategory="searchState.category"
      class="mb-4"
    />
    <FilterMenuToggle
      v-for="toggle in metaData.toggles"
      :key="toggle.attribute"
      :createURL="createURL"
      :searchState="searchState"
      :toggle="toggle"
    />
    <FilterMenuSales
      v-for="(count, sale) in metaData.sales"
      :key="sale"
      :createURL="createURL"
      :searchState="searchState"
      :sale="sale"
      :count="count"
    />
    <FilterMenuRefinement
      v-for="[attribute, refinements] in metaData.refinements"
      :key="attribute"
      :attribute="attribute"
      :refinements="refinements"
      :createURL="createURL"
      :searchState="searchState"
      class="mt-4"
    />
  </section>
</template>
<script>
import FilterMenuHierarchy from './FilterMenuHierarchy.vue';
import FilterMenuToggle from './FilterMenuToggle.vue';
import FilterMenuSales from './FilterMenuSales.vue';
import FilterMenuRefinement from './FilterMenuRefinement.vue';

export default {
  name: 'FilterMenu',
  components: {
    FilterMenuHierarchy,
    FilterMenuToggle,
    FilterMenuSales,
    FilterMenuRefinement,
  },
  props: {
    metaData: {
      type: Object,
      required: true,
    },
    searchState: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    }
  },
};
</script>