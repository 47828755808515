<template>
  <section class="my-12 pb-12 flex flex-col items-center dark:text-gray-100">
    <div class="max-w-md">
      <h1 class="font-medium text-center" v-text="$t('rnd.header')" />
      <h2 class="text-base text-center" v-text="$t('rnd.subheader')" />
    </div>
    <article class="flex flex-wrap mt-24">
      <div class="max-w-xs flex items-center mx-6 my-2">
        <img :src="readdSrc" alt="RE-ADD" class="w-full" />
      </div>
      <div class="max-w-md mx-6 my-2">
        <h4 class="font-medium" v-text="$t('rnd.readdHeader')" />
        <p class="mb-2" v-html="$t('rnd.readdText')" />
        <router-link
          to="/re-add"
          class="text-lg text-orange-600 font-medium underline"
          v-text="$t('rnd.readMore')"
        />
      </div>
    </article>
    <article class="flex flex-wrap mt-24">
      <div class="max-w-xs flex items-center mx-6 my-2">
        <img :src="celluSrc" alt="CelluPLA" class="w-full" />
      </div>
      <div class="max-w-lg mx-6 my-2 lg:order-first">
        <h4 class="font-medium" v-text="$t('rnd.celluHeader')" />
        <p v-html="$t('rnd.celluText')" />
      </div>
    </article>
    <article class="flex flex-wrap mt-24">
      <div class="max-w-xs flex flex-wrap items-center mx-6 my-2">
        <img :src="edmSrc" alt="EDM additive" class="w-full" />
        <img :src="smartSrc" alt="Smart advanced manufacturing" class="w-full" />
      </div>
      <div class="max-w-md mx-6 my-2">
        <h4 class="font-medium" v-text="$t('rnd.edmHeader')" />
        <p class="mb-2" v-html="$t('rnd.edmText')" />
      </div>
    </article>
  </section>
</template>
<script>
import imgixClient from '../services/imgixClient';

export default {
  name: 'Rnd',
  data() {
    return {
      readdSrc: imgixClient.buildURL('rnd/readd.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 320, fit: 'clip'
      }),
      celluSrc: imgixClient.buildURL('rnd/cellupla.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 320, fit: 'clip'
      }),
      edmSrc: imgixClient.buildURL('rnd/edm.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 320, fit: 'clip'
      }),
      smartSrc: imgixClient.buildURL('rnd/smart.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 320, fit: 'clip'
      }),
    };
  },
};
</script>
