<template>
  <div v-if="breadcrumbs.length" class="flex w-full overflow-x-auto">
    <div
      v-for="({ path, text}, index) in breadcrumbs"
      :key="path"
      class="flex items-center flex-shrink-0"
    >
      <router-link
        :to="path"
        v-text="text"
        class="capitalize"
      />
      <svg v-if="path && index < (breadcrumbs.length - 1)" class="h-4 w-4 mx-1" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KnowledgeArticleBreadcrumb',
  props: {
    article: {
      type: Object,
      required: true
    },
  },
  computed: {
    breadcrumbs() {
      const path = (this.article.category ? this.article.category.split('>') : [])
        .reduce((c, text, index, paths) => {
          c.push({
            text,
            path: this.$store.getters['shared/encodeUrl'](`/knowledge/browse/${this.article.type}/${paths.slice(0, index + 1).join('/')}`)
          })
          return c
        }, []);
      path.unshift(
        { text: 'Home', path: '/' },
        { text: 'Knowledge base', path: '/knowledge/browse' },
        { text: this.article.type, path: `/knowledge/browse/${this.article.type}` },
      )
      return path;
    },
  },
  jsonld() {
    if (!this.breadcrumbs.length) return {};
    return {
      '@context': 'http://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: this.breadcrumbs.map(({ text: name, path }, position) => ({
        "@type": "ListItem",
        position,
        name,
        item: `https://addnorth.${process.env.VUE_APP_DOMAIN}${path}`
      }))
    }
  },
};
</script>
