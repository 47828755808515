<template>
  <section class="w-full dark:text-white">
    <KnowledgeHeader :collapsed="true" />
    <div class="flex w-full">
      <div
        class="py-8 p-12 pr-24 w-auto flex-shrink-0 hidden lg:block bg-gray-200 dark:bg-gray-700"
      >
        <h6 class="text-base mb-2 text-gray-700 dark:text-white" v-text="'Topics'" />
        <KnowledgeNavigationItem
          :hierarchy="hierarchy"
          :selectedCategory="categories"
          :createURL="createURL"
          class="mb-4 ml-2"
        />
        <h6 class="text-base mt-8 mb-2 text-gray-700 dark:text-white" v-text="'Tags'" />
        <KnowledgeNavigationTags
          :tags="tagsRefinement"
          :selectedTags="searchState.tags"
          :createURL="createURL"
          class="mb-4 ml-2"
        />
      </div>
      <div class="flex-grow px-4 lg:px-12 pb-16">
        <KnowledgeCurrentRefinements
          class="my-4"
          :searchState="searchState"
          :createURL="createURL"
        />
        <article class="mb-16">
          <div v-if="searching" class="h-40 flex justify-center items-center pb-10 max-w-full">
            <div class="spinner large inline text-sm pt-12" v-text="'Loading...'" />
          </div>
          <div v-else class="w-full flex justify-center">
            <div v-if="!results.length" class="text-center">
              <div class="my-6" v-text="'No articles found, try remove filters'" />
            </div>
            <div class="space-y-4 sm:space-y-0 sm:flex sm:flex-wrap sm:-m-4">
              <div v-for="article in results" :key="article.objectId" class="mx-4">
                <KnowledgeCardDefault :article="article" class="mx-auto" />
              </div>
            </div>
          </div>
        </article>
        <KnowledgeNavigationPagination
          :metaData="metaData"
          :createURL="createURL"
          :searching="searching"
        />
      </div>
    </div>
  </section>
</template>
<script>
import searchClient from "@/services/algolia";
import KnowledgeHeader from "@/components/knowledge/KnowledgeHeader.vue";
import KnowledgeNavigationItem from "@/components/knowledge/KnowledgeNavigationItem.vue";
import KnowledgeNavigationTags from "@/components/knowledge/KnowledgeNavigationTags.vue";
import KnowledgeCurrentRefinements from "@/components/knowledge/KnowledgeCurrentRefinements.vue";
import KnowledgeCardDefault from "@/components/knowledge/KnowledgeCardDefault.vue";
import KnowledgeNavigationPagination from "@/components/knowledge/KnowledgeNavigationPagination.vue";
import Article from "@/services/article.js";

export default {
  name: "KnowledgeBase",
  components: {
    KnowledgeHeader,
    KnowledgeNavigationItem,
    KnowledgeNavigationTags,
    KnowledgeCurrentRefinements,
    KnowledgeCardDefault,
    KnowledgeNavigationPagination,
  },
  props: {
    categories: {
      type: Array,
      default: null,
    },
    query: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hierarchy: [],
      tagsRefinement: [],
      results: [],
      hitsPerPage: 8,
      indexName: "articles",
      searching: false,
      metaData: {
        nbHits: 0,
        page: 0,
        nbPages: 0,
      },
    };
  },
  computed: {
    searchState() {
      return {
        query: decodeURIComponent(this.query || ""),
        page: this.page,
        tags: this.tags.map(decodeURIComponent),
        categories: this.categories.map(decodeURIComponent),
      };
    },
  },
  methods: {
    createURL(change) {
      const { query, page, tags = [], categories = [] } = { ...this.searchState, ...change };
      const route = {
        path: "/knowledge/browse",
        query: {},
      };
      if (categories.length) route.path = `${route.path}/${encodeURI(categories.join("/"))}`;
      if (query) route.query.q = query;
      if (page && page > 1) route.query.page = page;
      if (tags.length) route.query.tags = tags.map(encodeURIComponent).join("~");
      return this.$router.resolve(route).href;
    },
    parseResults({ results }) {
      const [{ hits, page, nbPages, nbHits }] = results;
      // Results
      this.results.splice(0, this.results.length);
      this.results.push(...hits.map((h) => new Article(h)));

      // Pagination
      this.metaData.page = page;
      this.metaData.nbPages = nbPages;
      this.metaData.nbHits = nbHits;

      // Hiearchy
      this.hierarchy.splice(0, this.hierarchy.length);
      const hierarchyQueries = results.slice(0, this.categories.length + 1);
      hierarchyQueries.reverse();
      const hierarchyArray = hierarchyQueries.reduce((carry, { facets }, index) => {
        const categories = Object.entries(facets[`categories.lvl${index}`] || {}).reduce(
          (c, [key, value]) =>
            this.$store.getters["content/reduceSplitCategory"](c, key.split(">"), [], value),
          carry
        );
        categories.sort((a, b) => this.$store.getters["content/sortCategories"](a.label, b.label));
        return categories;
      }, []);
      this.hierarchy.push(...hierarchyArray);

      // Tags
      const { facets } = this.searchState.tags.length ? results[results.length - 1] : results[0];
      this.tagsRefinement.splice(0, this.tagsRefinement.length);
      if (facets.tags) {
        this.tagsRefinement.push(...Object.entries(facets.tags));
      }
    },
    queries() {
      // Base query
      const { query = "", page = 1, categories = [], tags = [] } = this.searchState;

      // Filters
      const filters = [
        `language:${process.env.VUE_APP_LANGUAGE}`,
        `environment:${process.env.VUE_APP_ALGOLIA_ENV || process.env.VUE_APP_NODE_ENV}`,
        "published:true",
      ].join(" AND ");

      // Facets
      const refineFacets = ["tags"];
      const categoryFacets = ["categories.lvl0"];
      for (let i = 1; i <= categories.length; i++) {
        categoryFacets.push(`categories.lvl${i}`);
      }
      const facets = [...refineFacets, ...categoryFacets];
      // Facet filters
      const refineFacetFilters = [];
      if (tags.length) refineFacetFilters.push(tags.map((i) => `tags:${i}`));

      const categoryFacetFilters = [];
      if (categories.length)
        categoryFacetFilters.push([
          `categories.lvl${categories.length - 1}: ${categories.join(">")}`,
        ]);
      const facetFilters = [...refineFacetFilters, ...categoryFacetFilters];
      const baseQuery = {
        indexName: this.indexName,
        query,
        params: {
          page: page - 1,
          hitsPerPage: this.hitsPerPage,
          filters,
          distinct: true,
          facets,
          facetingAfterDistinct: true,
          maxValuesPerFacet: 1000,
          facetFilters,
        },
      };
      const hierarchalQueries = [];
      if (categories.length) {
        // Add hiearchy support queries
        hierarchalQueries.push(
          ...categories.reduce((c, _, index, arr) => {
            const hierachyFacetFilters = [...refineFacetFilters];
            if (index > 0 && index === arr.length - 1) {
              hierachyFacetFilters.push(
                `categories.lvl${index - 1}:${arr.slice(0, index).join(">")}`
              );
            }
            return [
              ...c,
              {
                indexName: this.indexName,
                query: "",
                params: {
                  page: 0,
                  hitsPerPage: 1,
                  filters,
                  distinct: true,
                  facetingAfterDistinct: true,
                  facets: [...Array(index + 1).keys()].map((i) => `categories.lvl${i}`),
                  facetFilters: hierachyFacetFilters,
                  maxValuesPerFacet: 1000,
                },
              },
            ];
          }, [])
        );
        hierarchalQueries.reverse();
      }
      const refineQuery = [];
      // Add attributes support query
      if (tags.length) {
        refineQuery.push({
          indexName: this.indexName,
          query,
          params: {
            page: 0,
            hitsPerPage: 1,
            filters,
            distinct: true,
            facetingAfterDistinct: true,
            facets: refineFacets,
            maxValuesPerFacet: 1000,
            facetFilters: categoryFacetFilters,
          },
        });
      }
      return [baseQuery, ...hierarchalQueries, ...refineQuery];
    },
    search() {
      this.searching = true;
      searchClient
        .search(this.queries())
        .then(this.parseResults)
        .then(() => {
          if (this.searchState.query.length) {
            gtag("event", "search", {
              search_term: this.searchState.query,
            });
          }
        })
        .catch(() => {
          if (window.Rollbar)
            window.Rollbar.error(`Algolia knowledge base error: ${error.message}`, { error });
        })
        .finally(() => {
          this.searching = false;
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.search();
      },
    },
  },
  metaInfo: {
    title: "Knowledge base",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Use cases, tips & trix, print guides, cheat sheets, troubleshooting, FAQ, glossary, specifications & files to help you get the perfect 3D-print",
      },
      {
        vmid: "ogtitle",
        name: "og:title",
        property: "og:title",
        content: "Knowledge base - add:north",
      },
      {
        vmid: "ogimagealt",
        name: "og:image:alt",
        property: "og:image:alt",
        content: "Knowledge base - add:north",
      },
      {
        vmid: "twittertitle",
        name: "twitter:title",
        property: "twitter:title",
        content: "Knowledge base - add:north",
      },
      {
        vmid: "ogdescription",
        name: "og:description",
        property: "og:description",
        content:
          "Use cases, tips & trix, print guides, cheat sheets, troubleshooting, FAQ, glossary, specifications & files to help you get the perfect 3D-print",
      },
      {
        vmid: "twitterdescription",
        name: "twitter:description",
        property: "twitter:description",
        content:
          "Use cases, tips & trix, print guides, cheat sheets, troubleshooting, FAQ, glossary, specifications & files to help you get the perfect 3D-print",
      },
    ],
  },
};
</script>
