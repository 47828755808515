<template>
  <section class="p-4 dark:text-white">
    <ul class="my-4 flex items-center text-xs">
      <li v-for="({ text, link }, index) in breadcrumbs" :key="text" class="flex items-center">
        <router-link v-if="index < breadcrumbs.length - 1" :to="link" v-text="text" />
        <p v-else v-text="text" />
        <svg v-if="index < breadcrumbs.length - 1" class="h-4 w-4" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          />
        </svg>
      </li>
    </ul>
    <ul class="space-y-2 mb-12">
      <li
        v-for="({ link, label, spaceTop = false }, index) in categoriesWithDefault"
        :key="`${link}${label}${index}`"
      >
        <router-link
          class="h-12 bg-white text-gray-700 rounded-md flex justify-between items-center px-4 py-2 border custom"
          :class="{ 'mt-16': spaceTop }"
          :to="link"
        >
          <p class="font-medium text-sm" v-text="label" />
          <svg class="h-6 w-6" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </router-link>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  name: "BrowseProducts",
  props: {
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fixed: [
        {
          text: this.$t("store.products.latestProducts"),
          link: this.$store.getters["shared/encodeUrl"]("/shop"),
        },
        {
          text: this.$tc("store.products.bestSeller", 2),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { sort: "bestSellers" }),
        },
        {
          text: this.$tc("store.products.staffPick", 2),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { sort: "staffPicks" }),
        },
        {
          text: this.$t("common.onSale"),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { toggle: "onSale" }),
        },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      const selected = this.category && this.category.length ? this.category.split("/") : [];
      return [
        { text: this.$t("common.home"), link: this.$store.getters["shared/encodeUrl"]("/") },
        {
          text: this.$tc("store.products.product", 2),
          link: this.$store.getters["shared/encodeUrl"]("/browse"),
        },
        ...selected
          .reduce((c, text, index, paths) => {
            c.push({ text, link: paths.slice(0, index + 1).join("/") });
            return c;
          }, [])
          .map(({ text, link }) => ({
            text,
            link: this.$store.getters["shared/encodeUrl"](`/browse/${link}`),
          })),
      ];
    },
    categories() {
      let categories = [...this.$store.getters["content/siteProductCategories"]];
      const selected = this.category && this.category.length ? this.category.split("/") : [];
      selected.forEach((path) => {
        categories = categories.find((i) => i.label === path)?.children || [];
      });
      return categories.map((i) => ({
        label: i.label,
        link: this.$store.getters["shared/encodeUrl"](
          i.children.length ? `/browse/${i.path}` : `/shop/${i.path}`,
          i.query || {}
        ),
        spaceTop: i.spaceTop || false,
      }));
    },
    categoriesWithDefault() {
      const categories = [...this.categories];
      if (categories.length > 1) {
        categories.unshift({
          label: this.$t("common.showAll"),
          link: this.$store.getters["shared/encodeUrl"](`/shop/${this.category || ""}`, {}),
        });
        categories.push(
          {
            label: this.$t("store.products.latestProducts"),
            link: this.$store.getters["shared/encodeUrl"](`/shop/${this.category || ""}`, {}),
            spaceTop: true,
          },
          {
            label: this.$tc("store.products.bestSeller", 2),
            link: this.$store.getters["shared/encodeUrl"](`/shop/${this.category || ""}`, {
              sort: "bestSellers",
            }),
          },
          {
            label: this.$tc("store.products.staffPick", 2),
            link: this.$store.getters["shared/encodeUrl"](`/shop/${this.category || ""}`, {
              sort: "staffPicks",
            }),
          },
          {
            label: this.$t("common.onSale"),
            link: this.$store.getters["shared/encodeUrl"](`/shop`, {
              toggle: "onSale",
            }),
          }
        );
      }
      return categories;
    },
  },
  metaInfo() {
    return {
      title: this.breadcrumbs[this.breadcrumbs.length - 1].text,
    };
  },
  jsonld() {
    return [
      {
        "@context": "http://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 0,
            name: this.$t("common.home"),
            item: `https://addnorth.${process.env.VUE_APP_DOMAIN}`,
          },
          {
            "@type": "ListItem",
            position: 1,
            name: this.$tc("store.products.product", 2),
            item: `https://addnorth.${process.env.VUE_APP_DOMAIN}/browse`,
          },
          ...this.breadcrumbs.map((item, position) => ({
            "@type": "ListItem",
            position,
            name: item.text,
            item: `https://addnorth.${process.env.VUE_APP_DOMAIN}${item.link}`,
          })),
        ],
      },
      {
        "@context": "https://schema.org/",
        "@type": "ItemList",
        itemListElement: this.categories.map(({ link, label: name }, position) => ({
          "@type": "SiteNavigationElement",
          position,
          name,
          description: name,
          url: `https://addnorth.${process.env.VUE_APP_DOMAIN}${link}`,
        })),
      },
    ];
  },
};
</script>
