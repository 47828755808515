<template>
  <article class="dark:text-white">
    <h2 class="font-bold mx-4" v-text="$t('account.agreements')" />
    <div class="bg-gray-300 dark:bg-gray-800 bg-opacity-25 shadow-md p-6 rounded-sm">
      <div class="font-bold uppercase mb-2" v-text="$tc('store.products.product', 2)" />
      <ul class="list-none text-sm text-gray-700">
        <li
          v-for="({ productId, discountPercentage }, index) in agreements"
          :key="productId"
          class="p-2 flex flex-wrap rounded-sm"
          :class="{
            'bg-gray-200': index % 2 === 0,
            'bg-gray-50': index % 2 !== 0
          }"
        >
          <div class="flex-shrink-0 mr-4" v-text="productId" />
          <div class="flex-shrink-0 flex-grow text-right" v-text="`-${discountPercentage}%`" />
        </li>
      </ul>
      <p
        class="mt-4 text-sm"
        v-text="$t('account.agreementsText')"
      />
    </div>
  </article>
</template>
<script>
export default {
  name: 'AccountAgreements',
  computed: {
    agreements() {
      return this.$store.getters['account/account'].agreements;
    },
  },
};
</script>