<template>
  <div
    class="flex flex-wrap items-center"
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
  >
    <div
      v-for="({ label, url }) in refinements"
      :key="label"
      class="flex flex-wrap -mx-1 -my-1"
    >
      <div class="flex justify-center items-center align-center rounded-md text-gray-700 bg-gray-300 dark:bg-gray-100 text-xs font-normal py-2 px-3 mx-2 my-2">
        <div class="text-xs font-normal mr-1" v-text="label" />
        <router-link
          :to="url"
          class="flex justify-center items-center align-center rounded-md"
          v-slot="{ navigate, href }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            itemprop="url"
            rel="nofollow"
            aria-label="clear"
          >
            <svg class="h-4 w-4 focus:outline-none" viewBox="0 0 24 24">
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FilterMenuCurrentRefinements',
  props: {
    searchState: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    }
  },
  computed: {
    refinements() {
      const { query, category, toggle, sales, ...attributes } = this.searchState
      const refinements = []
      if (query) refinements.push({ label: `"${query}"`, url: this.createURL({ query: null, page: 1 }) })
      if (category.length) refinements.push({ label: category.join(' > '), url: this.createURL({ category: [], page: 1 }) })
      if (toggle.length) refinements.push(...toggle.map((i, _index, toggles) => {
        const newToggle = [...toggles]
        const index = newToggle.findIndex(j => j === i)
        newToggle.splice(index, 1)
        return { label: this.$t(`common.${i}`), url: this.createURL({ toggle: newToggle, page: 1 }) }
      }))
      if (sales.length) refinements.push(...sales.map((label, _index, sales) => {
        const newSales = [...sales]
        const index = newSales.findIndex(j => j === label)
        newSales.splice(index, 1)
        return { label: label, url: this.createURL({ sales: newSales, page: 1 }) }
      }))
      this.attributes.forEach(attribute => {
        if (attributes[attribute].length) {
          refinements.push({
            label: attributes[attribute].map(i => this.$store.getters['content/displayAttribute'](attribute, i)).join(' + '),
            url: this.createURL({ [attribute]: [], page: 1 })
          })
          
        }
      })
      return refinements
    }
  },
};
</script>
