<template>
  <li>
    <router-link
      :to="item.productLink"
      class="flex pr-3 md:pr-4 flex-basis-20 justify-center items-center"
      style="height: 80px"
    >
      <img
        :src="
          imgixClient.buildURL(item.thumbnail.src, {
            auto: 'format,compress',
            ch: 'DPR',
            q: 45,
            fit: 'clip',
            h: 80,
          })
        "
        class="max-h-full object-contain"
      />
    </router-link>
    <div class="flex-basis-80 flex justify-start flex-grow flex-shrink-0">
      <div class="flex-basis-40 flex-grow flex-shrink-0 pr-3 md:pr-4" style="height: 66px">
        <router-link
          :to="item.productLink"
          class="font-medium text-sm md:text-base custom"
          v-text="item.name"
        />
        <div class="flex">
          <div class="text-xs text-gray-400" v-text="`SKU: ${item.sku}`" />
          <div
            v-if="item.discontinued"
            class="ml-2 text-xs text-red-400"
            v-text="`(${$t('product.discontinued')})`"
          />
        </div>
      </div>
      <div
        class="absolute right-0 flex-shrink-0 transform translate-y-10 mt-0 pr-3 md:pr-4 md:static md:transform-none md:translate-y-0"
        style="flex-basis: 4rem"
      >
        <div
          v-if="index === 0"
          class="absolute top-0 transform -translate-y-6 text-gray-500 text-xs font-bold uppercase tracking-wide hidden md:block dark:text-white"
          v-text="$t('store.items.quantity')"
          style="translateY(calc(-100% - .3rem))"
        />
        <div class="flex">
          <button
            class="w-6 h-6 flex items-center justify-center border rounded-md"
            :class="{
              'border-gray-300 hover:bg-gray-200 text-gray-500': item.quantity > 1,
              'border-gray-200 text-gray-300 hover:cursor-auto': item.quantity <= 1,
            }"
            :disabled="item.quantity <= 1"
            @click="
              $store.commit('cart/ADD_TO_CART', { ...item.toCart(), quantity: item.quantity - 1 })
            "
          >
            <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
              <path d="M19,13H5V11H19V13Z" />
            </svg>
          </button>
          <div class="text-center w-8" v-text="item.quantity" />
          <button
            class="w-6 h-6 flex items-center justify-center border rounded-md"
            :class="{
              'border-gray-300 hover:bg-gray-200 text-gray-500':
                item.quantity + 1 <= (item?.availability?.maxQtyToOrder || 0),
              'border-gray-200 text-gray-300 hover:cursor-auto':
                item.quantity + 1 > (item?.availability?.maxQtyToOrder || 0),
            }"
            :disabled="item.quantity + 1 > (item?.availability?.maxQtyToOrder || 0)"
            @click="
              $store.commit('cart/ADD_TO_CART', { ...item.toCart(), quantity: item.quantity + 1 })
            "
          >
            <svg class="w-4 h-4 fill-current text-gray-500" viewBox="0 0 24 24">
              <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="mb-2 flex-shrink-0" style="flex-basis: 6rem">
        <div
          v-if="index === 0"
          class="absolute top-0 transform -translate-y-6 text-gray-500 text-xs font-bold uppercase tracking-wide hidden md:block text-right pr-3 right-0 dark:text-white"
          v-text="$t('store.items.price')"
          style="translateY(calc(-100% - .3rem))"
        />
        <ProductPrice
          :msrp="item.msrp"
          :currency="$store.state.store.currency"
          :discount="item.discount"
          :showWithVat="$store.state.store.showWithVat"
          :quantity="item.quantity"
          size="small"
          :showVatText="true"
          :showSales="false"
        />
      </div>
    </div>
    <div
      class="flex-basis-100 sm:absolute sm:bottom-0 sm:mb-3 sm:pl-3 sm:inset-x-0 sm:flex sm:justify-end"
    >
      <div class="flex items-center space-x-4 sm:flex-basis-80 justify-between md:pr-3">
        <Inventory
          :variation="item"
          :qtyIncCart="item.quantity"
          :small="true"
          class="sm:flex-basis-40"
        />
        <button
          @click.prevent="$store.commit('cart/REMOVE_FROM_CART', item.id)"
          class="flex items-center sm:flex-basis-40 md:flex-basis-auto text-gray-600 hover:text-gray-400 font-medium"
        >
          <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
            <path
              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
            />
          </svg>
          <p class="text-xs ml-1" v-text="$t('store.items.remove')" />
        </button>
      </div>
    </div>
  </li>
</template>
<script>
import Inventory from "@/components/store/Inventory.vue";
import imgixClient from "@/services/imgixClient";
import ProductPrice from "./ProductPrice.vue";

export default {
  name: "CartItem",
  components: {
    Inventory,
    ProductPrice,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imgixClient,
    };
  },
};
</script>
