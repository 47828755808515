<template>
  <div class="flex flex-wrap justify-between">
    <div
      v-for="[attribute, refinements] in facets"
      :key="attribute"
      :class="attribute === 'color' ? 'w-full' : 'w-auto flex-grow'"
      class="mr-4"
    >
      <div class="flex mb-2">
        <p class="text-gray-500 dark:text-gray-200 mr-1" v-text="`${$t(`common.${attribute}`)}:`" />
        <p
          v-if="attributeSelected(attribute)"
          class="font-medium dark:text-white"
          v-text="showAttribute(attribute, selectedAttributes[attribute])"
        />
        <p v-else class="font-medium text-red-400" v-text="$t('product.noAttributeSelected')" />
      </div>
      <div v-if="attribute === 'color'" class="flex flex-wrap items-end -m-1">
        <router-link
          v-for="refinement in refinements"
          :key="refinement"
          :to="createURL(attribute, refinement)"
          class="rounded-sm text-sm m-1 custom"
          :style="`background: ${colors[refinement]}`"
          :class="{
            'w-10 h-10 border-gray-900 dark:border-gray-100 border-2 text-white': refinementSelected(
              attribute,
              refinement
            ),
            'mt-3 w-8 h-8 border border-gray-400 dark:border-gray-100 hover:cursor-pointer': !refinementSelected(
              attribute,
              refinement
            ),
          }"
          :aria-label="refinement"
        />
      </div>
      <div v-else class="flex flex-wrap">
        <router-link
          v-for="refinement in refinements"
          :key="refinement"
          :to="createURL(attribute, refinement)"
          v-text="showAttribute(attribute, refinement)"
          class="px-2 py-1 mb-3 mr-3 rounded-md text-sm border border-gray-900 dark:border-gray-100 whitespace-nowrap custom"
          :class="{
            'bg-black text-white dark:bg-white dark:text-black': refinementSelected(
              attribute,
              refinement
            ),
            'hover:cursor-pointer dark:text-white': !refinementSelected(attribute, refinement),
          }"
        />
      </div>
      <div class="border-t my-4 w-full" />
    </div>
  </div>
</template>
<script>
import imgixClient from "../../services/imgixClient";

export default {
  name: "Attributes",
  props: {
    productName: {
      type: String,
      required: true,
    },
    variations: {
      type: Array,
      required: true,
    },
    selectedAttributes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    facets() {
      let facets = this.variations.reduce((c, { attributes = {} }) => {
        Object.entries(attributes || {})
          .filter(([_k, v]) => v)
          .forEach(([attribute, value]) => {
            if (!c[attribute]) c[attribute] = [];
            if (!c[attribute].includes(value)) {
              c[attribute].push(value);
            }
          });
        return c;
      }, {});
      facets = Object.entries(facets);
      facets.sort(
        ([a], [b]) =>
          this.$store.state.content.attributes.indexOf(a) -
          this.$store.state.content.attributes.indexOf(b)
      );
      facets = facets.map(([attribute, values]) => {
        values.sort((a, b) => this.$store.getters["content/sortAttributes"](a, b, attribute));

        // values.sort((a, b) => {
        //   if (typeof a === 'number') return a - b
        //   return a.localeCompare(b)
        // })
        return [attribute, values.map((i) => i.toString())];
      });
      return facets;
    },
    colors() {
      return this.variations
        .filter((i) => i.attributes && i.attributes.color)
        .reduce((c, { attributes: { color }, colorHex, pattern }) => {
          let value = colorHex || "#FFF";
          if (pattern && pattern.imgixPath)
            value = `url(${imgixClient.buildURL(pattern.imgixPath, {
              auto: "format,compress",
              ch: "DPR",
              q: 45,
              w: 32,
            })})`;
          c[color] = value;
          return c;
        }, {});
    },
  },
  methods: {
    areEqualShallow(a, b) {
      for (let key in a) {
        if (Boolean(a[key]) && (!(key in b) || a[key].toString() !== b[key].toString())) {
          return false;
        }
      }
      for (let key in b) {
        if (Boolean(b[key]) && (!(key in a) || a[key].toString() !== b[key].toString())) {
          return false;
        }
      }
      return true;
    },
    createURL(attribute, refinement) {
      const newAttributes = { ...this.selectedAttributes };
      newAttributes[attribute] = refinement;
      const variation = this.variations.find(({ attributes = {}, name }, i) => {
        return this.areEqualShallow(attributes, newAttributes);
      });
      if (variation) return this.$store.getters["shared/encodeUrl"](variation.productLink);
      return this.$store.getters["shared/encodeUrl"](`/product/${this.productName}`, newAttributes);
    },
    attributeSelected(attribute) {
      return typeof this.selectedAttributes[attribute] !== "undefined";
    },
    refinementSelected(attribute, refinement) {
      return (this.selectedAttributes[attribute] || "").toString() === refinement.toString();
    },
    showAttribute(attribute, value) {
      return this.$store.getters["content/displayAttribute"](attribute, value);
    },
  },
};
</script>
