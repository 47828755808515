<template>
  <div class="flex flex-col items-center lg:items-start bg-gray-100 lg:bg-background">
    <div class="bg-white w-full lg:hidden flex justify-center">
      <div class="max-w-lg w-full px-2 sm:px-0 py-3">
        <router-link to="/">
          <img
            :src="
              imgixClient.buildURL('logos/an_logo.svg', {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                w: 152,
                fit: 'clip',
              })
            "
            alt="add:north 3D filaments"
            style="width: 152px; height: 40px"
            width="152"
            height="40"
          />
        </router-link>
      </div>
    </div>
    <div
      class="px-0 lg:px-4 lg:py-12 xl:px-8 w-full border-t border-b lg:border-0 border-gray-300 flex flex-col items-center"
    >
      <div class="max-w-xl lg:max-w-lg w-full px-2 lg:px-0">
        <h5 class="font-medium hidden lg:block pb-2 border-b" v-text="$t('common.cart')" />
        <div class="py-4 lg:hidden flex items-center" @click="show = !show">
          <svg class="w-5 h-5 mr-1" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M7 22q-.825 0-1.413-.588T5 20q0-.825.588-1.413T7 18q.825 0 1.413.588T9 20q0 .825-.588 1.413T7 22Zm10 0q-.825 0-1.413-.588T15 20q0-.825.588-1.413T17 18q.825 0 1.413.588T19 20q0 .825-.588 1.413T17 22ZM6.15 6l2.4 5h7l2.75-5H6.15ZM5.2 4h14.75q.575 0 .875.513t.025 1.037l-3.55 6.4q-.275.5-.738.775T15.55 13H8.1L7 15h12v2H7q-1.125 0-1.7-.988t-.05-1.962L6.6 11.6L3 4H1V2h3.25l.95 2Zm3.35 7h7h-7Z"
            />
          </svg>
          <div class="text-sm underline truncate" v-text="$t('store.navigation.showProducts')" />
          <div class="flex-grow">
            <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24">
              <path v-if="show" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
              <path v-else d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </div>
          <div class="flex-shrink-0 text-right font-medium text-gray-900">
            {{
              meta.totals.grandTotal
                | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
            }}
          </div>
        </div>
        <ul
          class="list-none mb-6 divide-y divide-gray-200"
          :class="{
            'hidden lg:block': !show,
            'lg:block': show,
          }"
        >
          <li
            class="flex py-2 items-center"
            v-for="{
              sku,
              name,
              deliveryDate,
              thumbnail,
              quantity,
              salesType,
              salesName,
              discountPercentage,
              msrp,
            } in products"
            :key="sku"
          >
            <div class="mr-2 flex-shrink-0" style="width: 50px">
              <img
                :src="
                  imgixClient.buildURL(thumbnail || 'placeholder.svg', {
                    auto: 'format,compress',
                    ch: 'DPR',
                    q: 45,
                    fit: 'clip',
                    h: 40,
                  })
                "
                class="object-contain border rounded-lg"
                style="max-height: 40px"
              />
            </div>
            <div class="flex-shrink flex-grow overflow-hidden truncate mr-2">
              <div class="flex-grow flex-shrink font-medium truncate text-sm" v-text="name" />
              <div class="h-4 text-xs flex items-center">
                <div
                  class="rounded-full mr-2 w-2 h-2"
                  :class="{
                    'bg-green-400': !deliveryDate,
                    'bg-yellow-600': !!deliveryDate,
                  }"
                />
                <div
                  v-text="
                    !deliveryDate
                      ? $t('common.inStock')
                      : $t('store.shipping.estimatedDelivery', { deliveryDate })
                  "
                />
              </div>
            </div>
            <div class="flex-shrink-0 flex items-center">
              <div v-text="`${quantity} x `" class="text-sm mr-1" />
              <ProductPrice
                :msrp="msrp"
                :currency="meta.currency"
                :salesName="salesName"
                :salesType="salesType"
                :discountPercentage="discountPercentage"
                :showWithVat="meta.showWithVat"
                :quantity="1"
                size="small"
                :showVatText="false"
                :showSales="false"
              />
            </div>
          </li>
          <li class="py-2" v-if="meta.deliveryDate">
            <div
              class="pl-2 text-sm border-l-4 border-red-400"
              v-text="$t('checkout.isBackOrder')"
            />
          </li>
          <li v-if="!info.company">
            <Coupon :checkoutId="checkoutId" :meta="meta" :api="api" class="py-6" />
            <CheckoutPoints :checkoutId="checkoutId" :meta="meta" :info="info" :api="api" />
          </li>
          <li class="py-2">
            <div class="flex-shrink-0 flex justify-between">
              <div
                class="flex-grow pr-4 font-medium text-gray-900"
                v-text="$t('store.items.total')"
              />
              <div class="flex-shrink-0 text-right">
                <div
                  class="font-medium text-gray-900"
                  v-text="
                    $c(
                      activePoints.length
                        ? meta.totals.grandTotalPrePoints
                        : meta.totals.grandTotal,
                      { currency: meta.currency, showWithVat: false, precision: 2 }
                    )
                  "
                />
                <div
                  class="text-sm text-gray-600 leading-tight"
                  v-text="$t(meta.includeVat ? 'store.vat.incVat' : 'store.vat.exVat')"
                />
              </div>
            </div>
            <div class="mt-2" v-if="activePoints.length">
              <div
                v-for="{ pointId, usedPoints } in activePoints"
                :key="pointId"
                class="flex justify-between text-red-400 text-sm"
              >
                <div class="flex-grow pr-4" v-text="'add:points'" />
                <div class="flex-shrink-0 text-right">
                  {{
                    -usedPoints
                      | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
                  }}
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-between">
                <div
                  class="flex-grow pr-4 font-medium text-gray-900"
                  v-text="$t('store.items.toPay')"
                />
                <div class="flex-shrink-0 text-right font-medium text-gray-900">
                  {{
                    meta.totals.grandTotal
                      | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
                  }}
                </div>
              </div>
            </div>
          </li>
          <li class="py-2 flex justify-between text-gray-600 w-full text-sm">
            <div
              class="flex-grow pr-4"
              v-text="
                $tc('store.items.totalItems', meta.itemsQuantity, { items: meta.itemsQuantity })
              "
            />
            <div class="flex-shrink-0 text-right">
              {{
                meta.totals.subTotal
                  | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
              }}
              <div class="text-xs text-gray-500 leading-none" v-text="$t('store.vat.exVat')" />
            </div>
          </li>
          <li
            class="py-2 flex justify-between text-red-400 text-sm"
            v-if="meta.totals.discountTotal > 0"
          >
            <div class="flex-gow pr-4" v-text="$t('common.discount')" />
            <div class="flex-shrink-0 text-right">
              {{
                -meta.totals.discountTotal
                  | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
              }}
            </div>
          </li>
          <li class="py-2 flex justify-between text-gray-600 text-sm">
            <div class="flex-grow pr-4" v-text="$t('store.shipping.shipping')" />
            <div
              v-if="meta.totals.shippingCost === null"
              class="flex-shrink-0 text-right text-xs leading-5"
              v-text="$t('store.shipping.shippingCalculatedLater')"
            />
            <div
              v-else-if="meta.totals.shippingCost === 0"
              class="flex-shrink-0 text-right text-xs leading-5"
              v-text="$t('common.free')"
            />
            <div v-else class="flex-shrink-0 text-right">
              {{
                meta.totals.shippingCost
                  | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
              }}
            </div>
          </li>
          <li
            class="py-2 flex justify-between text-gray-600 text-sm"
            v-if="meta.totals.paymentMethodCost"
          >
            <div class="flex-grow pr-4" v-text="$t('store.payment.paymentFee')" />
            <div class="flex-shrink-0 text-right">
              {{
                meta.totals.paymentMethodCost
                  | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
              }}
            </div>
          </li>
          <li class="py-2 flex justify-between text-gray-600 text-sm">
            <div class="flex-grow pr-4 capitalize flex items-end">
              <div v-text="`${$t('store.vat.vat')} (25%)`" />
              <div
                v-if="details.billing.country === 'NO' && !info.company && meta.includeVat"
                class="ml-2 text-xxs"
                v-text="'Norwegian VAT (VOEC)'"
              />
            </div>
            <div class="flex-shrink-0 text-right">
              {{
                meta.totals.vat
                  | currency({ currency: meta.currency, showWithVat: false, precision: 2 })
              }}
            </div>
          </li>
          <li v-if="meta.addClubLevel">
            <div class="py-2 flex items-center border bg-gray-100 rounded-md mt-6">
              <svg viewBox="0 0 24 24" class="w-6 h-6 mx-2">
                <path
                  d="M4.86,17.4c0.14,0.14,0.29,0.26,0.46,0.36c-0.24-0.18-1-0.84-0.32-2.91c0.44-1.34,1.13-2.52,2.68-4.27
                c0.07-0.08,0.15-0.16,0.22-0.24c0.03-0.03,0.06-0.06,0.09-0.09c0.04-0.05,0.09-0.09,0.13-0.14C8.16,10.07,8.2,10.03,8.23,10
                c0.04-0.04,0.07-0.07,0.11-0.1c0.04-0.04,0.09-0.08,0.13-0.12C8.5,9.74,8.53,9.72,8.56,9.69c0.05-0.04,0.1-0.09,0.15-0.13
                c0.02-0.02,0.05-0.04,0.07-0.06c0.06-0.05,0.11-0.09,0.17-0.14c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05,0.13-0.1,0.19-0.15
                c0,0,0.01-0.01,0.01-0.01c0.65-0.48,1.29-0.83,1.93-1.07v0c0.66-0.26,1.31-0.4,1.91-0.46c-0.36,0.04-0.73,0.1-1.11,0.2
                c0.56-0.08,1.34-0.04,1.67,0.76c0.55,1.31-0.51,3.58-1.34,4.8c-0.06,0.08-0.11,0.17-0.17,0.25c-0.02,0.03-0.04,0.06-0.06,0.09
                c-0.04,0.06-0.08,0.11-0.13,0.17c-0.02,0.03-0.05,0.06-0.07,0.09c-0.05,0.07-0.1,0.14-0.16,0.2c-0.01,0.02-0.03,0.03-0.04,0.05
                c-0.07,0.09-0.15,0.18-0.22,0.27c0,0-0.01,0.01-0.01,0.01c-0.07,0.09-0.15,0.18-0.23,0.26c0,0.01-0.01,0.01-0.01,0.02
                c-0.9,1.02-1.99,1.98-3.03,2.57h0.01c-0.84,0.47-1.66,0.71-2.35,0.54C5.4,17.84,5.1,17.66,4.86,17.4z M23.09,17.39L23.09,17.39
                c0,0-0.01-0.01-0.01-0.02c-0.79-1.75-1.78-3.93-2.73-6.04c-0.15-0.32-3.46-7.73-3.46-7.73S15.28-0.08,12,0
                C8.35,0.09,7.12,3.61,7.12,3.61S5.43,7.5,3.9,10.96v0.01c-0.79,1.72-2.44,5.36-2.76,6.02c-0.95,1.93-1.26,3.39-1.1,4.38
                c0.23,1.12,0.94,2.14,1.94,2.46c1.17,0.38,2.29,0.06,3.58-0.72c0.02-0.01,0.04-0.02,0.06-0.03c0.1-0.06,0.19-0.12,0.29-0.18
                c1.91-1.18,4.88-2.37,7.68-1.81c2.98,0.6,4.55,2.93,6.78,2.93c1.52,0,2.92-0.92,3.41-2.13C24.51,20.27,23.22,17.63,23.09,17.39z"
                />
              </svg>
              <div v-if="meta.accountId && meta.addClub" class="flex-shrink-0 mr-2">
                <div class="text-xxs tracking-wide" v-text="$t('addclub.tier')" />
                <div class="text-xs font-medium" v-text="`${meta.addClubLevel.toUpperCase()}`" />
              </div>
              <div
                class="text-xs mr-2"
                v-html="
                  $t(
                    meta.accountId && meta.addClub
                      ? 'checkout.pointsToEarn'
                      : 'checkout.pointsPossibleToEarn',
                    {
                      clubLevel: meta.addClubLevel.toUpperCase(),
                      pointsToEarn: meta.pointsToEarn.toString(),
                    }
                  )
                "
              />
            </div>
          </li>
          <li v-for="{ id, message } in $store.state.content.messages" :key="id">
            <div v-text="message" class="text-xs p-2 border bg-gray-100 rounded-md mt-6" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import imgixClient from "@/services/imgixClient";
import ProductPrice from "@/components/store/ProductPrice.vue";
import Coupon from "./Coupon.vue";
import CheckoutPoints from "./CheckoutPoints.vue";
export default {
  name: "CheckoutProducts",
  components: {
    ProductPrice,
    Coupon,
    CheckoutPoints,
  },
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imgixClient,
      show: false,
    };
  },
  computed: {
    activePoints() {
      return this.meta.addClubActivePoints.filter(({ active }) => active);
    },
  },
};
</script>
