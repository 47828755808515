<template>
  <ul v-if="refinements.length">
    <li
      v-text="$t(`common.${attribute}`)"
      class="font-bold tracking-wider text-gray-700 dark:text-white text-xs uppercase border-b mb-1 pb-1"
    />
    <li
      v-for="[refinement, count] in items"
      :key="refinement"
      class="mb-1"
      itemscope
      itemtype="https://schema.org/SiteNavigationElement"
    >
      <router-link
        itemprop="url"
        :to="getURL(refinement)"
        v-slot="{ navigate, href }"
        custom
      >
        <a
          itemprop="url"
          rel="nofollow"
          :href="href"
          @click="navigate"
          class="text-sm flex items-center align-center text-gray-700 dark:text-gray-100"
          :class="{
            'font-bold': isRefined(refinement),
          }"
        >
          <p v-text="$store.getters['content/displayAttribute'](attribute, refinement)" class="mr-1 overflow-hidden truncate" />
          <div
            class="flex justify-center items-center rounded-md text-gray-600 bg-gray-200"
            style="padding: 0 0.3rem;"
          >
            <div class="text-xs font-normal" v-text="(count || 0).toLocaleString()" />
          </div>
        </a>
      </router-link>
    </li>
    <li v-if="refinements.length > 5">
      <button
        class="hover:cursor-pointer text-xs text-gray-600 dark:text-gray-200 underline outline-none focus:outline-none"
        v-text="showAll ? $t('common.showLess') : $t('common.showAll')"
        @click="showAll = !showAll"
      />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'FilterMenuRefinement',
  props: {
    attribute: {
      type: String,
      required: true,
    },
    refinements: {
      type: Array,
      required: true,
    },
    searchState: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showAll: false
    }
  },
  computed: {
    items() {
      if (!this.showAll) return this.refinements.slice(0, 4)
      return this.refinements
    }
  },
  methods: {
    isRefined(refinement) {
      return this.searchState[this.attribute].includes(refinement)
    },
    getURL(refinement) {
      const selected = [...this.searchState[this.attribute]]
      if (this.isRefined(refinement)) {
        const index = selected.findIndex(i => i === refinement)
        selected.splice(index, 1)
      } else {
        selected.push(refinement)
      }
      return this.createURL({ [this.attribute]: selected, page: 1 })
    },
  },
};
</script>
