<template>
  <ul v-if="tags.length">
    <li
      v-for="[tag, count] in items"
      :key="tag"
      class="mb-1"
      itemscope
      itemtype="https://schema.org/SiteNavigationElement"
    >
      <router-link
        itemprop="url"
        :to="getURL(tag)"
        v-slot="{ navigate, href }"
        custom
      >
        <a
          itemprop="url"
          rel="nofollow"
          :href="href"
          @click="navigate"
          class="text-sm flex items-center align-center text-gray-700 dark:text-gray-100"
          :class="{
            'font-bold': isRefined(tag),
          }"
        >
          <p v-text="`#${tag}`" class="mr-1" />
          <div
            class="flex justify-center items-center rounded-md text-gray-100 bg-gray-600 dark:bg-gray-100 dark:text-gray-700"
            style="padding: 0 0.3rem;"
          >
            <div class="text-xs font-normal" v-text="(count || 0).toLocaleString()" />
          </div>
        </a>
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'KnowledgeNavigationTags',
  props: {
    tags: {
      type: Array,
      required: true,
    },
    selectedTags: {
      type: Array,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
  },
  computed: {
    items() {
      const tags = [...this.tags];
      tags.sort(([a], [b]) => a.localeCompare(b, 'sv'))
      return tags
    }
  },
  methods: {
    isRefined(tag) {
      return this.selectedTags.includes(tag)
    },
    getURL(tag) {
      const selected = [...this.selectedTags]
      if (this.isRefined(tag)) {
        const index = selected.findIndex(i => i === tag)
        selected.splice(index, 1)
      } else {
        selected.push(tag)
      }
      return this.createURL({ tags: selected, page: 1 })
    },
  },
};
</script>
