<template>
  <div class="mb-6" v-if="accountAndPoints">
    <div
      class="font-medium text-sm"
      v-text="$t('checkout.usePoints')"
    />
    <div class="flex gap-4 my-2">
      <button
        v-for="{ pointId, active, netPoints } in meta.addClubActivePoints"
        :key="pointId"
        class="shadow w-24 h-16 flex items-center justify-center outline-none focus:outline-none hover:shadow-md relative"
        :class="{
          'bg-green-100': active,
          'bg-gray-100 hover:bg-green-50': !active
        }"
        @click="togglePoint(pointId)"
      >
        <div
          v-text="`${netPoints.toLocaleString('sv')} p`"
          class="text-lg font-medium"
        />
        <div v-if="active" class="absolute top-0 right-0 mr-1 mt-1">
          <svg class="w-5 h-5 fill-current text-green-800" viewBox="0 0 24 24">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckoutPoints',
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
  },
  computed: {
    accountAndPoints() {
      return this.info.accountType === 'account' && !this.info.company && this.meta.accountId && this.meta.addClub && this.meta.addClubActivePoints.length
    }
  },
  methods: {
    togglePoint(pointId) {
      this.api('checkoutPoints', { pointId }, this.checkoutId)
    }
  },
};
</script>
