var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('form',{staticClass:"flex space-x-4 w-full justify-end",on:{"submit":function($event){$event.preventDefault();return _vm.applyCoupon.apply(null, arguments)}}},[_c('div',{staticClass:"flex-grow styled-form",staticStyle:{"max-width":"150px"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.code),expression:"code",modifiers:{"trim":true}}],staticClass:"w-full rounded-md",class:{
          error: _vm.error,
          'bg-green-100': _vm.meta.couponName,
        },attrs:{"name":"coupon","type":"text","placeholder":" ","required":""},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"floating-label",attrs:{"for":"coupon"},domProps:{"textContent":_vm._s(_vm.$t('checkout.enterCoupon'))}})]),(!_vm.meta.couponName)?_c('button',{staticClass:"flex-shrink-0 w-28 main-button",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('div',{staticClass:"absolute inset-0 flex justify-center align-center"},[_c('div',{staticClass:"m-auto spinner"})]):_vm._e(),_c('div',{class:{
          invisible: _vm.loading,
        },domProps:{"textContent":_vm._s(_vm.$t('common.apply'))}})]):_c('button',{staticClass:"flex-shrink-0 w-28 px-3 uppercase relative font-medium tracking-wide rounded-md bg-gray-800 text-white py-2 hover:bg-gray-600",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.clearCoupon.apply(null, arguments)}}},[(_vm.loading)?_c('div',{staticClass:"absolute inset-0 flex justify-center align-center"},[_c('div',{staticClass:"m-auto spinner"})]):_vm._e(),_c('div',{class:{
          invisible: _vm.loading,
        },domProps:{"textContent":_vm._s(_vm.$t('checkout.clearCoupon'))}})])]),(_vm.error)?_c('div',{staticClass:"text-red-400 text-sm ml-3",domProps:{"textContent":_vm._s(_vm.$t('errors.couponError'))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }