<template>
  <div class="text-gray-700 dark:text-gray-100">
    <ul class="list-disc list-inside text-sm mb-4">
      <li
        v-for="(bullet, index) in bullets"
        :key="index"
        v-text="bullet"
      />
    </ul>
    <div class="text-sm relative flex-shrink description break-words">
      <div
        :class="{
          'break': !showFullDescription
        }"
        v-html="description"
      />
      <button
        class="underline mt-1 flex-shrink outline-none focus:outline-none"
        v-text="$t(`common.${showFullDescription ? 'showLess' : 'showMore'}`)"
        @click="showFullDescription = !showFullDescription"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Description',
  props: {
    product: {
      type: Object,
      required: true
    },
    variation: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showFullDescription: false,
    };
  },
  computed: {
    bullets() {
      return this.product.bullets
    },
    description() {
      if (this.variation && this.variation.description) return this.variation.description;
      return this.product.description
    }
  }
}
</script>
<style>
.description > div.break {
  @apply relative;
  @apply inline-block;
  @apply overflow-hidden;
  @apply break-words;
  line-height: 21px;
  max-height: 84px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.description > div:not(.break) p {
  @apply mb-4;
}
</style>
