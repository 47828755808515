<template>
  <div>
    <KnowledgeHeader
      :collapsed="true"
      :text="article ? article.title : null"
      :publishedDate="
        article ? new Date(article.publishedDate).toISOString().substring(0, 10) : null
      "
      :tags="article ? article.tags : []"
    />
    <div
      style="min-height: calc(60vh - 120px)"
      class="relative dark:text-white dark:bg-gray-700 pb-20"
    >
      <div v-if="loading" class="absolute inset-0 flex justify-center align-center">
        <div class="m-auto spinner x-large text-sm pt-16" v-text="`${$t('common.loading')}...`" />
      </div>
      <NotFound v-else-if="!loading && notFound" />
      <div v-else class="container mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl py-4">
        <div class="px-4">
          <KnowledgeArticleBreadcrumb :article="article" />
          <div class="mt-8 flex flex-wrap lg:flex-nowrap justify-between">
            <DynamicContent
              class="flex-grow mr-4 mb-8"
              v-if="article"
              :content="article.content"
              :listId="article.slug"
              :listName="article.title"
            />
            <DynamicNavigation
              v-if="article"
              style="width: 350px"
              class="hidden lg:block flex-shrink-0 border-l pl-6 ml-6"
              :content="article.content"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KnowledgeHeader from "@/components/knowledge/KnowledgeHeader.vue";
import KnowledgeArticleBreadcrumb from "./KnowledgeArticleBreadcrumb.vue";
import DynamicContent from "../shared/DynamicContent.vue";
import DynamicNavigation from "../shared/DynamicNavigation.vue";
import NotFound from "../shared/NotFound.vue";
import Article from "@/services/article.js";

export default {
  name: "KnowledgeArticle",
  components: {
    KnowledgeArticleBreadcrumb,
    KnowledgeHeader,
    DynamicContent,
    DynamicNavigation,
    NotFound,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      article: null,
      loading: false,
      notFound: false,
    };
  },
  methods: {
    async getArticle() {
      const article = this.$store.state.content.articles.find((i) => i.slug === this.slug);
      if (article) {
        this.article = new Article(article);
      } else {
        this.loading = true;
        try {
          const { data } = await this.$api2.get(`catalog/articles/${this.slug}`, {
            params: {
              language: process.env.VUE_APP_LANGUAGE,
            },
          });
          this.article = new Article(data);
        } catch {
          this.notFound = true;
          if (window.Rollbar) {
            window.Rollbar.error(`AN article error: Article ${this.slug} not found`);
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
  metaInfo() {
    if (!this.article || !this.article.meta) return {};
    return this.article.meta;
  },
  jsonld() {
    if (!this.article || !this.article.jsonld) return {};
    return this.article.jsonld;
  },
  watch: {
    slug: {
      immediate: true,
      handler: "getArticle",
    },
  },
};
</script>
