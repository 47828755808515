<template>
  <div>
    <h5 class="w-full font-medium mb-4" v-text="$tc('store.shipping.shippingMethod', 2)" />
    <div
      v-if="shippingOptions.length === 0"
      class="px-2 sm:px-4 text-sm"
      v-html="$t('store.shipping.noShippingOptions', { countryName })"
    />
    <div
      v-else
      class="divide-y border rounded-md w-full"
      :class="{
        'border-red-400': noShippingSelected,
      }"
    >
      <div
        v-for="option in shippingOptions"
        :key="option.id"
        class="p-4"
        :class="{
          'border-red-400': noShippingSelected,
        }"
      >
        <div class="flex justify-between items-center">
          <div class="flex flex-col items-start space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <label class="relative cursor-pointer select-none pl-5 radio-container">
              <div class="ml-1 flex items-center flex-wrap">
                <img
                  :src="
                    imgixClient.buildURL(`shippingLogos/${option.carrierName}.svg`, {
                      auto: 'format,compress',
                      ch: 'DPR',
                      q: 45,
                      fit: 'clip',
                      w: 70,
                    })
                  "
                  class="sm:block hidden"
                  style="max-height: 24px; max-width: 70px"
                />
                <div class="sm:hidden font-medium text-sm" v-text="option.carrierName" />
                <div
                  v-if="option.serviceName !== 'email'"
                  class="ml-1 font-medium text-sm"
                  v-text="option.serviceName"
                />
              </div>
              <input
                type="radio"
                class="radio absolute opacity-0 cursor-pointer"
                @change="changeShipping(option)"
                :value="options.shippingId === option.id ? 1 : 0"
                :checked="options.shippingId === option.id"
              />
              <span class="radio-checkmark absolute top-0 left-0 bg-gray-200 rounded-full" />
            </label>
            <div
              v-if="option.deliveryTime"
              class="ml-6 sm:ml-2 text-xxs text-gray-400 whitespace-nowrap"
              v-text="$t('store.shipping.deliveryTime', { deliveryTime: option.deliveryTime })"
            />
            <div
              v-if="option.freeShipping"
              class="ml-6 sm:ml-2 rounded-md px-2 py-1 bg-blue-200 text-blue-600 font-medium uppercase text-xxs whitespace-nowrap mr-2"
              v-text="
                $t('store.shipping.freeShippingOver', {
                  freeShipping: $c(option.freeShipping, {
                    currency: meta.currency,
                    showWithVat: false,
                  }),
                })
              "
            />
          </div>
          <div
            class="font-medium text-sm flex-shrink-0 whitespace-nowrap"
            v-text="$c(option.price, { currency: meta.currency, showWithVat: meta.showWithVat })"
          />
        </div>
        <div
          v-if="options.shippingId === option.id && option.servicePoint && option.carrierCode"
          class="pl-6 mt-2 w-full"
        >
          <div class="flex items-center justify-between">
            <div class="flex-grow overflow-hidden">
              <div class="text-xs font-medium" v-text="$t('store.shipping.pickupPoint')" />
              <div
                class="text-xs truncate"
                v-text="
                  options.pickupAgentId ? options.pickupAgentName : $t('store.shipping.noAgent')
                "
              />
            </div>
            <button
              @click.stop="open = true"
              class="text-xs text-gray-500 underline flex-shrink-0 ml-4"
              v-text="$t('common.change')"
            />
          </div>
          <Modal :open="open" @close="open = false">
            <div class="bg-white flex flex-col md:flex-row relative" style="max-width: 900px">
              <div class="flex-shrink-0 hidden md:block">
                <Map
                  style="width: 400px; height: 600px"
                  :zoom="16"
                  :lat="59.3418553"
                  :lng="18.0375072"
                  mapId="4d29ea7ed88ec706"
                  default
                  control
                  :markers="mapAgents"
                />
              </div>
              <div class="pt-8 pb-6 px-10 relative flex-grow">
                <button
                  class="absolute top-0 right-0 mr-2 mt-2 p-2 outline-none hover:cursor-pointer hover:bg-gray-200 rounded-full"
                  @click="open = false"
                >
                  <svg class="w-5 h-5 stroke-current text-gray-500" viewBox="0 0 20 20">
                    <path fill="none" stroke="#000" stroke-width="1.06" d="M16,16 L4,4" />
                    <path fill="none" stroke="#000" stroke-width="1.06" d="M16,4 L4,16" />
                  </svg>
                </button>
                <h5 class="font-medium" v-text="$t('store.shipping.selectServicePoint')" />
                <div class="styled-form mt-4 mb-2 border border-gray-800 rounded-md">
                  <input
                    name="zip"
                    type="text"
                    class="w-full rounded-md"
                    v-model="zip"
                    placeholder=" "
                  />
                  <label for="city" class="floating-label" v-text="$t('forms.billing.zip')" />
                  <div class="absolute inset-y-0 right-0 flex items-center mr-2">
                    <svg class="w-6 h-6" viewBox="0 0 24 24">
                      <path
                        d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="divide-y divide-gray-300"
                  :class="{
                    'overflow-y-scroll': agents.length > 6,
                    'overflow-y-scroll md:overflow-y-none': agents.length <= 6,
                  }"
                  style="height: 440px"
                >
                  <div v-for="agent in agents" :key="agent.id" class="flex flex-col">
                    <label
                      class="relative cursor-pointer select-none pl-5 radio-container pr-4 my-3"
                    >
                      <div class="ml-2 font-bold text-sm" v-text="agent.name" />
                      <div class="ml-2 text-sm" v-text="agent.address" />
                      <input
                        type="radio"
                        class="radio absolute opacity-0 cursor-pointer"
                        @change="selectAgent(agent)"
                        :checked="options.pickupAgentId === agent.id"
                      />
                      <span
                        class="radio-checkmark absolute top-0 left-0 bg-gray-200 rounded-full"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div
          v-if="options.shippingId === option.id && !option.servicePoint"
          class="pl-6 mt-2 w-full"
        >
          <div class="styled-form">
            <input
              name="deliveryNotes"
              class="w-full rounded-lg border"
              type="text"
              v-model.trim="deliveryNotes"
              placeholder=" "
            />
            <label for="deliveryNotes" class="floating-label" v-text="$t('forms.shipping.notes')" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center my-8">
      <button
        :disabled="shippingOptions.length === 0 || !options.shippingId"
        class="flex-shrink-0 main-button py-3"
        type="submit"
        v-text="$tc('store.payment.paymentMethod', 2)"
        @click="completed"
      />
      <router-link
        :to="`/checkout/${this.checkoutId}/address`"
        class="inline-flex items-center ml-4"
      >
        <svg class="h-5 w-5" viewBox="0 0 24 24">
          <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <p v-text="$t('store.navigation.backToAddress')" />
      </router-link>
    </div>
  </div>
</template>
<script>
import imgixClient from "@/services/imgixClient";
import Map from "../other/Map.vue";
import Modal from "../shared/Modal.vue";

export default {
  name: "CheckoutShipping",
  components: {
    Map,
    Modal,
  },
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    shippingOptions: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imgixClient,
      agents: [],
      open: false,
      debounceDelay: 800,
      debounceTimer: null,
      zip: this.details.differentShipping ? this.details.shipping.zip : this.details.billing.zip,
      country: this.details.differentShipping
        ? this.details.shipping.country
        : this.details.billing.country,
      deliveryNotes: this.details.deliveryNotes,
      noShippingSelected: false,
    };
  },
  computed: {
    mapAgents() {
      return this.agents.map(({ id, lat, lng }) => ({
        icon: {
          url: imgixClient.buildURL(`shippingLogos/${this.meta.shippingOption.carrierName}.svg`, {
            auto: "format,compress",
            ch: "DPR",
            q: 45,
            fit: "max",
            w: 80,
          }),
          scaledSize: {
            width: 100,
            height: 30,
            widthUnit: "%",
            heightUnit: "%",
          },
        },
        lat,
        lng,
        centered: id === this.options.pickupAgentId,
      }));
    },
    countryName() {
      const country = this.countries.find((country) => country.postal === this.country);
      if (!country) return "Unknown country";
      return country.name;
    },
  },
  methods: {
    changeShipping(option) {
      this.noShippingSelected = false;
      this.api("checkoutShipping", { id: option.id }, this.checkoutId);
    },
    getAgents() {
      if (this.meta.shippingOption.carrierCode && this.country && this.zip) {
        this.$api2
          .get("shipments/unifaun/agents", {
            params: {
              country: this.country,
              carrierCode: this.meta.shippingOption.carrierCode,
              zip: this.zip,
              geocode: "true",
            },
          })
          .then(({ data: agents }) => {
            this.agents.splice(0, this.agents.length, ...agents);
            if (!agents.length || this.options.pickupAgentId) return true;
            return this.selectAgent(agents[0]);
          });
      }
    },
    selectAgent({ id, name, address }) {
      return this.api("checkoutAgent", { id, name: `${name}, ${address}` }, this.checkoutId);
    },
    completed() {
      if (!this.options.shippingId) {
        this.noShippingSelected = true;
      } else {
        this.noShippingSelected = false;
        this.api(
          "checkoutShippingComplete",
          { deliveryNotes: this.deliveryNotes },
          this.checkoutId
        ).then(() => {
          this.$router.push(`/checkout/${this.checkoutId}/payment`);
        });
      }
    },
  },
  watch: {
    zip() {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(this.getAgents, this.debounceDelay);
    },
    meta: {
      immediate: true,
      handler(meta) {
        this.agents.splice(0, this.agents.length);
        if (meta.shippingOption && meta.shippingOption.carrierCode) {
          this.getAgents();
        }
      },
    },
    details: {
      immediate: true,
      deep: true,
      handler(details) {
        this.deliveryNotes = details.deliveryNotes;
      },
    },
  },
};
</script>
