<template>
  <form @submit.prevent="updateDetails">
    <div class="flex justify-center space-x-6 mb-4 mt-6">
      <button
        class="h-16 w-32 main-button px-0 normal-case"
        :class="{
          outline: info.company,
        }"
        :disabled="!info.company"
        @click.prevent="changeCompany(false)"
        v-text="$t('common.individual')"
      />
      <button
        class="h-16 w-32 main-button px-0 normal-case"
        :class="{
          outline: !info.company,
        }"
        :disabled="info.company"
        @click.prevent="changeCompany(true)"
        v-text="$t('common.company')"
      />
    </div>
    <h5 class="mb-4 font-medium" v-text="$t('checkout.customerDetails')" />
    <div class="grid grid-cols-12 gap-2 sm:mx-4 mb-4">
      <div class="styled-form col-span-12">
        <select
          name="country"
          class="w-full rounded-lg appearance-none"
          :value="billing.country"
          autocomplete="country"
          @change="changeBillingCountry($event.target.value)"
          placeholder=" "
        >
          <option
            v-for="{ postal, name } in countries"
            :key="postal"
            :value="postal"
            v-text="name"
            :selected="postal === billing.country"
          />
        </select>
        <label for="country" class="floating-label" v-text="$t('common.country')" />
      </div>
      <div class="styled-form col-span-12">
        <input
          name="email"
          class="w-full rounded-lg"
          type="email"
          autocomplete="email"
          v-model.trim="$v.billing.email.$model"
          placeholder=" "
          :class="{
            error: $v.billing.email.$error,
          }"
        />
        <label for="email" class="floating-label" v-text="`${$t('common.email')} *`" />
        <label
          for="email"
          class="error"
          v-if="$v.billing.email.$error"
          v-text="$t('formValidation.emailRequired')"
        />
      </div>
      <div class="styled-form col-span-6">
        <input
          name="billingFirstName"
          class="w-full rounded-lg"
          type="text"
          autocomplete="given-name"
          v-model.trim="$v.billing.firstName.$model"
          placeholder=" "
          :class="{
            error: $v.billing.firstName.$error,
          }"
        />
        <label
          for="billingFirstName"
          class="floating-label"
          v-text="`${$t('forms.billing.firstName')} *`"
        />
        <label
          for="billingFirstName"
          class="error"
          v-if="$v.billing.firstName.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-6">
        <input
          name="billingLastName"
          class="w-full rounded-lg"
          autocomplete="family-name"
          type="text"
          v-model.trim="$v.billing.lastName.$model"
          placeholder=" "
          :class="{
            error: $v.billing.lastName.$error,
          }"
        />
        <label
          for="billingLastName"
          class="floating-label"
          v-text="`${$t('forms.billing.lastName')} *`"
        />
        <label
          for="billingLastName"
          class="error"
          v-if="$v.billing.lastName.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div
        class="styled-form col-span-6"
        :class="{
          'col-span-6': 'domestic' === meta.zone,
          'col-span-12 sm:col-span-6': 'eu' === meta.zone,
          'col-span-12': 'export' === meta.zone,
        }"
        v-if="info.company"
      >
        <input
          name="company"
          class="w-full rounded-lg"
          type="text"
          v-model.trim="$v.billing.company.$model"
          autocomplete="organization"
          placeholder=" "
          :class="{
            error: $v.billing.company.$error,
          }"
        />
        <label for="company" class="floating-label" v-text="`${$t('common.company')} *`" />
        <label
          for="company"
          class="error"
          v-if="$v.billing.company.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-6" v-if="meta.zone === 'domestic' && info.company">
        <input
          name="orgNbr"
          class="w-full rounded-lg"
          type="tel"
          autocomplete="off"
          v-model.trim="$v.billing.orgNbr.$model"
          placeholder=" "
          :class="{
            error: $v.billing.orgNbr.$error,
          }"
        />
        <label for="orgNbr" class="floating-label" v-text="`${$t('forms.billing.orgNbr')} *`" />
        <label
          for="orgNbr"
          class="error"
          v-if="$v.billing.orgNbr.$error"
          v-text="$t('formValidation.orgNbr')"
        />
      </div>
      <div class="col-span-12 sm:col-span-6" v-if="meta.zone === 'eu' && info.company">
        <div class="styled-form">
          <input
            name="vatNbr"
            class="w-full rounded-lg"
            type="text"
            autocomplete="off"
            v-model.trim="$v.billing.vatNbr.$model"
            placeholder=" "
            :class="{
              error: $v.billing.vatNbr.$error,
            }"
            :disabled="noVatNumber"
          />
          <label for="vatNbr" class="floating-label" v-text="`${$t('forms.billing.vatNbr')} *`" />
          <label
            for="vatNbr"
            class="error"
            v-if="$v.billing.vatNbr.$error"
            v-text="$t('formValidation.required')"
          />
        </div>
        <label class="small mb-4">
          <input id="noVatNumber" type="checkbox" v-model="changeVatNbr" />
          <span />
          <label for="noVatNumber" v-text="`${$t('forms.billing.noVatNumber')} *`" />
        </label>
      </div>
      <div class="styled-form col-span-12">
        <input
          name="billingAddress1"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-line1"
          v-model.trim="$v.billing.address1.$model"
          placeholder=" "
          :class="{
            error: $v.billing.address1.$error,
          }"
        />
        <label
          for="billingAddress1"
          class="floating-label"
          v-text="`${$t('forms.billing.address1')} *`"
        />
        <label
          for="billingAddress1"
          class="error"
          v-if="$v.billing.address1.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-12">
        <input
          name="billingAddress2"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-line2"
          v-model.trim="$v.billing.address2.$model"
          placeholder=" "
          :class="{
            error: $v.billing.address2.$error,
          }"
        />
        <label for="billingAddress2" class="floating-label" v-text="$t('forms.billing.address2')" />
      </div>
      <div
        class="styled-form"
        :class="{
          'col-span-3': ['US', 'CA', 'AU'].includes(this.billing.country),
          'col-span-4': !['US', 'CA', 'AU'].includes(this.billing.country),
        }"
      >
        <input
          name="billingZip"
          class="w-full rounded-lg"
          type="text"
          autocomplete="postal-code"
          v-model.trim="$v.billing.zip.$model"
          placeholder=" "
          :class="{
            error: $v.billing.zip.$error,
          }"
        />
        <label for="billingZip" class="floating-label" v-text="`${$t('forms.billing.zip')} *`" />
        <label
          for="billingZip"
          class="error"
          v-if="$v.billing.zip.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-4" v-if="['US', 'CA', 'AU'].includes(this.billing.country)">
        <input
          name="billingState"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-level1"
          v-model.trim="$v.billing.state.$model"
          placeholder=" "
          :class="{
            error: $v.billing.state.$error,
          }"
        />
        <label
          for="billingState"
          class="floating-label"
          v-text="`${$t('forms.billing.state')} *`"
        />
        <label
          for="billingState"
          class="error"
          v-if="$v.billing.state.$error"
          v-text="$t('formValidation.state')"
        />
      </div>
      <div
        class="styled-form"
        :class="{
          'col-span-5': ['US', 'CA', 'AU'].includes(this.billing.country),
          'col-span-8': !['US', 'CA', 'AU'].includes(this.billing.country),
        }"
      >
        <input
          name="billingCity"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-level2"
          v-model.trim="$v.billing.city.$model"
          placeholder=" "
          :class="{
            error: $v.billing.city.$error,
          }"
        />
        <label for="billingCity" class="floating-label" v-text="`${$t('forms.billing.city')} *`" />
        <label
          for="billingCity"
          class="error"
          v-if="$v.billing.city.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-4">
        <select
          name="countryCode"
          class="w-full appearance-none rounded-lg"
          :value="billingCellCountry"
          autocomplete="tel-country-code"
          @change="changeBillingNumber($event.target.value, billing.cellNumber)"
          placeholder=" "
        >
          <option
            v-for="{ postal, name, phoneCode } in phoneCodes"
            :key="postal"
            :value="postal"
            v-text="`+${phoneCode} (${name})`"
          />
        </select>
        <label for="countryCode" class="floating-label" v-text="$t('common.countryCode')" />
      </div>
      <div class="styled-form col-span-8">
        <input
          name="cellNumber"
          class="w-full appearance-none rounded-lg"
          type="tel"
          :value="billing.cellNumber"
          autocomplete="tel-national"
          @input="changeBillingNumber(billingCellCountry, $event.target.value)"
          placeholder=" "
          :class="{
            error: $v.billing.cellNumber.$error,
          }"
        />
        <label
          for="cellNumber"
          class="floating-label"
          v-text="`${$t('forms.billing.cellNumber')} *`"
        />
        <label
          for="cellNumber"
          class="error"
          v-if="$v.billing.cellNumber.$error"
          v-text="$t('formValidation.cellNumber')"
        />
      </div>
      <div class="styled-form col-span-12">
        <input
          name="billingNotes"
          class="w-full appearance-none rounded-lg"
          type="text"
          autocomplete="off"
          v-model.trim="$v.billing.notes.$model"
          placeholder=" "
        />
        <label for="billingNotes" class="floating-label" v-text="$t('forms.billing.notes')" />
      </div>
    </div>
    <h5 class="mb-4 font-medium" v-text="$t('store.shipping.shippingDetails')" />
    <label class="checkbox mb-4">
      <input
        id="differentShipping"
        type="checkbox"
        :checked="!differentShipping"
        @change="changeDifferentShipping"
      />
      <span />
      <label for="differentShipping" v-text="$t('store.shipping.sameShippingAddress')" />
    </label>
    <div
      class="grid grid-cols-12 gap-2 sm:mx-4"
      :class="{
        hidden: !differentShipping,
      }"
    >
      <div class="styled-form col-span-12">
        <input
          name="shippingEmail"
          class="w-full rounded-lg"
          type="email"
          autocomplete="email"
          v-model.trim="$v.shipping.email.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.email.$error,
          }"
        />
        <label
          for="shippingEmail"
          class="floating-label"
          v-text="`${$t('forms.shipping.email')} *`"
        />
        <label
          for="shippingEmail"
          class="error"
          v-if="$v.shipping.email.$error"
          v-text="$t('formValidation.emailRequired')"
        />
      </div>
      <div class="styled-form col-span-6">
        <input
          name="shippingFirstName"
          class="w-full rounded-lg"
          type="text"
          autocomplete="given-name"
          v-model.trim="$v.shipping.firstName.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.firstName.$dirty && $v.shipping.firstName.$invalid,
          }"
        />
        <label
          for="shippingFirstName"
          class="floating-label"
          v-text="`${$t('forms.billing.firstName')} *`"
        />
        <label
          for="shippingFirstName"
          class="error"
          v-if="$v.shipping.firstName.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-6">
        <input
          name="shippingLastName"
          class="w-full rounded-lg"
          type="text"
          autocomplete="family-name"
          v-model.trim="$v.shipping.lastName.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.lastName.$error,
          }"
        />
        <label
          for="shippingLastName"
          class="floating-label"
          v-text="`${$t('forms.billing.lastName')} *`"
        />
        <label
          for="shippingLastName"
          class="error"
          v-if="$v.shipping.lastName.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-12" v-if="info.company">
        <input
          name="shippingCompany"
          class="w-full rounded-lg"
          type="text"
          autocomplete="organization"
          v-model.trim="$v.shipping.company.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.company.$error,
          }"
        />
        <label for="shippingCompany" class="floating-label" v-text="`${$t('common.company')} *`" />
        <label
          for="shippingCompany"
          class="error"
          v-if="$v.shipping.company.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-12">
        <input
          name="shippingAddress1"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-line1"
          v-model.trim="$v.shipping.address1.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.address1.$error,
          }"
        />
        <label
          for="shippingAddress1"
          class="floating-label"
          v-text="`${$t('forms.billing.address1')} *`"
        />
        <label
          for="shippingAddress1"
          class="error"
          v-if="$v.shipping.address1.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-12">
        <input
          name="shippingAddress2"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-line2"
          v-model.trim="$v.shipping.address2.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.address2.$dirty && $v.shipping.address2.$invalid,
          }"
        />
        <label
          for="shippingAddress2"
          class="floating-label"
          v-text="$t('forms.billing.address2')"
        />
      </div>
      <div
        class="styled-form"
        :class="{
          'col-span-3': ['US', 'CA', 'AU'].includes(this.shipping.country),
          'col-span-4': !['US', 'CA', 'AU'].includes(this.shipping.country),
        }"
      >
        <input
          name="shippingZip"
          class="w-full rounded-lg"
          type="text"
          autocomplete="postal-code"
          v-model.trim="$v.shipping.zip.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.zip.$error,
          }"
        />
        <label for="shippingZip" class="floating-label" v-text="`${$t('forms.billing.zip')} *`" />
        <label
          for="shippingZip"
          class="error"
          v-if="$v.shipping.zip.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-4" v-if="['US', 'CA', 'AU'].includes(this.shipping.country)">
        <input
          name="shippingState"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-level1"
          v-model.trim="$v.shipping.state.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.state.$error,
          }"
        />
        <label
          for="shippingState"
          class="floating-label"
          v-text="`${$t('forms.billing.state')} *`"
        />
        <label
          for="shippingState"
          class="error"
          v-if="$v.shipping.state.$error"
          v-text="$t('formValidation.state')"
        />
      </div>
      <div
        class="styled-form"
        :class="{
          'col-span-5': ['US', 'CA', 'AU'].includes(this.shipping.country),
          'col-span-8': !['US', 'CA', 'AU'].includes(this.shipping.country),
        }"
      >
        <input
          name="shippingCity"
          class="w-full rounded-lg"
          type="text"
          autocomplete="address-level2"
          v-model.trim="$v.shipping.city.$model"
          placeholder=" "
          :class="{
            error: $v.shipping.city.$error,
          }"
        />
        <label for="shippingCity" class="floating-label" v-text="`${$t('forms.billing.city')} *`" />
        <label
          for="shippingCity"
          class="error"
          v-if="$v.shipping.city.$error"
          v-text="$t('formValidation.required')"
        />
      </div>
      <div class="styled-form col-span-12">
        <select
          name="shippingCountry"
          class="w-full rounded-lg appearance-none"
          :value="shipping.country"
          autocomplete="country"
          @change="changeShippingCountry($event.target.value)"
          placeholder=" "
        >
          <option
            v-for="{ postal, name } in countries"
            :key="postal"
            :value="postal"
            v-text="name"
            :selected="postal === shipping.country"
          />
        </select>
        <label for="shippingCountry" class="floating-label" v-text="$t('common.country')" />
      </div>
      <div class="styled-form col-span-4">
        <select
          name="shippingCountryCode"
          class="w-full appearance-none rounded-lg"
          :value="shippingCellCountry"
          autocomplete="tel-country-code"
          @change="changeShippingNumber($event.target.value, shipping.cellNumber)"
          placeholder=" "
        >
          <option
            v-for="{ postal, name, phoneCode } in phoneCodes"
            :key="postal"
            :value="postal"
            v-text="`+${phoneCode} (${name})`"
          />
        </select>
        <label for="shippingCountryCode" class="floating-label" v-text="$t('common.countryCode')" />
      </div>
      <div class="styled-form col-span-8">
        <input
          name="shippingCellNumber"
          class="w-full appearance-none rounded-lg"
          type="tel"
          :value="shipping.cellNumber"
          autocomplete="tel-national"
          @input="changeShippingNumber(shippingCellCountry, $event.target.value)"
          placeholder=" "
          :class="{
            error: $v.shipping.cellNumber.$error,
          }"
        />
        <label
          for="shippingCellNumber"
          class="floating-label"
          v-text="`${$t('forms.shipping.cellNumber')} *`"
        />
        <label
          for="shippingCellNumber"
          class="error"
          v-if="$v.shipping.cellNumber.$error"
          v-text="$t('formValidation.cellNumber')"
        />
      </div>
    </div>
    <div class="flex items-center my-8">
      <button
        class="flex-shrink-0 main-button py-3"
        type="submit"
        v-text="$tc('store.shipping.shippingMethod', 2)"
      />
      <router-link
        :to="`/checkout/${this.checkoutId}/account`"
        class="inline-flex items-center ml-4"
      >
        <svg class="h-5 w-5" viewBox="0 0 24 24">
          <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <p v-text="$t('store.navigation.backToAccount')" />
      </router-link>
    </div>
  </form>
</template>
<script>
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  alpha,
  helpers,
  numeric,
} from "vuelidate/lib/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const exactLength = (param) =>
  helpers.withParams(
    { type: "exactLength", exactLength: param },
    (value) => !value || value.length === 10
  );

export default {
  name: "CheckoutAddress",
  props: {
    details: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    checkoutId: {
      type: String,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      billingCellCountry: null,
      shippingCellCountry: null,
      shippingEmail: null,
      billing: {
        address1: null,
        address2: null,
        zip: null,
        state: null,
        city: null,
        company: null,
        firstName: null,
        lastName: null,
        orgNbr: null,
        vatNbr: null,
        email: null,
        cellNumber: null,
        notes: null,
        country: null,
      },
      noVatNumber: false,
      differentShipping: false,
      shipping: {
        address1: null,
        address2: null,
        zip: null,
        state: null,
        city: null,
        company: null,
        firstName: null,
        lastName: null,
        email: null,
        cellNumber: null,
        country: null,
      },
    };
  },
  computed: {
    changeVatNbr: {
      get() {
        return this.noVatNumber;
      },
      set(noVatNumber) {
        this.api("checkoutNoVatNumber", { noVatNumber }, this.checkoutId);
      },
    },
    phoneCodes() {
      const list = [...this.countries];
      list.sort((a, b) => a.phoneCode - b.phoneCode);
      return list;
    },
    billingCellNumber() {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        this.billing.cellNumber || "",
        this.billingCellCountry
      );
      return typeof parsedPhoneNumber !== "undefined" &&
        parsedPhoneNumber &&
        parsedPhoneNumber.isValid()
        ? parsedPhoneNumber.number
        : this.billing.cellNumber;
    },
    shippingCellNumber() {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        this.shipping.cellNumber || "",
        this.shippingCellCountry
      );
      return typeof parsedPhoneNumber !== "undefined" &&
        parsedPhoneNumber &&
        parsedPhoneNumber.isValid()
        ? parsedPhoneNumber.number
        : this.shipping.cellNumber;
    },
    toBackEnd() {
      return {
        billing: {
          ...this.billing,
          cellNumber: this.billingCellNumber,
        },
        noVatNumber: this.noVatNumber,
        differentShipping: this.differentShipping,
        shipping: {
          ...this.shipping,
          cellNumber: this.shippingCellNumber,
        },
      };
    },
  },
  validations: {
    billing: {
      address1: { required, maxLength: maxLength(255) },
      address2: { maxLength: maxLength(255) },
      zip: { required, maxLength: maxLength(20) },
      state: {
        required: requiredIf(function () {
          return ["US", "CA", "AU"].includes(this.billing.country);
        }),
        minLength: minLength(2),
        maxLength: maxLength(3),
        alpha,
      },
      city: { required, maxLength: maxLength(255) },
      email: { required, email, maxLength: maxLength(255) },
      firstName: { required, maxLength: maxLength(255) },
      lastName: { required, maxLength: maxLength(255) },
      company: {
        required: requiredIf(function () {
          return this.info.company;
        }),
        maxLength: maxLength(255),
      },
      cellNumber: {
        required,
        validCell: function (cellNumber) {
          return this.cellValidator(cellNumber, this.billingCellCountry);
        },
      },
      notes: {},
      country: { required },
      vatNbr: {
        required: requiredIf(function () {
          return this.info.company && !this.noVatNumber && this.meta.zone === "eu";
        }),
        maxLength: maxLength(20),
      },
      orgNbr: {
        required: requiredIf(function () {
          return this.info.company && this.meta.zone === "domestic";
        }),
        exactLength: exactLength(10),
        numeric,
      },
    },
    shipping: {
      email: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        email,
        maxLength: maxLength(255),
      },
      firstName: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        maxLength: maxLength(255),
      },
      lastName: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        maxLength: maxLength(255),
      },
      company: {
        required: requiredIf(function () {
          return this.info.company && this.differentShipping;
        }),
        maxLength: maxLength(255),
      },
      address1: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        maxLength: maxLength(255),
      },
      address2: { maxLength: maxLength(255) },
      zip: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        maxLength: maxLength(20),
      },
      city: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        maxLength: maxLength(255),
      },
      cellNumber: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
        validCell: function (cellNumber) {
          return this.cellValidator(cellNumber, this.shippingCellCountry);
        },
      },
      state: {
        required: requiredIf(function () {
          return this.differentShipping && ["US", "CA", "AU"].includes(this.shipping.country);
        }),
        minLength: minLength(2),
        maxLength: maxLength(3),
        alpha,
      },
      country: {
        required: requiredIf(function () {
          return this.differentShipping;
        }),
      },
    },
  },
  methods: {
    changeBillingCountry(postal) {
      this.api("checkoutCountry", { billingCountry: postal }, this.checkoutId);
    },
    changeShippingCountry(postal) {
      this.changeShippingNumber(postal, this.shipping.cellNumber);
      this.$v.shipping.cellNumber.$reset();
      this.shipping.country = postal;
      this.api("checkoutCountry", { shippingCountry: postal }, this.checkoutId);
    },
    changeCompany(company) {
      this.api("checkoutCompany", { company }, this.checkoutId);
    },
    changeDifferentShipping(event) {
      this.differentShipping = !event.target.checked;
      if (this.differentShipping && !this.shipping.country) {
        this.shipping.country = this.billing.country;
      }
      this.api(
        "checkoutCountry",
        { differentShipping: this.differentShipping, shippingCountry: this.shipping.country },
        this.checkoutId
      );
    },
    changeBillingNumber(countryCode, cellNumber) {
      this.billingCellCountry = countryCode;
      const parsedPhoneNumber = parsePhoneNumberFromString(cellNumber || "", countryCode);
      if (
        typeof parsedPhoneNumber !== "undefined" &&
        parsedPhoneNumber &&
        parsedPhoneNumber.isValid()
      ) {
        this.$v.billing.cellNumber.$model = parsedPhoneNumber.nationalNumber;
      } else {
        this.$v.billing.cellNumber.$model = cellNumber;
      }
    },
    changeShippingNumber(countryCode, cellNumber) {
      this.shippingCellCountry = countryCode;
      const parsedPhoneNumber = parsePhoneNumberFromString(cellNumber || "", countryCode);
      if (
        typeof parsedPhoneNumber !== "undefined" &&
        parsedPhoneNumber &&
        parsedPhoneNumber.isValid()
      ) {
        this.$v.shipping.cellNumber.$model = parsedPhoneNumber.nationalNumber;
      } else {
        this.$v.shipping.cellNumber.$model = cellNumber;
      }
    },
    cellValidator(cellNumber, countryCode) {
      if (!cellNumber) return true;
      const parsedPhoneNumber = parsePhoneNumberFromString(cellNumber || "", countryCode);
      return (
        typeof parsedPhoneNumber !== "undefined" && parsedPhoneNumber && parsedPhoneNumber.isValid()
      );
    },
    updateDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.api("checkoutAddress", this.toBackEnd, this.checkoutId).then(() => {
          this.$router.push(`/checkout/${this.checkoutId}/shipping`);
        });
      }
    },
  },
  watch: {
    details: {
      immediate: true,
      handler({
        billing: { glnNbr, refCode, ...billing },
        shipping: { notes, ...shipping },
        noVatNumber,
        differentShipping,
      }) {
        Object.assign(this.billing, billing);
        Object.assign(this.shipping, shipping);
        this.noVatNumber = noVatNumber;
        this.differentShipping = differentShipping;
        this.changeBillingNumber(billing.country, billing.cellNumber);
        this.changeShippingNumber(shipping.country, shipping.cellNumber);
        this.$v.$reset();
      },
    },
  },
};
</script>
<style scoped>
.styled-form input:not(.error),
.styled-form select:not(.error) {
  @apply border;
  @apply border-gray-300;
}
.styled-form input.error,
.styled-form select.error {
  @apply border-2;
  @apply border-red-400;
}
</style>
