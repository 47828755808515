<template>
  <section class="mt-6 mb-20 w-full dark:text-white">
    <h2 class="font-medium text-center mb-4" v-text="$t('meta.faq.title')" />
    <div class="flex flex-col sm:flex-row">
      <div class="mr-4">
        <div
          v-text="$t('shop.categories')"
          class="font-bold tracking-wider text-gray-700 dark:text-white text-xs uppercase border-b mb-1 pb-1"
        />
        <ul class="w-64 sm:flex-shrink-0 mb-4">
          <li
            v-for="([category, count]) in categories"
            :key="category"
            class="mb-1"
          >
            <button
              class="text-sm flex items-center align-center"
              :class="{
                'font-bold': selectedCategory === category,
              }"
              @click.prevent="selectCategory(category)"
            >
              <p v-text="category" class="mr-1" />
              <div
                class="flex justify-center items-center rounded-md text-gray-600 bg-gray-200"
                style="padding: 0 0.3rem;"
              >
                <div class="text-xs font-normal" v-text="count.toLocaleString()" />
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div class="flex-grow px-4 sm:px-8">
        <div class="inline-block relative md:max-w-md w-full">
          <div class="absolute left-0 top-0 bottom-0 flex items-center">
            <svg class="fill-current ml-2 h-6 w-6 text-gray-500" viewBox="0 0 24 24">
              <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
          </div>
          <div class="styled-form relative">
            <input
              id="faq"
              name="faq"
              class="w-full rounded-lg"
              type="text"
              placeholder=" "
              v-model="query"
            />
            <label for="faq" class="floating-label" v-text="`${$t('common.search')} ${$t('meta.faq.title')}`" />
            <div
              class="absolute right-0 top-0 bottom-0 mr-6"
              :class="{
                'spinner': searching
              }"
            />
          </div>
        </div>
        <div
          v-if="!results.length"
          class="my-10 mx-3"
        >
          {{ $t('faq.noResults') }}
        </div>
        <div
          v-for="{ objectID, question, answer } in results"
          :key="objectID"
          class="my-4 border rounded-md"
        >
          <div class="font-bold text-md p-2 bg-gray-200 dark:bg-gray-600 rounded-t-md">
            <p v-html="question" class="highlighted" />
          </div>
          <div class="p-2">
            <p v-html="answer" class="highlighted" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import searchClient from '@/services/algolia';
export default {
  name: 'Faq',
  data() {
    return {
      searching: false,
      query: '',
      selectedCategory: null,
      facetFilters: [],
      categories: [],
      results: []
    };
  },
  methods: {
    search() {
      this.searching = true;
      const facetFilters = []
      if(this.selectedCategory) {
        facetFilters.push(`category.${process.env.VUE_APP_LANGUAGE}:${this.selectedCategory}`)
      }
      searchClient
        .search([{
          indexName: 'faq',
          query: this.query,
          params: {
            page: 0,
            hitsPerPage: 1000,
            distinct: false,
            facets: [`category.${process.env.VUE_APP_LANGUAGE}`],
            maxValuesPerFacet: 1000,
            facetFilters
          }
        }])
        .then(({results: [{ hits = [], facets }]}) => {
          this.results = hits.map(({ objectID, category, _highlightResult }) => ({
            objectID,
            category: category[process.env.VUE_APP_LANGUAGE],
            question: _highlightResult.question[process.env.VUE_APP_LANGUAGE].value,
            answer: _highlightResult.answer[process.env.VUE_APP_LANGUAGE].value
          }))
          if (!this.categories.length) {
            this.categories.push(...Object.entries(facets[`category.${process.env.VUE_APP_LANGUAGE}`] || {}))
          }
        })
        .catch((error) => {
          if (window.Rollbar) window.Rollbar.error(`Algolia faq error: ${error.message}`, { error });
        })
        .finally(() => { this.searching = false; })
      
    },
    selectCategory(category) {
      this.selectedCategory = this.selectedCategory === category ? null : category
      this.search()
    }
  },
  watch: {
    query: {
      immediate: true,
      handler() {
        this.search()
      }
    }
  },
  jsonld() {
    return {
      '@context': 'http://schema.org/',
      '@type': 'FAQPage',
      mainEntity: this.results.map(({ question, answer }) => ({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer
        }
      }))
    }
  }
};
</script>