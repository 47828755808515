<template>
  <div class="flex items-center text-sm">
    <router-link
      to="/cart"
      class="mr-2 hover:text-gray-600"
      v-text="$t('common.cart')"
    />
    <svg class="w-4 h-4 mr-1 sm:mr-2" viewBox="0 0 24 24">
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
    <div
      v-for="({ step, active, link, last, textKey }, index) in breadcrumbs"
      :key="step"
      class="flex items-center"
    >
      <router-link
        v-if="link"
        class="flex items-center"
        :class="{
          'mr-1 sm:mr-2': !last,
          'text-gray-400': !link,
          'font-medium hover:cursor-auto': active,
          'hover:text-gray-600 hover:cursor-pointer': index < currentStep
        }"
        v-text="$t(textKey)"
        :to="`/checkout/${checkoutId}/${step}`"
      />
      <div
        v-else
        class="flex items-center"
        :class="{
          'mr-1 sm:mr-2': !last,
          'text-gray-400': !link && !active,
          'font-medium hover:cursor-auto': active,
        }"
        v-text="$t(textKey)"
      />
      <div
        v-if="!last"
        class="mr-1 sm:mr-2"
      >
        <svg class="w-4 h-4" viewBox="0 0 24 24">
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckoutBredcrumb',
  props: {
    step: {
      type: String,
      default: null
    },
    checkoutId: {
      type: String,
      required: true
    },
    completedSteps: {
      type: Array,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentStep() {
      return this.steps.findIndex(i => i.step === this.step);
    },
    breadcrumbs() {
      return this.steps
        .map(({ step, textKey }, index, arr) => {
        return {
          step,
          textKey,
          link: arr.slice(0, index).every(i => this.completedSteps.includes(i.step)) && this.step !== step,
          active: this.step === step,
          last: index === (arr.length - 1)
        }
      })
    }
  },
}
</script>
