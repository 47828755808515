<template>
  <router-link
    :to="getURL"
    v-slot="{ href, navigate }"
    custom
  >
    <a
      itemscope
      itemtype="https://schema.org/SiteNavigationElement"
      itemprop="url"
      rel="nofollow"
      :href="href"
      @click="navigate"
      class="text-sm flex items-center align-center text-gray-700 dark:text-gray-100"
      :class="{
        'font-bold': isRefined,
      }"
    >
      <div v-text="$t(`common.${toggle.attribute}`)" class="mr-1 overflow-hidden truncate" />
      <div
        class="flex justify-center items-center rounded-md text-gray-600 bg-gray-200"
        style="padding: 0 0.3rem;"
      >
        <div class="text-xs font-normal" v-text="(toggle.count || 0).toLocaleString()" />
      </div>
    </a>
  </router-link>
</template>
<script>
export default {
  name: 'FilterMenuToggle',
  props: {
    toggle: {
      type: Object,
      required: true,
    },
    searchState: {
      type: Object,
      required: true
    },
    createURL: {
      type: Function,
      required: true
    },
  },
  computed: {
    isRefined() {
      return this.searchState.toggle.includes(this.toggle.attribute)
    },
    getURL() {
      if (this.isRefined) {
        const toggle = [...this.searchState.toggle]
        const index = this.searchState.toggle.findIndex(i => i.attribute === this.toggle.attribute)
        toggle.splice(index, 1)
        return this.createURL({ toggle, page: 1 })
      }
      return this.createURL({ toggle: [...this.searchState.toggle, this.toggle.attribute], page: 1 })
    },
  },
};
</script>
