<template>
  <div class="flex flex-col items-center">
    <button class="underline dark:text-white" v-text="$t('site.suggest')" @click="open = true" />
    <Modal :open="open" @close="close">
      <div
        class="max-w-lg w-full bg-background dark:bg-gray-800 rounded-lg shadow-2xl px-10 pt-6 pb-10 flex flex-col space-y-4 relative"
      >
        <button
          class="absolute top-0 right-0 mt-2 mr-2 rounded-full hover:bg-gray-300 dark:text-white p-2"
          @click.prevent="close"
        >
          <svg class="w-6 h-6 stroke-current stroke-2" width="20" height="20" viewBox="0 0 20 20">
            <path fill="none" stroke-width="1.06" d="M16,16 L4,4" />
            <path fill="none" stroke-width="1.06" d="M16,4 L4,16" />
          </svg>
        </button>
        <h4 class="font-bold dark:text-white" v-text="$t('site.suggestionBox')" />
        <form id="suggestion" ref="suggestion" @submit.prevent="send">
          <div class="shadow-lg rounded-xl">
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  class="w-full rounded-tl-xl"
                  name="firstname"
                  autocomplete="given-name"
                  type="text"
                  placeholder=" "
                />
                <label class="floating-label" v-text="$t('forms.billing.firstName')" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  class="w-full rounded-tr-xl"
                  autocomplete="family-name"
                  name="lastname"
                  type="text"
                  placeholder=" "
                />
                <label class="floating-label" v-text="$t('forms.billing.lastName')" />
              </div>
            </div>
            <div class="styled-form w-full">
              <input
                class="w-full"
                name="email"
                autocomplete="email"
                type="email"
                placeholder=" "
              />
              <label class="floating-label" v-text="$t('common.email')" />
            </div>
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  class="w-full"
                  autocomplete="organization"
                  name="company"
                  type="text"
                  placeholder=" "
                />
                <label class="floating-label" v-text="$t('common.company')" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  class="w-full"
                  autocomplete="tel"
                  name="mobilephone"
                  type="tel"
                  placeholder=" "
                />
                <label class="floating-label" v-text="$t('forms.billing.cellNumber')" />
              </div>
            </div>
            <div class="styled-form w-full">
              <textarea
                class="w-full rounded-bl-xl rounded-br-xl"
                name="msg"
                placeholder=" "
                rows="4"
                style="margin-bottom: -6px"
                required
                role="textbox"
              />
              <label class="floating-label" v-text="`${$t('site.suggestion')} *`" />
            </div>
          </div>
          <button
            class="main-button w-full mt-8"
            v-text="$t('site.sendSuggestion')"
            type="submit"
            :disabled="loading"
          />
          <input type="hidden" name="form_type" value="suggestion" />
        </form>
        <div v-if="sent" class="text-green-300 my-3" v-text="$t('site.suggestionSent')" />
        <div v-if="error" class="text-red-300 my-3" v-text="$t('errors.contactError')" />
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "./Modal.vue";
import axios from "axios";
export default {
  name: "SuggestionBox",
  components: {
    Modal,
  },
  data() {
    return {
      open: false,
      loading: false,
      sent: false,
      error: null,
    };
  },
  methods: {
    close() {
      this.open = false;
    },
    async send() {
      try {
        this.sent = false;
        this.error = null;
        this.loading = true;
        await axios.post(
          "https://forms.hubspot.com/uploads/form/v2/7263663/e8d3e743-ade0-44bf-9f7f-5be5cd694e56",
          new FormData(this.$refs.suggestion)
        );
        this.sent = true;
        this.$refs.suggestion.reset();
      } catch (error) {
        console.error(error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
