<template>
  <section>
    <div class="bg-white w-full py-16 px-4">
      <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <h1 class="font-bold mb-2 border-b" v-text="$t('meta.affiliate.title')" />
        <h2 class="text-2xl font-light text-gray-700">
          {{ $t("affiliate.heading") }}
        </h2>
      </div>
    </div>
    <div class="w-full pt-16 pb-20 px-4">
      <div class="mx-auto max-w-md">
        <h4 class="font-bold text-4xl">{{ $t("partnerProgram.interested") }}</h4>
        <p class="mb-4">{{ $t("affiliate.application") }}:</p>
        <form id="affiliate" ref="affiliate" @submit.prevent="send">
          <div class="shadow-lg bg-white rounded-xl">
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  name="firstname"
                  class="w-full rounded-tl-xl"
                  type="text"
                  placeholder=" "
                  autocomplete="given-name"
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.firstName')} *`" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  name="lastname"
                  autocomplete="family-name"
                  class="w-full rounded-tr-xl"
                  type="text"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.lastName')} *`" />
              </div>
            </div>
            <div class="styled-form w-full"></div>
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  name="email"
                  autocomplete="email"
                  class="w-full"
                  type="email"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('common.email')} *`" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  class="w-full"
                  autocomplete="tel"
                  name="mobilephone"
                  type="tel"
                  placeholder=" "
                />
                <label class="floating-label" v-text="`${$t('forms.billing.cellNumber')}`" />
              </div>
            </div>
            <div class="styled-form w-full">
              <input class="w-full" name="attachments" type="file" multiple />
            </div>
            <div class="styled-form w-full border p-4 text-sm border-gray-200">
              <ul class="text-gray-500 space-y-4 list-decimal list-inside">
                <li>{{ $t("affiliate.questions.handles") }}</li>
                <li>{{ $t("affiliate.questions.experience") }}</li>
                <li>{{ $t("affiliate.questions.content") }}</li>
                <li>{{ $t("affiliate.questions.current") }}</li>
                <li>{{ $t("affiliate.questions.used") }}</li>
                <li>{{ $t("affiliate.questions.other") }}</li>
              </ul>
            </div>
            <div class="styled-form w-full">
              <textarea
                name="msg"
                class="w-full rounded-bl-xl rounded-br-xl"
                placeholder=" "
                rows="8"
                style="margin-bottom: -6px"
                required
              />
              <label class="floating-label" v-text="`${$t('affiliate.answers')} *`" />
            </div>
          </div>
          <button
            class="uppercase main-button w-full mt-8"
            type="submit"
            v-text="$t('forms.send')"
            :disabled="loading"
          />
          <input type="hidden" name="form_type" value="affiliate" />
        </form>
        <div v-if="sent" class="text-green-300 my-3" v-text="$t('contact.sent')" />
        <div v-if="error" class="text-red-300 my-3" v-text="$t('errors.contactError')" />
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "EndProductUse",
  data() {
    return {
      loading: false,
      sent: false,
      error: false,
    };
  },
  methods: {
    async send() {
      try {
        this.sent = false;
        this.error = null;
        this.loading = true;
        await axios.post(
          "https://forms.hubspot.com/uploads/form/v2/7263663/e8d3e743-ade0-44bf-9f7f-5be5cd694e56",
          new FormData(this.$refs.affiliate)
        );
        this.sent = true;
        this.$refs.affiliate.reset();
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.end-product {
  @apply space-y-2;
  @apply inline-block;
  > li:first-child {
    @apply border-b;
    @apply pb-2;

    // @apply pr-4;
    // @apply pl-10;
    @apply text-left;
    @apply tracking-wide;
    @apply font-normal;
    @apply uppercase;
    @apply text-sm;
  }

  > li:not(:first-child) {
    @apply relative;
    @apply pl-7;
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      @apply absolute;
      @apply left-0;
      @apply text-green-500;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(16, 185, 129)' class='w-6 h-6' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10C22 6.48 17.52 2 12 2z M10 17l-4-4l1.4-1.4l2.6 2.6l6.6-6.6 L18 9L10 17z' %3E%3C/path%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
  }

  > li {
    @apply px-2;
  }
}
</style>
