<template>
  <div>
    <h3 class="mb-4 text-center font-medium text-gray-800 dark:text-white" v-text="$t('home.followInstagram')" />
    <div class="elfsight-app-f384d398-d79b-42a4-804a-53c1930dfa32" />
  </div>
</template>
<script>
export default {
  name: 'Instagram',
};
</script>