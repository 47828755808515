<template>
  <div>
    <h5 class="w-full font-medium mb-4" v-text="$tc('store.payment.paymentMethod', 2)" />
    <div
      class="divide-y border rounded-md w-full"
      :class="{
        'border-red-400': noPaymentSelected,
      }"
    >
      <div
        v-for="method in showPaymentMethods"
        :key="method.id"
        class="p-4"
        :class="{
          'border-red-400': noPaymentSelected,
        }"
      >
        <div class="flex justify-between items-center">
          <div class="flex flex-col items-start space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <label class="relative cursor-pointer select-none pl-5 radio-container">
              <div
                class="ml-1 font-medium text-sm"
                v-text="$t(`store.payment.options.${method.method}`)"
              />
              <input
                type="radio"
                class="radio absolute opacity-0 cursor-pointer"
                @change="changePayment(method.id)"
                :value="meta.paymentMethod && meta.paymentMethod.id === method.id ? 1 : 0"
                :checked="meta.paymentMethod && meta.paymentMethod.id === method.id"
              />
              <span class="radio-checkmark absolute top-0 left-0 bg-gray-200 rounded-full" />
            </label>
            <div
              v-if="methodTerms(method)"
              class="ml-4 text-sm text-gray-400"
              v-html="methodTerms(method)"
            />
            <div
              v-if="method.payTerms > 0"
              class="ml-4 text-sm text-gray-400"
              v-text="$t(`store.payment.creditCheck`)"
            />
          </div>
          <div class="ml-1 flex items-center">
            <img
              :src="method.url"
              class="sm:block hidden"
              style="max-height: 20px; max-width: 60px"
            />
          </div>
        </div>
        <div
          class="ml-6 text-sm text-gray-700"
          v-if="
            meta.paymentMethod &&
            meta.paymentMethod.id === method.id &&
            method.id === 'invoice_email'
          "
          v-text="$t(`store.payment.sendPDFTo`, { email: details.billing.email })"
        />
        <div
          v-if="method.id === 'stripe'"
          v-show="
            method.id === 'stripe' && meta.paymentMethod && meta.paymentMethod.id === 'stripe'
          "
          class="pl-6 mt-2 w-full relative"
        >
          <div id="card-element" />
          <div id="card-errors" class="error--text mt-2 text-sm text-red-400" />
          <div class="text-xs text-gray-400 mt-4" v-text="$t('checkout.stripeSecurity')" />
          <div class="absolute inset-0 bg-white spinner rounded-md large" v-if="loadingStripe" />
        </div>
        <div
          v-if="method.supplier === 'klarna'"
          :key="method.id"
          v-show="meta.paymentMethod && meta.paymentMethod.id === method.id"
          class="pl-6 mt-2 w-full relative"
        >
          <div :id="method.id" />
        </div>
        <div
          v-if="method.id === 'e_invoice'"
          v-show="
            method.id === 'e_invoice' && meta.paymentMethod && meta.paymentMethod.id === 'e_invoice'
          "
          class="sm:pl-6 mt-2 w-full space-y-2"
        >
          <div class="styled-form">
            <input
              name="glnNbr"
              class="w-full rounded-md border"
              type="text"
              v-model="$v.glnNbr.$model"
              placeholder=" "
              :class="{
                'border-2 border-red-400': $v.glnNbr.$error,
              }"
            />
            <label for="glnNbr" class="floating-label" v-text="`${$t('checkout.glnNbr')} *`" />
            <label
              for="glnNbr"
              class="error"
              v-if="$v.glnNbr.$error"
              v-text="$t('formValidation.glnNbr')"
            />
          </div>
          <div class="styled-form">
            <input
              name="refCode"
              class="w-full rounded-md border"
              type="text"
              v-model="$v.refCode.$model"
              placeholder=" "
            />
            <label for="refCode" class="floating-label" v-text="`${$t('checkout.refCode')}`" />
          </div>
        </div>
      </div>
    </div>
    <label class="checkbox small mt-4 mb-2">
      <input id="newsletterSignup" type="checkbox" v-model="$v.newsletterSignup.$model" />
      <span />
      <div for="newsletterSignup" v-text="$t('checkout.newsletterSignup')" />
    </label>
    <label v-if="!info.company && (!meta.accountId || !meta.addClub)" class="checkbox small my-2">
      <input id="newsletterSignup" type="checkbox" v-model="$v.addClubSignUp.$model" />
      <span />
      <div for="newsletterSignup" v-html="$t('checkout.addClubSignup', { language })" />
    </label>
    <label class="checkbox small my-2">
      <input id="newsletterSignup" type="checkbox" v-model="$v.acceptTerms.$model" />
      <span />
      <div
        for="newsletterSignup"
        :class="{
          'text-red-400': $v.acceptTerms.$dirty && $v.acceptTerms.$invalid,
        }"
        v-html="$t('checkout.acceptTerms', { language })"
      />
    </label>
    <label v-if="meta.addborTerms" class="checkbox small my-2">
      <input id="newsletterSignup" type="checkbox" v-model="$v.acceptAddborTerms.$model" />
      <span />
      <div
        for="newsletterSignup"
        :class="{
          'text-red-400': $v.acceptAddborTerms.$dirty && $v.acceptAddborTerms.$invalid,
        }"
        v-text="$t('checkout.addborAgree')"
      />
    </label>
    <div v-if="warnings.length" class="my-6">
      <div
        v-for="warning in warnings"
        :key="warning"
        class="border-r border-t border-b my-2 rounded-md"
      >
        <div class="border-yellow-400 border-l-4 flex items-center rounded-md">
          <svg class="flex-shrink-0 mx-4 w-7 h-7 fill-current text-yellow-400" viewBox="0 0 24 24">
            <path
              d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
            />
          </svg>
          <div class="my-3 text-sm" v-html="warning" />
        </div>
      </div>
    </div>
    <div class="flex items-center my-8">
      <button
        class="flex-shrink-0 main-button py-3"
        type="submit"
        v-text="$t('checkout.placeOrder')"
        @click="place"
        :disabled="placingStep > 0"
      />
      <router-link
        :to="`/checkout/${this.checkoutId}/shipping`"
        class="inline-flex items-center ml-4"
      >
        <svg class="h-5 w-5" viewBox="0 0 24 24">
          <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <p v-text="$t('store.navigation.backToShipping')" />
      </router-link>
    </div>
    <div
      v-if="placingStep"
      class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-40"
    >
      <div
        class="bg-white rounded-xl p-6 flex items-center justify-center"
        style="width: 300px; height: 350px"
      >
        <div class="h-full">
          <div class="h-full flex items-center justify-center flex-col" v-show="placingStep === 1">
            <div>
              <div class="spinner x-large h-12 w-12" />
            </div>
            <div class="text-lg font-medium mb-4" v-text="`${$t('checkout.placing')}...`" />
            <div class="text-xs text-justify" v-text="$t('checkout.dontLeave')" />
          </div>
          <div class="h-full flex items-center flex-col" v-show="placingStep === 2">
            <VueQrcode
              class="flex-grow"
              v-if="qrCode"
              :key="qrCode"
              :value="qrCode"
              :options="{ width: 200 }"
            />
            <div class="text-sm text-justify mb-6" v-text="$t('checkout.scanSwish')" />
            <div class="text-xs text-justify" v-text="$t('checkout.dontLeave')" />
          </div>
          <div class="h-full flex items-center flex-col" v-show="placingStep === 3">
            <div>
              <div class="spinner x-large h-12 w-12" />
            </div>
            <div
              class="text-lg font-medium text-center flex-grow my-4"
              v-text="`${$t('checkout.swishWaiting')}...`"
            />
            <div class="text-xs text-justify" v-text="$t('checkout.dontLeave')" />
          </div>
          <div class="h-full flex items-center flex-col" v-show="placingStep === 9">
            <svg class="h-16 w-16 fill-current text-red-400" viewBox="0 0 24 24">
              <path
                v-if="paymentError"
                d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H13.09A5.47 5.47 0 0 1 13 19A6 6 0 0 1 19 13A5.88 5.88 0 0 1 22 13.81V6A2 2 0 0 0 20 4M20 11H4V8H20M22.54 16.88L20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.46 21.12L17.59 19L15.46 16.88L16.88 15.46L19 17.59L21.12 15.46Z"
              />
              <path
                v-else
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
            <div
              class="text-lg font-medium mb-4"
              v-text="this.$t(paymentError ? 'errors.paymentError' : 'errors.placeError')"
            />
            <div class="text-sm text-justify flex-grow" v-text="error" />
            <button
              class="px-4 py-2 mb-4 uppercase relative font-medium tracking-wide rounded-md bg-gray-800 border border-gray-800 text-white hover:bg-gray-600 hover:cursor-pointer w-full"
              type="submit"
              v-text="$t('common.close')"
              @click="closePaymentWindow"
            />
            <router-link
              class="text-sm font-medium"
              to="/cart"
              v-text="$t('store.navigation.backToCart')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imgixClient from "@/services/imgixClient";
import { requiredIf, helpers } from "vuelidate/lib/validators";
import VueQrcode from "@chenfengyuan/vue-qrcode";

const consolidatedStripeErrors = {
  balance_insufficient: "balance_insufficient",
  insufficient_funds: "balance_insufficient",
  authentication_required: "authentication_required",
  payment_intent_authentication_failure: "authentication_required",
  approve_with_id: "approve_with_id",
  unknownReason: "unknownReason",
  call_issuer: "unknownReason",
  do_not_honor: "unknownReason",
  do_not_try_again: "unknownReason",
  fraudulent: "unknownReason",
  generic_decline: "unknownReason",
  lost_card: "unknownReason",
  merchant_blacklist: "unknownReason",
  card_not_supported: "card_not_supported",
  card_velocity_exceeded: "card_velocity_exceeded",
  currency_not_supported: "currency_not_supported",
  duplicate_transaction: "duplicate_transaction",
  expired_card: "expired_card",
  card_decline_rate_limit_exceeded: "card_decline_rate_limit_exceeded",
  card_declined: "card_declined",
  incorrect_cvc: "incorrect_cvc",
  invalid_cvc: "incorrect_cvc",
  incorrect_number: "incorrect_number",
  invalid_number: "incorrect_number",
  incorrect_zip: "incorrect_zip",
  invalid_card_type: "invalid_card_type",
  invalid_characters: "invalid_characters",
  invalid_account: "invalid_account",
  invalid_amount: "invalid_amount",
  invalid_expiry_month: "invalid_expiry_month",
  invalid_expiry_year: "invalid_expiry_year",
  issuer_not_available: "issuer_not_available",
  not_permitted: "not_permitted",
  processing_error: "processing_error",
  reenter_transaction: "processing_error",
  try_again_later: "processing_error",
  new_account_information_available: "processing_error",
  restricted_card: "processing_error",
  revocation_of_all_authorizations: "processing_error",
  revocation_of_authorization: "processing_error",
  security_violation: "processing_error",
  service_not_allowed: "processing_error",
  stolen_card: "processing_error",
  no_action_taken: "processing_error",
  pickup_card: "processing_error",
};

const checkoutErrors = {
  klarna: {
    rejected: "Klarna did not authorize the credit, please try another payment option",
    solvableError: "Klarna needs more details to complete purchase, please check form",
    finalize: "Klarna needs more info / action from you to finalize the payment",
  },
  stripe: {
    balance_insufficient:
      "Card balance insufficient, please check your card provider and try again",
    authentication_required: "Authentication required to make the purchase, please try again",
    approve_with_id:
      "The payment cannot be authorized. Try one more time. If the payment still can't be processed, please contact your card issuer.",
    unknownReason:
      "The card has been declined for an unknown reason. Please contact your card issuer",
    card_not_supported: "The card does not support this type of purchase.",
    card_velocity_exceeded: "Balance or credit limit available on their card has been exceeded.",
    currency_not_supported: "The currency is not supported for this card",
    duplicate_transaction:
      "A transaction with identical amount and credit card information was submitted very recently.",
    expired_card: "The card has expired.",
    card_decline_rate_limit_exceeded: "Card decline rate limit exceeded, please try again later",
    card_declined: "Card has been declined",
    incorrect_cvc: "The CVC number is incorrect.",
    incorrect_number: "The card number is incorrect.",
    incorrect_zip: "The ZIP/postal code is incorrect.",
    invalid_card_type: "The card type is invalid for this purchase",
    invalid_characters: "Invalid characters in card number, please try again",
    invalid_account:
      "The card, or account the card is connected to, is invalid. Please contact your card issuer",
    invalid_amount:
      "The payment amount exceeds the amount that is allowed. Please contact your card issuer",
    invalid_expiry_month: "The expiration month invalid.",
    invalid_expiry_year: "The expiration year invalid.",
    issuer_not_available:
      "The card issuer could not be reached, so the payment could not be authorized. Please try again",
    not_permitted: "The payment is not permitted.",
    processing_error:
      "An error occurred while processing the card. Please contact your card issuer",
  },
  swish: {
    BE18: "The Swish number is incorrect. Please check and try again",
    RP06: "Another Swish payment exists. Please wait 3 minutes and try again",
    ACMT03: "Swish is not activated for the given number",
    RF07: "Transaction declined. The Swish limit could have been exceeded. Please try again",
    AM21:
      "The Swish limit have been exceeded. Please contact your bank to raise the limit or try again later",
    swishDecline: "The payment was declined by the buyer",
    swishTimeout: "Swish payment timed out. Please try again",
    BANKIDCL: "BankID-signing was cancelled",
    TM01: "The transaction timed out. Please try again",
    BANKIDONGOING: "BankID is already in use. Please try again",
  },
  gatewayError:
    "The payment gateway returned an unknown error, admin has been notified. Please try another payment option in the meantime",
  unknownError: "An unknown error occured, admin has been notified. Please try again later",
};

export default {
  name: "CheckoutPayment",
  components: {
    VueQrcode,
  },
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    completedSteps: {
      type: Array,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    orderId: {
      type: [Number, Boolean],
      default: null,
    },
    products: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    klarna: {
      type: Object,
    },
    details: {
      type: Object,
      required: true,
    },
    uuid: {
      type: String,
      default: null,
    },
    api: {
      type: Function,
      required: true,
    },
    analytics: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imgixClient,
      language: process.env.VUE_APP_LANGUAGE,
      klarnaInit: false,
      loadedKlarnaMethods: [],
      disabledKlarnaMethods: [],
      loadingStripe: false,
      stripe: null,
      elements: null,
      glnNbr: null,
      refCode: null,
      stripeOk: false,
      newsletterSignup: false,
      acceptTerms: false,
      acceptAddborTerms: false,
      addClubSignUp: false,
      noPaymentSelected: false,
      placingStep: 0,
      paymentError: null,
      error: null,
      qrCode: null,
    };
  },
  validations: {
    newsletterSignup: {},
    acceptTerms: { accept: (value) => value === true },
    acceptAddborTerms: {
      required: function (value) {
        return !this.meta.addborTerms || value === true;
      },
    },
    addClubSignUp: {},
    glnNbr: {
      required: requiredIf(function () {
        return this.meta.paymentMethod && this.meta.paymentMethod.id === "e_invoice";
      }),
      glnOrPeppol: (value) =>
        !helpers.req(value) ||
        /^\d{13}$/.test(value) ||
        /^0007:\d{10}$/.test(value) ||
        /^0088:\d{13}$/.test(value),
    },
    refCode: {},
  },
  computed: {
    availableKlarnaMethods() {
      const activeKlarnaMethods = [];
      if (this.klarna && this.klarna.payment_method_categories) {
        activeKlarnaMethods.push(
          ...this.klarna.payment_method_categories.map(({ identifier }) => identifier)
        );
      }
      return activeKlarnaMethods;
    },
    showPaymentMethods() {
      return this.paymentMethods
        .filter(
          ({ active, id }) =>
            active ||
            (this.availableKlarnaMethods.includes(id) && !this.disabledKlarnaMethods.includes(id))
        )
        .map((i) => {
          if (i.supplier !== "klarna") {
            return {
              ...i,
              url: imgixClient.buildURL(`paymentLogos/${i.id}.svg`, {
                auto: "format,compress",
                ch: "DPR",
                q: 45,
                fit: "clip",
                w: 60,
              }),
            };
          }
          const fromKlarna = this.klarna.payment_method_categories.find(
            (j) => j.identifier === i.id
          );
          return {
            ...i,
            url: fromKlarna.asset_urls.standard,
          };
        });
    },
    warnings() {
      const warnings = [];
      if (this.meta.diameters.length) {
        warnings.push(
          this.$tc("checkout.chosenDiameter", this.meta.diameters.length, {
            diameter: this.meta.diameters.map((i) => `${i}mm`).join(` ${this.$t("common.and")} `),
          })
        );
      }
      if (this.meta.deliveryDate) {
        warnings.push(
          this.$t("store.shipping.delayedShipping", { deliveryDate: this.meta.deliveryDate })
        );
      }
      return warnings;
    },
  },
  methods: {
    methodTerms(method) {
      const texts = [];
      if (method.payTerms) texts.push(this.$t("store.payment.payTerms", { days: method.payTerms }));
      if (method.price)
        texts.push(
          this.$c(method.price, {
            currency: this.meta.currency,
            showWithVat: this.meta.showWithVat,
          })
        );
      return texts.join(" &#8729; ");
    },
    loadStripe() {
      if (document.getElementById("stripe-js")) return this.initStripe();
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/";
      script.type = "text/javascript";
      script.id = "stripe-js";
      script.addEventListener("load", this.initStripe);
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    initStripe() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
        locale: process.env.VUE_APP_LANGUAGE,
      });
      this.elements = this.stripe.elements();
      if (!this.card) this.loadCard();
    },
    loadCard() {
      this.card = this.elements.create("card", {
        style: {
          base: {
            fontWeight: 500,
            fontSize: "14px",
          },
        },
        hidePostalCode: true,
      });
      this.card.mount("#card-element");
      this.card.addEventListener("change", (event) => {
        const displayError = document.getElementById("card-errors");
        displayError.textContent = event.error ? event.error.message : "";
        this.stripeOk = event.complete;
      });
      this.card.addEventListener("ready", () => {
        this.loadingStripe = false;
      });
    },
    loadKlarna() {
      if (document.getElementById("klarna-js")) return this.initKlarna();
      const script = document.createElement("script");
      script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js";
      script.type = "text/javascript";
      script.id = "klarna-js";
      window.klarnaAsyncCallback = this.initKlarna;
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    initKlarna() {
      if (this.klarna && this.klarna.client_token) {
        Klarna.Payments.init({
          client_token: this.klarna.client_token,
        });
        this.klarnaInit = true;
      }
    },
    displayKlarnaWidgets() {
      if (this.klarnaInit && this.availableKlarnaMethods.length) {
        this.availableKlarnaMethods.forEach(this.displayKlarnaWidget);
      }
    },
    displayKlarnaWidget(id) {
      if (!this.loadedKlarnaMethods.includes(id)) {
        Klarna.Payments.load(
          {
            container: `#${id}`,
            payment_method_category: id,
          },
          (res) => {
            this.loadedKlarnaMethods.push(id);
            if (!res.show_form) {
              this.disabledKlarnaMethods.push(id);
            }
          }
        );
      }
    },
    changePayment(id) {
      this.api("checkoutPayment", { id }, this.checkoutId);
      this.$v.$reset();
      if (this.card) this.card.clear();
      this.noPaymentSelected = false;
      const displayError = document.getElementById("card-errors");
      displayError.textContent = "";
    },
    closePaymentWindow() {
      this.placingStep = 0;
      this.error = null;
      this.paymentError = null;
    },
    async place() {
      if (!this.meta.paymentMethod || !this.meta.paymentMethod.id) {
        this.noPaymentSelected = true;
        return;
      }
      this.noPaymentSelected = false;
      this.$v.$touch();
      if (this.meta.paymentMethod.id === "stripe" && !this.stripeOk) {
        document.getElementById("card-element").classList.add("StripeElement--invalid");
        return;
      }
      if (this.$v.$invalid || this.completedSteps.length !== 4 || this.placing) return;
      this.placingStep = 1;
      this.error = null;
      this.paymentError = null;
      this.qrCode = null;
      const displayError = document.getElementById("card-errors");
      displayError.textContent = "";
      try {
        let klarnaAuthorizationToken = null;
        if (this.meta.paymentMethod.supplier === "klarna") {
          klarnaAuthorizationToken = await this.authorizeKlarna(this.meta.paymentMethod.id);
        }
        const { paymentData } = await this.api(
          "checkoutPlace",
          {
            glnNbr: this.glnNbr,
            refCode: this.refCode,
            acceptTerms: this.acceptTerms,
            newsletterSignup: this.newsletterSignup,
            addClubSignUp: this.addClubSignUp,
            acceptAddborTerms: this.acceptAddborTerms,
            referalToken: this.$store.state.account.referalToken,
            affiliates: this.$store.state.store.affiliates,
            klarnaAuthorizationToken,
          },
          this.checkoutId
        );
        if (paymentData && paymentData.type === "swish") {
          const { uuid, token } = paymentData;
          const callbackUrl = `https://${window.location.host}/checkout/${this.checkoutId}/payment/${uuid}`;
          window.document.location = `swish://paymentrequest?token=${token}&callbackurl=${encodeURIComponent(
            callbackUrl
          )}`;
          return new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
            this.swishQrForDesktop(uuid, token)
          );
        } else if (paymentData && paymentData.type === "stripe") {
          return this.payWithStripe(paymentData.clientSecret);
        } else if (paymentData && paymentData.type === "klarna") {
          window.location.href = paymentData.redirect_url;
        } else {
          return this.orderSuccessful();
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      let errorText = null;
      // Swish or stripe error
      if (error instanceof this.$api.PaymentError) {
        if (this.meta.paymentMethod.id === "stripe" && consolidatedStripeErrors[error.message]) {
          errorText = checkoutErrors.stripe[consolidatedStripeErrors[error.message]];
        } else if (this.meta.paymentMethod.id === "swish") {
          errorText = checkoutErrors.swish[error.message];
        } else if (this.meta.paymentMethod.supplier === "klarna") {
          errorText = checkoutErrors.klarna[error.message];
        }
        this.paymentError = true;
      } else {
        this.paymentError = false;
      }

      if (this.paymentError && errorText) {
        this.error = errorText;
      } else if (this.paymentError && !errorText) {
        this.error = checkoutErrors["gatewayError"];
      } else if (!this.paymentError) {
        this.error = checkoutErrors["unknownError"];
      }
      this.placingStep = 9;
      this.qrCode = null;
      if (this.paymentError === false && window.Rollbar) {
        window.Rollbar.error(`AN checkout error: ${error.message}`, {
          checkoutId: this.checkoutId,
          paymentMethods: this.paymentMethods,
          completedSteps: this.completedSteps,
          options: this.options,
          meta: this.meta,
          details: this.details,
          api: this.api,
        });
        throw error;
      } else if (this.paymentError && !errorText && window.Rollbar) {
        window.Rollbar.error(
          `AN checkout payment error translation not available, code: ${error.message}`,
          {
            checkoutId: this.checkoutId,
            paymentMethods: this.paymentMethods,
            completedSteps: this.completedSteps,
            options: this.options,
            meta: this.meta,
            details: this.details,
            api: this.api,
          }
        );
        throw error;
      }
    },
    async authorizeKlarna(id) {
      return new Promise((resolve, reject) => {
        try {
          Klarna.Payments.authorize(
            {
              payment_method_category: id,
            },
            (res) => {
              const { authorization_token, approved, show_form, finalize, error } = res;
              if (!show_form) {
                this.disabledKlarnaMethods.push(id);
              }
              if (approved && authorization_token) {
                // Successful authorization
                return resolve(authorization_token);
              } else if (approved && finalize) {
                // Authorization that requires finalization
                return reject(new this.$api.PaymentError("finalize"));
              } else if (!approved && show_form && error) {
                // Rejected authorization with solvable errors
                return reject(new this.$api.PaymentError("solvableError"));
              } else {
                // Rejected
                return reject(new this.$api.PaymentError("rejected"));
              }
            }
          );
        } catch (e) {
          console.error(e);
          this.disabledKlarnaMethods.push(id);
          reject(new this.$api.PaymentError("rejected"));
        }
      });
    },
    payWithStripe(clientSecret) {
      return this.stripe
        .confirmCardPayment(clientSecret, { payment_method: { card: this.card } })
        .then((result) => {
          if (result.error) {
            const displayError = document.getElementById("card-errors");
            displayError.textContent = result.error.message;
            throw new this.$api.PaymentError(
              result.error.decline_code || result.error.code || "gatewayError"
            );
          } else {
            return this.orderSuccessful();
          }
        });
    },
    swishQrForDesktop(uuid, token) {
      this.qrCode = `D${token}`;
      this.placingStep = 2;
      return this.waitSwish(uuid);
    },
    async waitSwish(uuid) {
      let retry = true;
      let attempt = 1;
      do {
        try {
          if (attempt > 200) throw new this.$api.PaymentError("swishTimeout");
          const paid = await this.$api.checkoutSwishStatus(uuid, this.checkoutId);
          if (paid) {
            retry = false;
            return this.orderSuccessful();
          }
          attempt += 1;
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
          retry = false;
          if (this.uuid) this.$router.push(`/checkout/${this.checkoutId}/payment`);
          throw error;
        }
      } while (retry);
    },
    async orderSuccessful() {
      if (this.$store.getters["account/authenticated"]) {
        const accounts = await this.$api.listAccounts();
        this.$store.commit("account/SET_ACCOUNTS", accounts);
      }
      this.analytics("purchase", this.orderId);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.$router.push(`/checkout/${this.checkoutId}/confirmation`);
      return true;
    },
  },
  mounted() {
    this.loadStripe();
    this.loadKlarna();
  },
  created() {
    if (this.uuid) {
      this.placingStep = 3;
      this.waitSwish(this.uuid).catch(this.handleError);
    }
  },
  watch: {
    availableKlarnaMethods: {
      handler: "displayKlarnaWidgets",
    },
    klarnaInit: {
      handler: "displayKlarnaWidgets",
    },
  },
};
</script>
<style scoped>
#card-element {
  @apply border;
  @apply border-gray-400;
  @apply p-2;
  @apply rounded-md;
  @apply bg-white;
}
#card-element.StripeElement--complete {
  @apply border-green-400;
  @apply bg-green-100;
}
#card-element.StripeElement--invalid {
  @apply border-red-400;
  @apply bg-red-100;
}
input:checked + svg {
  display: block;
}
</style>
