<template>
  <div class="flex flex-col items-center bg-white dark:bg-gray-700 px-8 py-10 rounded-lg shadow-lg">
    <div class="max-w-2xl">
      <h2 class="leading-none font-bold text-center" v-text="$t('readd.form.newReAdd')" />
      <p class="mt-3" v-html="$t('readd.form.instructions1')" />
      <p class="mt-3" v-html="$t('readd.form.instructions2')" />
      <p class="mt-3 font-bold underline" v-html="$t('readd.form.requirements')" />
      <ul class="list-disc list-inside mt-1 mb-6">
        <li v-html="$t('readd.form.criteria1')" />
        <li v-html="$t('readd.form.criteria2')" />
        <li v-html="$t('readd.form.criteria3')" />
        <li>
          <span v-html="$t('readd.form.parcel')" />
          <ul class="ml-8 list-disc list-inside">
            <li v-html="$t('readd.form.parcelWeight')"></li>
            <li v-html="$t('readd.form.parcelLength')"></li>
            <li v-html="$t('readd.form.parcelCircumference')"></li>
          </ul>
        </li>
        <li v-html="$t('readd.form.verification')" />
        <li v-html="$t('readd.form.readdLimit')" />
        <li v-html="$t('readd.form.readdEdiLimit')" />
        <li v-html="$t('readd.form.consumerRequirement')" />
      </ul>
    </div>
    <form id="contact" @submit.prevent="send" class="flex flex-col items-center">
      <div class="mb-2 shadow-lg bg-white rounded-xl" style="width: 340px">
        <div class="styled-form w-full rounded-t-xl">
          <input
            class="w-full rounded-t-xl"
            v-model.trim.number="$v.orderId.$model"
            :class="{
              error: $v.orderId.$error,
            }"
            type="number"
            placeholder=" "
            required
          />
          <label class="floating-label" v-text="`${$t('readd.form.orderNumber')} *`" />
        </div>
        <div class="styled-form w-full">
          <input
            class="w-full"
            v-model.trim="$v.email.$model"
            :class="{
              error: $v.email.$error,
            }"
            type="email"
            placeholder=" "
            required
          />
          <label class="floating-label" v-text="`${$t('readd.form.email')} *`" />
        </div>
        <div class="styled-form w-full rounded-b-xl">
          <select
            class="w-full rounded-b-xl"
            v-model.trim="$v.material.$model"
            required
            :class="{
              error: $v.material.$error,
            }"
          >
            <option value="PLA">PLA</option>
          </select>
          <label class="floating-label" v-text="`${$t('readd.form.materialToSend')} *`" />
        </div>
      </div>
      <div>
        <label class="block max-w-lg">
          <input id="agree" type="checkbox" required v-model.trim="$v.agree.$model" />
          <span />
          <label for="agree" class="ml-1" v-html="$t('readd.form.agree', { material })" />
        </label>
      </div>
      <div class="flex justify-center">
        <button
          class="uppercase main-button w-full mt-8"
          type="submit"
          v-text="$t('readd.form.createReaddAndLabel')"
          :disabled="loading"
        />
      </div>
    </form>
    <div v-if="sentLink" class="text-green-300 my-3 max-w-lg">
      <span class="mr-2" v-html="$t('readd.form.readdCreated')" />
      <a
        :href="sentLink"
        class="underline"
        target="_blank"
        v-text="$t('readd.form.readdGetLabel')"
      />
    </div>
    <div v-if="error" class="text-red-300 my-3 max-w-lg" v-html="error" />
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "READDForm",
  data() {
    return {
      loading: false,
      sentLink: null,
      error: null,
      email: null,
      orderId: null,
      material: "PLA",
      agree: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    orderId: {
      required,
    },
    material: {
      required,
    },
    agree: {
      required,
    },
  },
  methods: {
    send() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sentLink = null;
        this.error = null;
        this.loading = true;
        this.$api2
          .post("readd", {
            email: this.email,
            orderId: this.orderId,
            material: this.material,
          })
          .then(({ data }) => {
            this.sentLink = `https://api.addnorth.com/api/readd/${data}`;
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data &&
              error.response.data.message
            ) {
              this.error = error.response.data.message;
            } else if (
              error.response &&
              error.response.status === 409 &&
              error.response.data &&
              error.response.data.message
            ) {
              const token = error.response.data.message;
              this.error = `You can only create one RE-ADD per 6 months. To get your previous shipping label click 
                <a href="https://api.addnorth.com/api/readd/${token}" target="_blank">here</a>`;
            } else {
              this.error = "Something went wrong!";
            }
          })
          .finally(() => {
            this.$v.$reset();
            this.loading = false;
          });
      }
    },
  },
};
</script>
