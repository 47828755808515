<template>
  <ul
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
  >
    <li
      v-for="{ label, category, count, children } in sortedItems"
      :key="category.join()"
      class="mb-1"
    >
      <router-link
        :to="getURL(category)"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a
          itemprop="url"
          :href="href"
          @click="navigate"
          class="text-sm flex items-center align-center text-gray-700 dark:text-gray-100"
          :class="{
            'font-bold': isActive,
          }"
        >
          <div itemprop="name" v-text="label" class="mr-1" />
          <div
            class="flex justify-center items-center rounded-md text-gray-100 bg-gray-600 dark:bg-gray-100 dark:text-gray-700"
            style="padding: 0 0.3rem;"
          >
            <div class="text-xs font-normal" v-text="count.toLocaleString()" />
          </div>
        </a>
      </router-link>
      <div class="ml-4 mt-1">
        <KnowledgeNavigationItem
          v-if="children.length && open(category)"
          :hierarchy="children"
          :selectedCategory="selectedCategory"
          :createURL="createURL"
        />
      </div>
    </li>
  </ul>
</template>
<script>

export default {
  name: 'KnowledgeNavigationItem',
  props: {
    hierarchy: {
      type: Array,
      required: true,
    },
    selectedCategory: {
      type: Array,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
  },
  computed: {
    sortedItems() {
      const items = [...this.hierarchy];
      items.sort((a, b) => a.label.localeCompare(b.label, 'sv'))
      return items;
    }
  },
  methods: {
    open(category) {
      return category.every((i, index) => this.selectedCategory[index] === i)
    },
    exact(category) {
      return category.length === this.selectedCategory.length && category.every((i, index) => this.selectedCategory[index] === i)
    },
    getURL(category) {
      if (!this.exact(category)) return this.createURL({ categories: category, page: 1 })
      if (!category.length) return this.createURL({ categories: [], page: 1 })
      const back = [...category]
      back.pop()
      return this.createURL({ categories: back, page: 1 })
    }
  }
};
</script>
