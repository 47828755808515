<template>
  <section class="my-12 pb-12 flex flex-col w-full items-center dark:text-gray-100">
    <div>
      <img :src="readd" alt="RE-ADD" class="w-80 mb-10" />
    </div>
    <article class="w-full readd-video-container relative">
      <video
        id="readd-video"
        class="w-full h-auto"
        autoplay
        loop
        muted
        preload="auto"
        poster="/img/transparent.png"
        playsinline
      >
        <source id="readd-video-webm" :src="videoUrls.webm" type='video/webm; codecs="vp8"' />
        <source id="readd-video-mp4" :src="videoUrls.mp4" type="video/mp4" />
      </video>
    </article>
    <article class="flex justify-around w-full flex-wrap mt-12 mb-6">
      <div class="max-w-xl mx-6 my-2">
        <h3 class="font-medium" v-text="$t('readd.info.problem')" />
        <p class="mb-2" v-html="$t('readd.info.problemText')" />
        <table class="border-gray-200">
          <thead>
              <tr>
                  <th>{{ $t('common.material') }}</th>
                  <th>{{ $t('readd.table.recyclingRate2016') }}</th>
                  <th>{{ $t('readd.table.recyclingRate2025') }}</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{{ $t('readd.table.cardboard') }}</td>
                  <td>80.4%</td>
                  <td>90%</td>
              </tr>
              <tr class="border-2 border-red-400">
                  <td>{{ $t('readd.table.plastics') }}</td>
                  <td>42.2%</td>
                  <td>75%</td>
              </tr>
              <tr>
                  <td>{{ $t('readd.table.metal') }}</td>
                  <td>77.5%</td>
                  <td>90%</td>
              </tr>
              <tr>
                  <td>{{ $t('readd.table.glass') }}</td>
                  <td>92.8%</td>
                  <td>90%</td>
              </tr>
              <tr>
                  <td>{{ $t('readd.table.paper') }}</td>
                  <td>95%</td>
                  <td>90%</td>
              </tr>
          </tbody>
        </table>
        <small>{{ $t('readd.table.source') }}: FTI</small>
      </div>
      <div class="max-w-md mx-6 my-2">
        <h3 class="font-medium" v-text="$t('readd.info.impact')" />
        <p class="mb-2" v-html="$t('readd.info.impactText')" />
        <img :src="rPLA" alt="rPLA" class="w-full" />
      </div>
    </article>
    <article class="mb-12">
      <readd-form />
    </article>
    <article class="max-w-2xl my-12 mx-4">
      <h1 v-text="'FAQ'" class="text-center font-medium mb-4" />
      <div
        v-for="({ question, answer }, index) in faq"
        :key="index"
      >
        <h5 class="font-medium" v-text="question" />
        <div
          v-text="answer"
          :class="{
            'border-b': index < faq.length - 1
          }"
          class="pb-4 mb-4"
        />
      </div>
    </article>
  </section>
</template>
<script>
import imgixClient from '../services/imgixClient';
import ReaddForm from '../components/shared/READDForm.vue'
export default {
  name: 'READD',
  components: {
    ReaddForm
  },
  data() {
    return {
      nbrQuestions: 3,
      videoSize: 'small',
      rPLA: imgixClient.buildURL('readd/rPLASpool.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 576, fit: 'clip'
      }),
      readd: imgixClient.buildURL('rnd/readd.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 576, fit: 'clip'
      }),
      videoPath: {
        small: {
          webm: `${process.env.BASE_URL}videos/RE-ADD/rPLA_Banner_Mobil.webm`,
          mp4: `${process.env.BASE_URL}videos/RE-ADD/rPLA_Banner_Mobil.mp4`
        },
        large: {
          webm: `${process.env.BASE_URL}videos/RE-ADD/rPLA_Banner_Desktop.webm`,
          mp4: `${process.env.BASE_URL}videos/RE-ADD/rPLA_Banner_Desktop.mp4`
        }
      },
    };
  },
  computed: {
    faq() {
      return Array.from({length: this.nbrQuestions}, (_, i) => ({
        question: this.$t(`readd.faq.q${i+1}`),
        answer: this.$t(`readd.faq.a${i+1}`)
      }))
    },
    videoUrls() {
      return {
        webm: this.videoPath[this.videoSize].webm,
        mp4: this.videoPath[this.videoSize].mp4
      }
    },
  },
  methods: {
    initVideo() {
      const w = window.matchMedia('(max-width: 639px)');
      if (!w.matches) {
        this.videoSize = 'large';
      }
    },
    resize() {
      const w = window.matchMedia('(max-width: 639px)');
      const video = document.getElementById('home-video');
      const newSize = w.matches ? 'small' : 'large';
      
      if (newSize !== this.videoSize) {
        video.pause();
        this.videoSize = newSize;
        video.load();
        video.play();
      }
    },
  },
  created() {
    this.initVideo();
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  jsonld() {
    return {
      '@context': 'http://schema.org/',
      '@type': 'FAQPage',
      mainEntity: this.faq.map(({ question, answer }) => ({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer
        }
      }))
    }
  }
};
</script>
<style scoped>
.readd-video-container {
  height: 100vw;
}
@media (min-width: 640px) {
  .readd-video-container {
    height: 26vw;
  }
}
.readd-video-container video source {
  @apply w-full;
  @apply h-auto;
}
.readd-video-container video[poster] {
  @apply w-full;
  @apply h-full;
}
table>:first-child>tr:not(:first-child),
table>:not(:first-child)>tr,
table>tr:not(:first-child) {
  @apply border-t;
}
table td,
table th {
  @apply p-3;
}
table td:not(:first-child),
table th:not(:first-child) {
  @apply text-right;
}
</style>