<template>
  <div class="max-w-2xl w-full">
    <div v-if="agreedCookies" class="relative w-full overflow-hidden" style="padding-top: 56.25%;">
      <iframe
        id="ytplayer"
        type="text/html"
        class="absolute top-0 left-0 w-full h-full"
        :src="`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=0&loop=1&modestbranding=1`"
        frameborder="0"
      />
    </div>
    <div
      v-else
      style="height: 200px"
      class="flex align-center items-center text-lg dark:text-gray-100 font-medium text-center"
      v-text="$t('site.videoNotAvailable')"
    />
  </div>
</template>
<script>

export default {
  name: 'YoutubeVideo',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    agreedCookies() {
      return this.$store.state.shared.agreedCookies;
    },
  },
};
</script>
