<template>
  <div class="flex flex-wrap lg:justify-between mt-10 lg:mt-16">
    <div class="flex items-center mb-4">
      <svg
        class="flex-shrink-0 mx-2 w-16 h-16 fill-current text-blue-500 dark:text-blue-200"
        viewBox="0 0 24 24"
      >
        <path d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />
      </svg>
      <div class="mx-2" style="max-width: 14rem">
        <p
          class="text-lg font-bold dark:text-white"
          v-text="$t('store.info.freeShipping')"
        />
        <p
          class="text-sm text-gray-600 dark:text-gray-200"
          v-text="$t('store.info.freeShippingText')"
        />
      </div>
    </div>
    <div class="flex items-center mb-4">
      <svg
        class="flex-shrink-0 mx-2 w-16 h-16 fill-current text-blue-500 dark:text-blue-200"
        viewBox="0 0 24 24"
      >
        <path d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
      </svg>
      <div class="mx-2" style="max-width: 12rem">
        <p
          class="text-lg font-bold dark:text-white"
          v-text="$t('store.info.shippedWithin')"
        />
        <p
          class="text-sm text-gray-600 dark:text-gray-200"
          v-text="$t('store.info.shippedWithinText')"
        />
      </div>
    </div>
    <div class="flex items-center mb-4">
      <svg
        class="flex-shrink-0 mx-2 w-16 h-16 fill-current text-blue-500 dark:text-blue-200"
        viewBox="0 0 24 24"
      >
        <path d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z" />
      </svg>
      <div class="mx-2" style="max-width: 16rem">
        <p
          class="text-lg font-bold dark:text-white"
          v-text="$t('store.info.returnPolicy')"
        />
        <p
          class="text-sm text-gray-600 dark:text-gray-200"
          v-text="$t('store.info.returnPolicyText')"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoBoxes',
};
</script>
