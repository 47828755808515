<template>
  <div class="mb-20">
    <div style="min-height: calc(60vh - 120px)" class="relative">
      <div class="p-4 flex flex-wrap lg:flex-nowrap justify-between dark:text-white">
        <DynamicContent
          class="flex-grow mr-4 mb-8"
          :content="dynamicContent"
          :listId="name"
          :listName="name"
          :title="title"
        />
        <DynamicNavigation
          class="hidden lg:block flex-shrink-0 border-l pl-6 ml-6"
          style="width: 350px"
          :content="dynamicContent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DynamicContent from "../shared/DynamicContent.vue";
import DynamicNavigation from "../shared/DynamicNavigation.vue";

export default {
  name: "KnowledgeArticle",
  components: {
    DynamicContent,
    DynamicNavigation,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dynamicContent() {
      return this.content.content;
    },
  },
  metaInfo() {
    if (!this.title || !this.description) return {};
    const meta = {
      title: this.title,
      meta: [
        {
          vmid: "ogtitle",
          name: "og:title",
          property: "og:title",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "ogimagealt",
          name: "og:image:alt",
          property: "og:image:alt",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "twittertitle",
          name: "twitter:title",
          property: "twitter:title",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "description",
          name: "description",
          content: this.description,
        },
        {
          vmid: "ogdescription",
          name: "og:description",
          property: "og:description",
          content: this.description,
        },
        {
          vmid: "twitterdescription",
          name: "twitter:description",
          property: "twitter:description",
          content: this.description,
        },
      ],
    };
    return meta;
  },
};
</script>
