<template>
  <div v-if="breadcrumbs" class="flex items-center text-sm mb-4 w-full overflow-x-auto">
    <div
      v-for="({ path, text}, index) in breadcrumbs"
      :key="path"
      class="flex items-center text-gray-700 dark:text-gray-100 flex-shrink-0"
    >
      <router-link
        :to="path"
        v-text="text"
        class="font-medium"
      />
      <svg
        v-if="path && index < (breadcrumbs.length - 1)"
        class="fill-current h-4 w-4 mx-1"
        viewBox="0 0 24 24"
      >
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    categories: {
      type: Array,
      default: () => ([])
    },
    productName: {
      type: String,
      required: true
    },
  },
  computed: {
    breadcrumbs() {
      if (!this.categories.length) return null;
      const categories = this.categories.map(i => i.split('>')).filter(i => i.length)
      if (!categories.length) return null;
      categories.sort((a, b) => {
        const indexA = this.$store.state.content.categorySort.indexOf(a[0])
        const indexB = this.$store.state.content.categorySort.indexOf(b[0])
        if (indexA < 0 && indexB < 0) return 0
        if (indexA < 0 && indexB >= 0) return 1
        if (indexA >= 0 && indexB < 0) return -1
        return indexA - indexB
      })
      const category = categories.shift()
      const path = category
        .reduce((c, text, index, paths) => {
          c.push({ text, path: this.$store.getters['shared/encodeUrl'](`/shop/${paths.slice(0, index + 1).join('/')}`) })
          return c
        }, [
          { text: this.$t('common.home'), path: '/' },
          { text: this.$t('store.products.allProducts'), path: this.$store.getters['shared/encodeUrl']('/shop') },
          
        ]);
      if (path.length < 5) {
        path.push({ text: this.productName, path: this.$store.getters['shared/encodeUrl'](`/shop/${category.join('/')}/${this.productName}`) })
      }
      return path;
    },
  }
}
</script>