<template>
  <div
    class="fixed bottom inset-x-0 lg:static lg:top-0 lg:mx-2 xl:mx-0 lg:mb-4 bg-white dark:bg-gray-600 dark:text-white flex items-center z-20"
    style="bottom: 60px"
  >
    <div class="flex items-center lg:rounded-md justify-between w-full">
      <router-link
        v-for="({ text, textKey, link, icon }, index) in links"
        :key="text"
        :to="`/account/${link}`"
        style="height: 60px"
        custom
        v-slot="{ href, navigate, isExactActive }"
      >
        <a
          :href="href"
          @click.stop="navigate"
          class="w-1/3 hover:cursor-pointer flex flex-col text-center justify-center items-center hover:bg-gray-200 dark:hover:bg-gray-500"
          :class="{
            'lg:rounded-l-md': index === 0,
            'lg:rounded-r-md': index === (link.length - 1),
            'bg-gray-300 dark:bg-gray-400': isExactActive,
          }"
        >
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path :d="icon" />
          </svg>
          <div class="text-xxs uppercase font-medium tracking-wide lg:normal-case lg:text-sm lg:leading-4" v-text="text || $t(textKey)" />
        </a>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccountBar',
  data() {
    return {
      links: [
        { textKey: 'account.overview', link: '', icon: 'M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z' },
        { text: 'add:club', link: 'addclub', icon: 'M4.86,17.4c0.14,0.14,0.29,0.26,0.46,0.36c-0.24-0.18-1-0.84-0.32-2.91c0.44-1.34,1.13-2.52,2.68-4.27c0.07-0.08,0.15-0.16,0.22-0.24c0.03-0.03,0.06-0.06,0.09-0.09c0.04-0.05,0.09-0.09,0.13-0.14C8.16,10.07,8.2,10.03,8.23,10c0.04-0.04,0.07-0.07,0.11-0.1c0.04-0.04,0.09-0.08,0.13-0.12C8.5,9.74,8.53,9.72,8.56,9.69c0.05-0.04,0.1-0.09,0.15-0.13c0.02-0.02,0.05-0.04,0.07-0.06c0.06-0.05,0.11-0.09,0.17-0.14c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05,0.13-0.1,0.19-0.15c0,0,0.01-0.01,0.01-0.01c0.65-0.48,1.29-0.83,1.93-1.07v0c0.66-0.26,1.31-0.4,1.91-0.46c-0.36,0.04-0.73,0.1-1.11,0.2c0.56-0.08,1.34-0.04,1.67,0.76c0.55,1.31-0.51,3.58-1.34,4.8c-0.06,0.08-0.11,0.17-0.17,0.25c-0.02,0.03-0.04,0.06-0.06,0.09c-0.04,0.06-0.08,0.11-0.13,0.17c-0.02,0.03-0.05,0.06-0.07,0.09c-0.05,0.07-0.1,0.14-0.16,0.2c-0.01,0.02-0.03,0.03-0.04,0.05c-0.07,0.09-0.15,0.18-0.22,0.27c0,0-0.01,0.01-0.01,0.01c-0.07,0.09-0.15,0.18-0.23,0.26c0,0.01-0.01,0.01-0.01,0.02c-0.9,1.02-1.99,1.98-3.03,2.57h0.01c-0.84,0.47-1.66,0.71-2.35,0.54C5.4,17.84,5.1,17.66,4.86,17.4z M23.09,17.39L23.09,17.39c0,0-0.01-0.01-0.01-0.02c-0.79-1.75-1.78-3.93-2.73-6.04c-0.15-0.32-3.46-7.73-3.46-7.73S15.28-0.08,12,0C8.35,0.09,7.12,3.61,7.12,3.61S5.43,7.5,3.9,10.96v0.01c-0.79,1.72-2.44,5.36-2.76,6.02c-0.95,1.93-1.26,3.39-1.1,4.38c0.23,1.12,0.94,2.14,1.94,2.46c1.17,0.38,2.29,0.06,3.58-0.72c0.02-0.01,0.04-0.02,0.06-0.03c0.1-0.06,0.19-0.12,0.29-0.18c1.91-1.18,4.88-2.37,7.68-1.81c2.98,0.6,4.55,2.93,6.78,2.93c1.52,0,2.92-0.92,3.41-2.13C24.51,20.27,23.22,17.63,23.09,17.39z' },
        { textKey: 'account.settings', link: 'settings', icon: 'M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z' },
      ]
    }
  },
};
</script>