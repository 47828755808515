<template>
  <div id="radar-chart" />
</template>
<script>
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';

const texts = {
  "layerAdhesion": "Layer adhesion",
  "easeOfUse": "Ease of use",
  "impactResistance": "Impact resistance",
  "warpResistance": "Warp resistance",
  "heatDeflection": "Heat deflection",
}

export default {
  name: 'RadarChart',
  props: {
    data: {
      type: Object,
      required: true,
    },
    comparableData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      serieColors: ["rgba(66,153,225, 0.3)", "rgba(119,211,143, 0.3)"],
      width: 360,
      height: 260,
      radialScale: scaleLinear()
        .domain([1, 9])
        .range([0, 100]),
      svg: null,
      labels: [],
      series: [],
      labelWidths: [],
      circleHeightOffset: 16,
    }
  },
  computed: {
    circleXCenter() {
      return (this.width - this.labelWidths[1] - this.labelWidths[4]) / 2 + this.labelWidths[1]
    },
    circleYCenter() {
      return (this.height + this.circleHeightOffset) / 2
    }
  },
  methods: {
    render(data) {
      // Remove previous SVGs
      const previous = document.querySelector('#radar-chart > svg')
      if (previous) previous.parentNode.removeChild(previous);
      
      // Set data
      this.series.splice(0, this.series.length)
      this.series.push(Object.values(data));
      if (this.comparableData) this.series.push(Object.values(this.comparableData))

      // Set labels
      this.labels = Object.keys(data).map(label => texts[label]);
      
      // Create SVG
      this.svg = select("#radar-chart").append("svg")
        .attr("class", "dark:text-white fill-current")
        .attr("width", this.width)
        .attr("height", this.height);
      
      this.getXTextSpace()
      this.plotTicks()
      this.plotLabels()
      this.plotSeries()
    },
    getXTextSpace() {
      this.labelWidths.splice(0, this.labelWidths.length)
      const labelWidths = this.labelWidths
      this.svg.append('g')
        .selectAll('.dummyText')
        .data(this.labels)
        .enter()
        .append("text")
        .style("font-size", ".75rem")
        .style("font-weight", '100')
        .text((d) => { return d})
        .each(function(d,i) {
            var thisWidth = this.getComputedTextLength()
            labelWidths.push(thisWidth)
            this.remove()
        })
    },
    plotTicks() {
      const ticks = [1,3,5,7,9];
      ticks.forEach(t =>
        this.svg.append("circle")
          .attr("cx", this.circleXCenter)
          .attr("cy", this.circleYCenter)
          .attr("fill", "none")
          .attr("stroke", "#D1D5DB")
          .attr("r", this.radialScale(t))
      );
    },
    plotLabels() {
      this.labels.forEach((label, index) => {
        let { x, y } = this.angleToCoordinate(index, this.labels.length, 10);
        if (index === 0) x -= this.labelWidths[index] / 2
        if (index > 0 && index < 3) x -= this.labelWidths[index]
        this.svg
          .append("text")
          .style("font-size", ".75rem")
          .style("font-weight", '100')
          // .style("color", '#4B5563')
          .attr("x", x)
          .attr("y", y)
          .text(label);
      });
    },
    plotSeries() {
      const serieLine = line()
        .x(d => d.x)
        .y(d => d.y);
      this.series.forEach((serie, index) => {
        let coordinates = this.labels.map((_label, i, arr) => this.angleToCoordinate(i, arr.length, serie[i]));
        this.svg.append("path")
          .datum(coordinates)
          .attr("d", serieLine)
          .attr("stroke-width", 3)
          .attr("stroke", this.serieColors[index])
          .attr("fill", this.serieColors[index])
          .attr("stroke-opacity", 1)
          // .attr("opacity", 0.5);
      });
    },
    angleToCoordinate(index, length, value) {
      const angle = (Math.PI / 2) + (2 * Math.PI * index / length)
      let x = Math.cos(angle) * this.radialScale(value);
      let y = Math.sin(angle) * this.radialScale(value);
      return { "x": this.circleXCenter + x, "y": this.circleYCenter - y };
    }
  },
  mounted() {
    this.render(this.data);
  },
  watch: {
    data() {
      this.render(this.data);
    },
    comparableData() {
      this.render(this.data);
    },
  },
};
</script>
<style>
.label {
  @apply font-thin;
  @apply text-xs;
  @apply text-gray-600;
}
</style>