<template>
  <section class="relative w-full">
    <div v-if="loadingMap" class="absolute inset-0 flex justify-center align-center">
      <div class="m-auto spinner x-large" />
    </div>
    <div id="map" class="w-full h-full" v-show="!loadingMap" />
  </section>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "Map",
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      default: 5,
    },
    lat: {
      type: Number,
      default: 57.635293,
    },
    lng: {
      type: Number,
      default: 13.619846,
    },
    control: {
      type: Boolean,
      default: false,
    },
    mapId: {
      type: String,
      default: "4b46b25910259c89",
    },
  },
  data() {
    return {
      loadingMap: true,
      map: null,
      infoWin: null,
      mapMarkers: [],
      infoWindows: [],
    };
  },
  computed: {
    center() {
      const centerMarker = this.markers.find(({ centered, lat, lng }) => centered && lat && lng);
      if (centerMarker) return { lat: centerMarker.lat, lng: centerMarker.lng };
      return { lat: this.lat, lng: this.lng };
    },
  },
  methods: {
    async createMarkers(markers) {
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      this.infoWindows.splice(0, this.infoWindows.length);
      const deletedMarkers = this.mapMarkers.splice(0, this.mapMarkers.length);
      deletedMarkers.forEach((marker) => marker.setMap(null));
      markers
        .filter(({ lat, lng }) => lat && lng)
        .forEach(({ lat, lng, icon = null, zIndex = 1, infoWindow = null }) => {
          let content = "";
          if (icon && icon.url) {
            const img = document.createElement("img");
            img.style = "max-width: 50px";
            img.src = icon.url;
            content = img;
          }
          const marker = new AdvancedMarkerElement({
            zIndex,
            position: { lat, lng },
            map: this.map,
            content,
          });
          if (infoWindow) {
            const iW = new google.maps.InfoWindow(infoWindow);
            marker.addListener("click", () => {
              this.infoWindows
                .filter((i) => i !== marker)
                .forEach((i) => {
                  i.close();
                });
              iW.open(this.map, marker);
            });
            this.infoWindows.push(iW);
          }
          this.mapMarkers.push(marker);
        });
    },
    async init() {
      const { Map } = await google.maps.importLibrary("maps");
      const mapEl = document.getElementById("map");
      if (this.map) mapEl.textContent = "";
      const options = {
        mapId: this.mapId,
        zoom: this.zoom,
        center: { lat: this.center.lat, lng: this.center.lng },
        disableDefaultUI: true,
        draggable: this.control,
        draggableCursor: "auto",
        zoomControl: this.control,
        panControl: this.control,
      };
      this.map = new Map(mapEl, options);
      if (this.markers) await this.createMarkers(this.markers);
    },
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GMAP_KEY,
      version: "weekly",
      authReferrerPolicy: "origin",
      libraries: ["core", "maps", "marker"],
    });
    loader
      .load()
      .then(() => this.init())
      .finally(() => {
        this.loadingMap = false;
      });
  },
  watch: {
    center: {
      deep: true,
      handler(center) {
        if (this.map) this.map.setCenter(center);
      },
    },
    markers: {
      async handler(markers) {
        if (this.map) await this.createMarkers(markers);
      },
    },
  },
};
</script>
