<template>
  <section>
    <div class="flex md:my-10 w-full">
      <FilterMenu
        class="px-2 hidden lg:block"
        style="min-width: 220px"
        :createURL="createURL"
        :metaData="metaData"
        :searchState="searchState"
        :attributes="attributes"
      />
      <div class="flex-grow w-full px-4">
        <SearchBar
          :createURL="createURL"
          :metaData="metaData"
          :searchState="searchState"
          :searching="searching"
          :baseIndex="baseIndex"
          :sortIndexes="sortIndexes"
          :attributes="attributes"
        />
        <SearchResults
          class="flex-1 truncate overflow-hidden whitespace-nowrap min-w-0"
          :searchState="searchState"
          :createURL="createURL"
          :searching="searching"
          :results="results"
        />
        <Pagination :createURL="createURL" :searching="searching" :metaData="metaData" />
      </div>
    </div>
    <SuggestionBox class="my-12" />
  </section>
</template>
<script>
import FilterMenu from "./FilterMenu.vue";
import SearchBar from "./SearchBar.vue";
import SearchResults from "./SearchResults.vue";
import Pagination from "./Pagination.vue";
import searchClient from "@/services/algolia";
import SuggestionBox from "../shared/SuggestionBox.vue";
import Variation from "@/services/variation2.js";

export default {
  name: "Shop",
  components: {
    FilterMenu,
    SearchBar,
    SearchResults,
    Pagination,
    SuggestionBox,
  },
  props: {
    category: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hitsPerPage: 18,
      distinct: false,
      baseIndex: `variations`,
      sortIndexes: [
        `variations_bestSellers`,
        `variations_staffPicks`,
        `variations_nameAsc`,
        `variations_priceAsc`,
        `variations_priceDesc`,
      ],
      gridType: "gallery",
      searching: false,
      metaData: {
        nbHits: 0,
        page: 0,
        nbPages: 0,
        processingTimeMS: 0,
        sales: {},
        toggles: [],
        hierarchy: [],
        refinements: [],
      },
      results: [],
      attributes: ["diameter", "size", "color", "version", "material", "hotend"],
      toggles: ["onSale"],
    };
  },
  computed: {
    searchState() {
      const { query = "", page, sort = "", grid = "gallery", toggle, sales } = this.query;
      const state = {
        query,
        category: !this.category ? [] : decodeURI(this.category).split("/"),
        sort: this.sortIndexes.find((i) => i === `variations_${sort}`) || this.baseIndex,
        page: Math.max(page ? parseInt(page, 10) : 1, 1),
        grid,
        toggle: toggle ? toggle.split("~").map(decodeURIComponent) : [],
        sales: sales ? sales.split("~").map(decodeURIComponent) : [],
      };
      this.attributes.forEach((attribute) => {
        state[attribute] = [];
        if (this.query[attribute]) {
          state[attribute].push(...this.query[attribute].split("~").map(decodeURIComponent));
        }
      });
      return state;
    },
    searchRefined() {
      return (
        this.searchState.toggle.length +
          this.searchState.sales.length +
          this.attributes.reduce((c, attribute) => c + this.searchState[attribute].length, 0) >
        0
      );
    },
  },
  methods: {
    createURL(change) {
      const { query, page, sort, grid, toggle = [], sales = [], category = [], ...attributes } = {
        ...this.searchState,
        ...change,
      };
      const route = {
        path: "/shop",
        query: {},
      };
      if (category.length) route.path = `${route.path}/${encodeURI(category.join("/"))}`;
      if (query) route.query.query = query;
      if (page && page > 1) route.query.page = page;
      if (sort && this.sortIndexes.find((i) => i === sort)) route.query.sort = `${sort.substr(11)}`;
      if (grid !== "gallery") route.query.grid = grid;
      if (toggle.length) route.query.toggle = toggle.map(encodeURIComponent).join("~");
      if (sales.length) route.query.sales = sales.map(encodeURIComponent).join("~");
      this.attributes.forEach((attribute) => {
        if (attributes[attribute].length)
          route.query[attribute] = attributes[attribute].map(encodeURIComponent).join("~");
      });
      return this.$router.resolve(route).href;
    },
    routeToQueryParams() {
      // Base query
      const {
        query = "",
        page = 1,
        sort = null,
        category = [],
        toggle = [],
        sales = [],
        ...attributes
      } = this.searchState;
      const indexName = sort;

      // Filters
      let filters = [
        "active:true",
        `language:${process.env.VUE_APP_LANGUAGE}`,
        `environment:${process.env.VUE_APP_ALGOLIA_ENV || process.env.VUE_APP_NODE_ENV}`,
      ];
      toggle.forEach((toggle) => {
        if (toggle === "onSale") {
          filters.push("sale.discountPercentage>0");
        }
      });
      filters = filters.join(" AND ");
      // Facets
      const refineFacets = [
        ...this.attributes.map((i) => `attributes.${i}`),
        ...this.toggles,
        "sales.name",
      ];
      const categoryFacets = ["algoliaCategories.lvl0"];
      for (let i = 1; i <= category.length; i++) {
        categoryFacets.push(`algoliaCategories.lvl${i}`);
      }
      const facets = [...refineFacets, ...categoryFacets];
      // Facet filters
      const refineFacetFilters = [sales.map((i) => `sales.name:${i}`)];
      this.attributes.forEach((attribute) => {
        if (attributes[attribute].length)
          refineFacetFilters.push(attributes[attribute].map((i) => `attributes.${attribute}:${i}`));
      });
      const categoryFacetFilters = [];
      if (category.length)
        categoryFacetFilters.push([
          `algoliaCategories.lvl${category.length - 1}: ${category.join(">")}`,
        ]);
      const facetFilters = [...refineFacetFilters, ...categoryFacetFilters];
      const baseQuery = {
        indexName,
        query,
        params: {
          page: page - 1,
          hitsPerPage: this.hitsPerPage,
          filters,
          distinct: false,
          facets,
          maxValuesPerFacet: 1000,
          facetFilters,
        },
      };
      const hierarchalQueries = [];
      if (category.length) {
        // Add hiearchy support queries
        hierarchalQueries.push(
          ...category.reduce((c, _, index, arr) => {
            const hierachyFacetFilters = [...refineFacetFilters];
            if (index > 0 && index === arr.length - 1) {
              hierachyFacetFilters.push(
                `algoliaCategories.lvl${index - 1}:${arr.slice(0, index).join(">")}`
              );
            }
            return [
              ...c,
              {
                indexName,
                query,
                params: {
                  page: 0,
                  hitsPerPage: 1,
                  filters,
                  distinct: false,
                  facets: [...Array(index + 1).keys()].map((i) => `algoliaCategories.lvl${i}`),
                  facetFilters: hierachyFacetFilters,
                  maxValuesPerFacet: 1000,
                },
              },
            ];
          }, [])
        );
        hierarchalQueries.reverse();
      }
      const refineQuery = [];
      // Add attributes support query
      if (this.searchRefined) {
        refineQuery.push({
          indexName,
          query,
          params: {
            page: 0,
            hitsPerPage: 1,
            filters,
            distinct: false,
            facets: refineFacets,
            maxValuesPerFacet: 1000,
            facetFilters: categoryFacetFilters,
          },
        });
      }
      return [baseQuery, ...hierarchalQueries, ...refineQuery];
    },
    parseSearchResults({ results }) {
      const [{ hits, page, nbPages, nbHits, processingTimeMS }] = results;
      // Results
      this.results.splice(
        0,
        this.results.length,
        ...hits.map((h) => new Variation(this.$store, h))
      );

      // Pagination
      this.metaData.page = page;
      this.metaData.nbPages = nbPages;
      this.metaData.nbHits = nbHits;
      this.metaData.processingTimeMS = processingTimeMS;

      // Hierarchy
      this.metaData.hierarchy.splice(0, this.metaData.hierarchy.length);
      const hierarchyQueries = results.slice(0, this.searchState.category.length + 1);
      hierarchyQueries.reverse();
      const hierarchyArray = hierarchyQueries.reduce((carry, { facets }, index) => {
        const categories = Object.entries(facets[`algoliaCategories.lvl${index}`] || {}).reduce(
          (c, [key, value]) =>
            this.$store.getters["content/reduceSplitCategory"](c, key.split(">"), [], value),
          carry
        );
        categories.sort((a, b) => this.$store.getters["content/sortCategories"](a.label, b.label));
        return categories;
      }, []);
      this.metaData.hierarchy.push(...hierarchyArray);

      // Toggles
      const { facets } = this.searchRefined ? results[results.length - 1] : results[0];
      this.metaData.toggles.splice(0, this.metaData.toggles.length);
      this.metaData.toggles.push(...this.resultsToToggles(facets));

      // Banners
      this.metaData.sales = facets["sales.name"] ? facets["sales.name"] : {};

      // Refinements
      this.metaData.refinements.splice(0, this.metaData.refinements.length);
      const refinements = Object.entries(facets)
        .filter(([attribute]) => this.attributes.includes(attribute.substr(11)))
        .map(([attribute, m]) => {
          const list = Object.entries(m);
          list.sort(([a], [b]) =>
            this.$store.getters["content/sortAttributes"](a, b, attribute.substr(11))
          );
          return [attribute.substr(11), list];
        });
      refinements.sort(
        ([a], [b]) =>
          this.$store.state.content.attributes.indexOf(a) -
          this.$store.state.content.attributes.indexOf(b)
      );
      this.metaData.refinements = refinements;
    },
    search() {
      this.searching = true;
      searchClient
        .search(this.routeToQueryParams())
        .then(this.parseSearchResults)
        .then(() => {
          if (this.searchState.query.length) {
            gtag("event", "search", {
              search_term: this.searchState.query,
            });
          }
        })
        .catch((error) => {
          if (window.Rollbar)
            window.Rollbar.error(`Algolia shop error: ${error.message}`, { error });
        })
        .finally(() => {
          this.searching = false;
        });
    },
    resultsToToggles(facets) {
      const toggles = [];
      this.toggles.forEach((attribute) => {
        if (facets[attribute] && facets[attribute]["1"] && facets[attribute]["1"] > 0)
          toggles.push({
            attribute,
            count: facets[attribute]["1"],
          });
      });
      return toggles;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.search();
      },
    },
  },
  metaInfo() {
    const categories = [...this.searchState.category];
    categories.unshift("shop");
    const meta = {
      link: [
        {
          rel: "canonical",
          href: `https://addnorth.${process.env.VUE_APP_DOMAIN}/${categories.join("/")}`,
        },
      ],
    };
    if (this.searchState.category.length) {
      meta.title = this.searchState.category[this.searchState.category.length - 1];
    }
    return meta;
  },
  jsonld() {
    if (!this.searchState.category.length) return {};
    return {
      "@context": "http://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 0,
          name: this.$t("common.home"),
          item: `https://addnorth.${process.env.VUE_APP_DOMAIN}`,
        },
        {
          "@type": "ListItem",
          position: 1,
          name: this.$t("common.shop"),
          item: `https://addnorth.${process.env.VUE_APP_DOMAIN}/shop`,
        },
        ...this.searchState.category.map((category, position, categories) => ({
          "@type": "ListItem",
          position,
          name: category,
          item: `https://addnorth.${process.env.VUE_APP_DOMAIN}${this.createURL({
            category: categories.slice(0, position + 1),
          })}`,
        })),
      ],
    };
  },
};
</script>
