<template>
  <section
    class="slider"
    role="Gallery"
    :class="{
      hasSlides: slides.length > 1,
    }"
  >
    <ol class="slides" ref="slides">
      <li
        v-for="(slide, index) in slides"
        :key="`slide${index}`"
        :id="`slide${index}`"
        :tabindex="index"
      >
        <div
          v-if="arrows"
          @click="scrollTo('prev')"
          class="absolute left-0 z-10 inset-y-0 flex items-center hover:cursor-pointer dark:text-white"
        >
          <svg class="w-10 h-10" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
            />
          </svg>
        </div>
        <slot name="slide" v-bind:slide="slide" />
        <div
          v-if="arrows"
          @click="scrollTo('next')"
          class="absolute right-0 z-10 inset-y-0 flex items-center hover:cursor-pointer dark:text-white"
        >
          <svg class="w-10 h-10" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </div>
      </li>
    </ol>
  </section>
</template>
<script>
export default {
  name: "ImageCarousel",
  props: {
    slides: {
      type: Array,
      required: true,
    },
    arrows: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollTo(direction) {
      const slider = this.$refs.slides;
      const sliderWidth = slider.scrollWidth;
      const slidesWidth = sliderWidth / this.slides.length;
      if (direction === "next" && slider.scrollLeft + slidesWidth >= sliderWidth) {
        slider.scrollLeft = 0;
      } else if (direction === "next") {
        slider.scrollLeft += slidesWidth;
      } else if (direction === "prev" && slider.scrollLeft - slidesWidth < 0) {
        slider.scrollLeft = sliderWidth - slidesWidth;
      } else if (direction === "prev") {
        slider.scrollLeft -= slidesWidth;
      }
    },
  },
};
</script>
<style scoped>
.slider {
  @apply overflow-hidden;
  @apply text-center;
}
.slides {
  @apply w-full;
  @apply flex;
}
.slider.hasSlides > .slides {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: 30px;
  @apply overflow-x-scroll;
}
.slides > li {
  @apply flex-shrink-0;
  @apply w-full;
  @apply flex;
  @apply relative;
  @apply justify-center;
  @apply items-center;
  scroll-snap-align: start;
  padding-bottom: 78.95%;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
}
img {
  @apply object-contain;
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
}
.slider.hasSlides > .slides::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.slider.hasSlides > .slides::-webkit-scrollbar-thumb {
  @apply bg-black;
  @apply border-white;
  @apply border;
  @apply border-solid;
  border-top-width: 10px;
  border-bottom-width: 10px;
}
.dark .slider.hasSlides > .slides::-webkit-scrollbar-thumb {
  @apply bg-white;
  @apply border-gray-900;
}
.slider.hasSlides > .slides::-webkit-scrollbar-track {
  @apply bg-gray-300;
  @apply border;
  @apply border-white;
  @apply border-solid;
  border-top-width: 14px;
  border-bottom-width: 14px;
}
.dark .slider.hasSlides > .slides::-webkit-scrollbar-track {
  @apply bg-gray-400;
  @apply border-gray-900;
}
</style>
