var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center text-sm"},[_c('router-link',{staticClass:"mr-2 hover:text-gray-600",attrs:{"to":"/cart"},domProps:{"textContent":_vm._s(_vm.$t('common.cart'))}}),_c('svg',{staticClass:"w-4 h-4 mr-1 sm:mr-2",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"}})]),_vm._l((_vm.breadcrumbs),function({ step, active, link, last, textKey },index){return _c('div',{key:step,staticClass:"flex items-center"},[(link)?_c('router-link',{staticClass:"flex items-center",class:{
        'mr-1 sm:mr-2': !last,
        'text-gray-400': !link,
        'font-medium hover:cursor-auto': active,
        'hover:text-gray-600 hover:cursor-pointer': index < _vm.currentStep
      },attrs:{"to":`/checkout/${_vm.checkoutId}/${step}`},domProps:{"textContent":_vm._s(_vm.$t(textKey))}}):_c('div',{staticClass:"flex items-center",class:{
        'mr-1 sm:mr-2': !last,
        'text-gray-400': !link && !active,
        'font-medium hover:cursor-auto': active,
      },domProps:{"textContent":_vm._s(_vm.$t(textKey))}}),(!last)?_c('div',{staticClass:"mr-1 sm:mr-2"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"}})])]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }