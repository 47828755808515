<template>
  <section class="w-full flex flex-col items-center pb-12">
    <ContactForm class="my-8 px-8 rounded-lg" />
    <div class="flex w-full mb-8 md:order-first justify-center">
      <div class="flex-grow hidden md:block">
        <Map
          class="w-full h-full"
          :markers="[
            {
              lat: 57.63545979504315,
              lng: 13.620028565780558,
              icon: {
                url: imgixClient.buildURL('mapMarkers/addnorth_black.png', {
                  auto: 'format,compress',
                  ch: 'DPR',
                  q: 45,
                  fit: 'clip',
                  w: 30,
                }),
              },
            },
            {
              lat: 59.3418553,
              lng: 18.0375072,
              icon: {
                url: imgixClient.buildURL('mapMarkers/addnorth_black.png', {
                  auto: 'format,compress',
                  ch: 'DPR',
                  q: 45,
                  fit: 'clip',
                  w: 30,
                }),
              },
            },
          ]"
        />
      </div>
      <div class="py-2 px-2 md:pl-6 md:pr-12 dark:text-gray-100">
        <SocialMedia />
        <h4 class="font-medium mt-1">Add North 3D AB</h4>
        <div class="mt-1 font-text-gray-700">
          <p class="text-sm" v-text="`${$t('forms.billing.orgNbr')}: 559063-0504`" />
          <p class="text-sm" v-text="`${$t('forms.billing.vatNbr')}: SE559063050401`" />
          <p class="font-bold mt-6" v-text="$t('contact.office')" />
          <p>Torsgatan 48B</p>
          <p>113 37, Stockholm</p>
          <p>{{ $t("contact.sweden") }}</p>
          <p class="font-bold mt-6">{{ $t("contact.production") }}</p>
          <p>Ulricehamnsvägen 11</p>
          <p>514 62, Ölsremma</p>
          <p>{{ $t("contact.sweden") }}</p>
          <div class="text-xs mt-6">
            <p class="font-bold">{{ $t("contact.invoicingAddress") }}</p>
            <p>Add North 3D AB</p>
            <p>Box 4, FE-35194</p>
            <p>737 21, Fagersta</p>
            <p>{{ $t("contact.sweden") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Map from "../components/other/Map.vue";
import ContactForm from "../components/shared/ContactForm.vue";
import SocialMedia from "../components/shared/SocialMedia.vue";
import imgixClient from "@/services/imgixClient";

export default {
  name: "Contact",
  components: {
    Map,
    ContactForm,
    SocialMedia,
  },
  data() {
    return {
      imgixClient,
    };
  },
};
</script>
