<template>
  <div class="min-h-screen min-w-screen h-full w-full bg-white relative">
    <div
      v-if="loading"
      class="min-w-full min-h-full fixed inset-0 flex justify-center items-center bg-white bg-opacity-75 z-40"
    >
      <div class="spinner large h-12 w-12" />
    </div>
    <div
      v-else-if="error"
      class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
    >
      <div
        class="bg-white rounded-xl p-6 flex flex-col items-center"
        style="width: 300px; height: 350px"
      >
        <svg class="h-10 w-10 fill-current text-red-400 mb-2" viewBox="0 0 24 24">
          <path
            d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
          />
        </svg>
        <div class="text-lg font-medium mb-4" v-text="$t('errors.apiErrorTitle')" />
        <div
          class="text-sm text-justify flex-grow"
          style="max-width: 330px"
          v-text="$t('errors.apiError')"
        />
        <button
          class="px-4 py-2 mb-4 uppercase relative font-medium tracking-wide rounded-md bg-gray-800 border border-gray-800 text-white hover:bg-gray-600"
          type="submit"
          v-text="$t('common.refresh')"
          @click="refresh"
        />
        <router-link
          class="text-sm font-medium"
          to="/cart"
          v-text="$t('store.navigation.backToCart')"
        />
      </div>
    </div>
    <div
      v-else-if="noProductsError"
      class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
    >
      <div
        class="bg-white rounded-xl p-6 flex flex-col items-center"
        style="width: 300px; height: 350px"
      >
        <svg class="h-10 w-10 fill-current text-red-400 mb-2" viewBox="0 0 24 24">
          <path
            d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
          />
        </svg>
        <div class="text-lg font-medium mb-4" v-text="$t('errors.cartNoProductsTitle')" />
        <div
          class="text-sm text-justify flex-grow"
          style="max-width: 330px"
          v-text="$t('errors.cartNoProducts')"
        />
        <router-link
          class="text-sm font-medium"
          to="/cart"
          v-text="$t('store.navigation.backToCart')"
        />
      </div>
    </div>
    <div
      v-else-if="notFound"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60"
    >
      <div
        class="bg-white rounded-xl p-6 flex flex-col items-center m-6"
        style="width: 300px; height: 350px"
      >
        <svg class="h-10 w-10 fill-current mb-2" viewBox="0 0 24 24">
          <path
            d="M22.73,22.73L1.27,1.27L0,2.54L4.39,6.93L6.6,11.59L5.25,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H14.46L15.84,18.38C15.34,18.74 15,19.33 15,20A2,2 0 0,0 17,22C17.67,22 18.26,21.67 18.62,21.16L21.46,24L22.73,22.73M7.42,15A0.25,0.25 0 0,1 7.17,14.75L7.2,14.63L8.1,13H10.46L12.46,15H7.42M15.55,13C16.3,13 16.96,12.59 17.3,11.97L20.88,5.5C20.96,5.34 21,5.17 21,5A1,1 0 0,0 20,4H6.54L15.55,13M7,18A2,2 0 0,0 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20A2,2 0 0,0 7,18Z"
          />
        </svg>
        <div class="text-lg font-medium mb-4" v-text="$t('errors.cartNotFoundTitle')" />
        <div
          class="text-sm text-justify flex-grow"
          style="max-width: 330px"
          v-text="$t('errors.cartNotFound')"
        />
        <button
          class="px-4 py-2 mb-4 uppercase relative font-medium tracking-wide rounded-md bg-gray-800 border border-gray-800 text-white hover:bg-gray-600 hover:cursor-pointer"
          type="submit"
          v-text="$t('checkout.notFoundRefresh')"
          @click="create"
        />
        <router-link
          class="text-sm font-medium"
          to="/cart"
          v-text="$t('store.navigation.backToCart')"
        />
      </div>
    </div>
    <div
      v-if="checkoutId && showComponent === 'CheckoutConfirmation'"
      class="flex justify-center py-6 px-4"
    >
      <CheckoutConfirmation class="max-w-lg" :orderId="data.orderId" />
    </div>
    <div v-else-if="checkoutId && showComponent" class="lg:flex lg:flex-row min-h-full">
      <CheckoutProducts
        class="lg:order-last lg:flex-basis-40 min-h-full"
        :checkoutId="checkoutId"
        :meta="data.meta"
        :info="data.info"
        :details="data.details"
        :products="data.products"
        :api="api"
      />
      <div
        class="bg-white lg:flex-basis-60 py-4 lg:py-12 lg:border-r flex justify-center lg:justify-start lg:flex-col lg:items-end"
      >
        <div class="px-4 sm:px-0 lg:px-4 w-full max-w-xl">
          <router-link class="mb-6 hidden lg:block" to="/">
            <img
              :src="
                imgixClient.buildURL('logos/an_logo.svg', {
                  auto: 'format,compress',
                  ch: 'DPR',
                  q: 45,
                  w: 152,
                  fit: 'clip',
                })
              "
              alt="add:north 3D filaments"
              style="width: 152px; height: 40px"
              width="152"
              height="40"
            />
          </router-link>
          <CheckoutBreadcrumb
            class="mb-4 sm:mb-6"
            :checkoutId="checkoutId"
            :step="step"
            :steps="steps"
            :completedSteps="data.completedSteps"
          />
          <CheckoutSummary
            v-if="['shipping', 'address', 'payment'].includes(step)"
            class="mb-4 sm:mb-6"
            :step="step"
            :checkoutId="checkoutId"
            :details="data.details"
            :options="data.options"
            :meta="data.meta"
            :info="data.info"
            :completedSteps="data.completedSteps"
            :api="api"
          />
          <component
            :key="showComponent"
            :is="showComponent"
            :checkoutId="checkoutId"
            :details="data.details"
            :meta="data.meta"
            :options="data.options"
            :countries="data.countries"
            :products="data.products"
            :klarna="data.klarna"
            :shippingOptions="data.shippingOptions"
            :paymentMethods="data.paymentMethods"
            :completedSteps="data.completedSteps"
            :info="data.info"
            :uuid="uuid"
            :orderId="data.orderId"
            :api="api"
            :analytics="analytics"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imgixClient from "../../services/imgixClient";
import CheckoutAccount from "./CheckoutAccount";
import CheckoutAddress from "./CheckoutAddress";
import CheckoutShipping from "./CheckoutShipping";
import CheckoutPayment from "./CheckoutPayment";
import CheckoutProducts from "./CheckoutProducts";
import CheckoutBreadcrumb from "./CheckoutBreadcrumb";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutConfirmation from "./CheckoutConfirmation";

export default {
  name: "CheckoutView",
  components: {
    CheckoutProducts,
    CheckoutBreadcrumb,
    CheckoutAccount,
    CheckoutAddress,
    CheckoutShipping,
    CheckoutPayment,
    CheckoutSummary,
    CheckoutConfirmation,
  },
  props: {
    checkoutId: {
      type: String,
      default: null,
    },
    step: {
      type: String,
      default: null,
    },
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      notFound: false,
      noProductsError: false,
      imgixClient,
      data: {},
      steps: [
        { step: "account", component: "CheckoutAccount", textKey: "common.account" },
        { step: "address", component: "CheckoutAddress", textKey: "common.address" },
        { step: "shipping", component: "CheckoutShipping", textKey: "store.shipping.shipping" },
        { step: "payment", component: "CheckoutPayment", textKey: "store.payment.payment" },
      ],
    };
  },
  computed: {
    authReady() {
      return this.$store.state.account.authReady;
    },
    showComponent() {
      if ((this.data.placed || this.step === "confirmation") && this.data.orderId)
        return "CheckoutConfirmation";
      if (!this.step || !this.data.products || !this.data.products.length) return null;
      const { component } = this.steps.find((i) => i.step === this.step) || {};
      return component;
    },
    loggedIn() {
      return this.$store.getters["account/loggedIn"];
    },
  },
  methods: {
    refresh() {
      window.location.reload();
    },
    parseCartResult(data) {
      this.data = data;
      this.$store.commit("checkout/SET_COMPANY", data.info.company);
      this.$store.commit("checkout/SET_COUNTRY", data.details.billing.country);
      this.$store.commit("store/SET_CURRENCY", data.meta.currency);
      this.$store.commit("store/SET_SHOW_WITH_VAT", data.meta.showWithVat);
    },
    api(method, data, id) {
      this.loading = true;
      return this.$api[method](data, id)
        .then((data) => {
          if (data.cart) this.parseCartResult(data.cart);
          if (this.showComponent === "CheckoutAccount") {
            this.analytics("begin_checkout");
          } else if (this.showComponent === "CheckoutAddress") {
            this.analytics("add_shipping_info");
          } else if (this.showComponent === "CheckoutShipping") {
            this.analytics("add_shipping_info");
          } else if (this.showComponent === "CheckoutPayment") {
            this.analytics("add_payment_info");
          }
          this.error = false;
          this.loading = false;
          return data;
        })
        .catch((error) => {
          this.loading = false;
          if (error instanceof this.$api.PaymentError) throw error;
          this.error = true;
          if (window.Rollbar) {
            window.Rollbar.error(`AN Checkout api error: ${error.message}`, error);
          }
          throw error;
        });
    },
    get() {
      this.loading = true;
      return this.$api
        .checkoutGet(this.checkoutId)
        .then(({ cart }) => {
          this.notFound = false;
          this.parseCartResult(cart);
          this.analytics("begin_checkout");
          if (cart.placed && this.step !== "confirmation") {
            this.$router.push(`/checkout/${this.checkoutId}/confirmation`);
          } else if (!this.step) {
            let index = cart.completedSteps.length - 1;
            if (index + 1 <= cart.completedSteps.length - 1) index += 1;
            this.$router.push(`/checkout/${this.checkoutId}/${this.steps[index].step}`);
          }
        })
        .catch((error) => {
          if (error instanceof this.$api.ApiError && error.response.status === 404) {
            this.notFound = true;
          } else {
            this.error = true;
          }
          if (window.Rollbar)
            window.Rollbar.error(`AN checkout get error: ${error.message}`, error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.loading = true;
      const {
        checkout: { country = null, company = false },
        store: { affiliates },
        account: { accountId },
      } = this.$store.state;
      const products = this.$store.getters["cart/toCheckout"];
      return this.$api
        .checkoutCreate({ country, company, products, accountId, affiliates })
        .then(({ checkoutId, cart }) => {
          this.notFound = false;
          this.parseCartResult(cart);
          this.analytics("begin_checkout");
          this.$router.replace(
            `/checkout/${checkoutId}/${cart.completedSteps.length ? "address" : "account"}`
          );
        })
        .catch((error) => {
          console.error(error);
          if (error.message === "NotFound") {
            this.noProductsError = true;
          } else {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async init() {
      if (!this.checkoutId) {
        if (!this.$store.getters["cart/itemsInCart"]) return this.$router.push("/cart");
        await this.create();
      } else {
        await this.get();
      }
    },
    analytics(event, orderId) {
      const {
        meta,
        products,
        details: { billing, shipping, differentShipping },
        info,
      } = this.data;
      const data = {
        event,
        coupon: meta.couponName,
        items: products.map(
          ({ sku, productId, name, price, discountPercentage, quantity, salesName }) => ({
            item_id: sku,
            item_name: productId,
            item_variant: name,
            price: price / 100,
            discount: Math.round((price * discountPercentage) / 100) / 100,
            coupon: salesName,
            quantity,
          })
        ),
        value: products.reduce((c, { price, quantity }) => c + price * quantity, 0) / 100,
        shipping: 0,
        user_data: {
          email_address: !differentShipping ? billing.email : shipping.email,
          phone_number: !differentShipping ? billing.cellNumber : shipping.cellNumber,
          address: {
            first_name: !differentShipping ? billing.firstName : shipping.firstName,
            last_name: !differentShipping ? billing.lastName : shipping.lastName,
            zip: !differentShipping ? billing.zip : shipping.zip,
            state: !differentShipping ? billing.state : shipping.state,
            country: !differentShipping ? billing.country : shipping.country,
            city: !differentShipping ? billing.city : shipping.city,
          },
        },
      };
      if (meta.shippingOption && meta.shippingOption.carrierName) {
        data.shipping_tier = `${meta.shippingOption.carrierName} ${meta.shippingOption.serviceName}`;
      }
      if (info.affiliate) {
        data.affiliation = info.affiliate;
      }
      if (meta.paymentMethod && meta.paymentMethod.id) {
        data.payment_type = meta.paymentMethod.id;
      }
      if (meta.totals && meta.totals.shippingCost) {
        data.shipping = meta.totals.shippingCost / 100;
      }
      if (meta.totals && meta.totals.vat) {
        data.tax = meta.totals.vat / 100;
      }
      if (orderId) {
        data.transaction_id = orderId;
      }
      this.$store.dispatch("shared/sendAnalytics", data);
    },
  },
  watch: {
    authReady: {
      immediate: true,
      handler(authReady) {
        if (authReady) {
          this.init();
        }
      },
    },
  },
};
</script>
