var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProductCard',{staticClass:"w-full block relative px-4 border mb-1 z-10 hover:shadow-md rounded-md flex items-center bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700",staticStyle:{"height":"94px"},attrs:{"product":_vm.product,"index":_vm.index,"listId":_vm.listId,"listName":_vm.listName},scopedSlots:_vm._u([{key:"default",fn:function({
      product: {
        texts: { primaryAttribute, secondaryAttribute, remainingAttributes },
        msrp,
        discount,
        bullets,
      },
      displayLabels,
    }){return [_vm._l((displayLabels),function({ text, classes },index){return _c('div',{key:text,staticClass:"absolute left-0 top-0",style:(`margin-top: ${index * 2}rem`)},[_c('div',{staticClass:"h-0 relative",class:classes,style:(`width: ${
          9 - index
        }rem; border-top-width: 28px; border-right: 10px solid transparent;`)},[_c('div',{staticClass:"text-sm absolute inset-0 py-1 pl-4 overflow-visible font-medium uppercase",staticStyle:{"top":"-29px"},domProps:{"textContent":_vm._s(text)}})])])}),_c('div',{staticClass:"flex-basis-20 ml-36 whitespace-nowrap truncate overflow-hidden mr-4"},[_c('h4',{staticClass:"font-medium text-lg leading-tight",domProps:{"innerHTML":_vm._s(primaryAttribute)}}),_c('h5',{staticClass:"font-light text-base truncate",domProps:{"innerHTML":_vm._s(secondaryAttribute)}}),_c('h6',{staticClass:"font-light text-sm",domProps:{"innerHTML":_vm._s(remainingAttributes)}})]),(bullets.length)?_c('div',{staticClass:"flex-basis-20 flex-shrink flex-grow whitespace-nowrap truncate overflow-hidden mr-4"},_vm._l((bullets),function(bullet,index){return _c('div',{key:`${index}${bullet}`,staticClass:"flex flex-shrink items-center text-sm"},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"}})]),_c('p',{staticClass:"w-full whitespace-nowrap truncate overflow-hidden",staticStyle:{"min-width":"0"},domProps:{"innerHTML":_vm._s(bullet)}})])}),0):_vm._e(),_c('ProductPrice',{staticClass:"flex-shrink-0 mr-4",attrs:{"msrp":msrp,"currency":_vm.$store.state.store.currency,"discount":discount,"showWithVat":_vm.$store.state.store.showWithVat,"quantity":1}}),_c('button',{staticClass:"main-button flex-shrink-0",staticStyle:{"min-width":"100px"},domProps:{"textContent":_vm._s(_vm.$t('shop.view'))}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }