<template>
  <section class="px-4 dark:text-white">
    <div class="w-full flex justify-around items-center mb-6 mt-2 addclub-header">
      <div class="h-full flex flex-col justify-center flex-shrink-0">
        <div class="flex items-center">
          <svg
            viewBox="0 0 24 24"
            class="w-12 h-12 mr-2 mt-2 fill-current"
          >
            <path d="M4.86,17.4c0.14,0.14,0.29,0.26,0.46,0.36c-0.24-0.18-1-0.84-0.32-2.91c0.44-1.34,1.13-2.52,2.68-4.27
              c0.07-0.08,0.15-0.16,0.22-0.24c0.03-0.03,0.06-0.06,0.09-0.09c0.04-0.05,0.09-0.09,0.13-0.14C8.16,10.07,8.2,10.03,8.23,10
              c0.04-0.04,0.07-0.07,0.11-0.1c0.04-0.04,0.09-0.08,0.13-0.12C8.5,9.74,8.53,9.72,8.56,9.69c0.05-0.04,0.1-0.09,0.15-0.13
              c0.02-0.02,0.05-0.04,0.07-0.06c0.06-0.05,0.11-0.09,0.17-0.14c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05,0.13-0.1,0.19-0.15
              c0,0,0.01-0.01,0.01-0.01c0.65-0.48,1.29-0.83,1.93-1.07v0c0.66-0.26,1.31-0.4,1.91-0.46c-0.36,0.04-0.73,0.1-1.11,0.2
              c0.56-0.08,1.34-0.04,1.67,0.76c0.55,1.31-0.51,3.58-1.34,4.8c-0.06,0.08-0.11,0.17-0.17,0.25c-0.02,0.03-0.04,0.06-0.06,0.09
              c-0.04,0.06-0.08,0.11-0.13,0.17c-0.02,0.03-0.05,0.06-0.07,0.09c-0.05,0.07-0.1,0.14-0.16,0.2c-0.01,0.02-0.03,0.03-0.04,0.05
              c-0.07,0.09-0.15,0.18-0.22,0.27c0,0-0.01,0.01-0.01,0.01c-0.07,0.09-0.15,0.18-0.23,0.26c0,0.01-0.01,0.01-0.01,0.02
              c-0.9,1.02-1.99,1.98-3.03,2.57h0.01c-0.84,0.47-1.66,0.71-2.35,0.54C5.4,17.84,5.1,17.66,4.86,17.4z M23.09,17.39L23.09,17.39
              c0,0-0.01-0.01-0.01-0.02c-0.79-1.75-1.78-3.93-2.73-6.04c-0.15-0.32-3.46-7.73-3.46-7.73S15.28-0.08,12,0
              C8.35,0.09,7.12,3.61,7.12,3.61S5.43,7.5,3.9,10.96v0.01c-0.79,1.72-2.44,5.36-2.76,6.02c-0.95,1.93-1.26,3.39-1.1,4.38
              c0.23,1.12,0.94,2.14,1.94,2.46c1.17,0.38,2.29,0.06,3.58-0.72c0.02-0.01,0.04-0.02,0.06-0.03c0.1-0.06,0.19-0.12,0.29-0.18
              c1.91-1.18,4.88-2.37,7.68-1.81c2.98,0.6,4.55,2.93,6.78,2.93c1.52,0,2.92-0.92,3.41-2.13C24.51,20.27,23.22,17.63,23.09,17.39z"
            />
          </svg>
          <h1 class="tracking-wide font-bold" v-text="'add:club'" />
        </div>
        <h2 class="tracking-wide font-thin" v-text="$t('addclub.customerClub')" />
      </div>
      <div class="flex-shrink">
      <img
        :src="imgixClient.buildURL('addclub/add_club_coins.png', {auto: 'format,compress', ch: 'DPR', q: 45, fit: 'clip', h: 126})"
        alt="add:club"
        class="object-contain"
        style="max-height: 126px"
      />
      </div>
    </div>
    <div class="flex justify-center mb-12">
      <div class="grid grid-cols-2 gap-10">
        <div class="col-span-2 md:col-span-1">
          <h4 class="font-medium" v-text="$t('addclub.perksHeader')" />
          <ul class="list-outside list-decimal">
            <li
              v-for="index in 5"
              :key="`perks${index}`"
              v-text="$t(`addclub.perks${index}`)"
              class="my-2"
            />
          </ul>
        </div>
        <div class="col-span-2 md:col-span-1">
          <h4 class="font-medium" v-text="$t('addclub.worksHeader')" />
          <ul class="list-outside list-disc">
            <li
              v-for="index in 7"
              :key="`works${index}`"
              v-text="$t(`addclub.works${index}`, worksData)"
              class="my-2"
            />
          </ul>
        </div>
      </div>
    </div>
    <div class="mb-6">
      <div
        v-for="index in 3"
        :key="`notes${index}`"
        v-text="`${index}) ${$t(`addclub.notes${index}`)}`"
        class="text-xs italic"
      />
      <router-link
        class="text-xs underline my-0"
        to="/terms"
        v-text="$t('addclub.fullTerms')"
      />
    </div>
  </section>
</template>
<script>
import imgixClient from '@/services/imgixClient';
export default {
  name: 'AddClub',
  data() {
    return {
      imgixClient,
    }
  },
  computed: {
    worksData() {
      if (!this.$store.state.resources.addClubInfo.tiers) return {}
      return {
        currency: this.$store.state.store.currency,
        ...this.$store.state.resources.addClubInfo.tiers.reduce((c, { name, rewardPercentage }) => {
          c[name] = rewardPercentage * 100
          return c
        }, {}),
        pointExpiryMonths: Math.round(this.$store.state.resources.addClubInfo.pointExpiryDays / 30),
        invitationBonus: this.$store.state.resources.addClubInfo.invitationBonus[this.$store.state.store.currency],
        levelPeriodMonths: Math.round(this.$store.state.resources.addClubInfo.levelPeriodDays / 30)
      }
    },
  }
};
</script>
<style scoped>
.addclub-header {
  height: 100px;
}
@media (min-width: 640px) {
  .addclub-header {
    height: 250px !important;
  } 
}
</style>