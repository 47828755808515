<template>
  <section class="w-full">
    <KnowledgeHeader />
    <div class="flex w-full dark:text-white">
      <div class="flex-grow px-4 lg:px-12">
        <section class="flex justify-center mt-8 mb-24">
          <div>
            <h3 class="font-medium pb-2 border-b" v-text="'Topics'" />
            <div class="grid grid-cols-2">
              <div
                v-for="{ text, description, link, icon } in categories"
                :key="link"
                class="col-span-2 sm:col-span-1 mr-4"
              >
                <router-link
                  :to="`/knowledge/browse/${link}`"
                  class="border-b h-full flex items-center py-6 px-4 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 bg-opacity-75 hover:shadow-sm"
                >
                  <svg class="w-12 h-12 mr-4 fill-current" viewBox="0 0 24 24">
                    <path :d="icon" />
                  </svg>
                  <div>
                    <h5 class="font-medium" v-text="text" />
                    <p class="max-w-sm" v-text="description" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import KnowledgeHeader from '@/components/knowledge/KnowledgeHeader.vue';

export default {
  name: 'KnowledgeBase',
  components: {
    KnowledgeHeader,
  },
  data() {
    return {
      categories: [
        { 
          text: this.$t('knowledgeBase.useCasesText'),
          description: this.$t('knowledgeBase.useCasesDescription'),
          link: 'Use cases',
          icon: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z'
        },
        { 
          text: this.$t('knowledgeBase.tipsText'),
          description: this.$t('knowledgeBase.tipsDescription'),
          link: 'Tips',
          icon: 'M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63Z'
        },
        { 
          text: this.$t('knowledgeBase.printGuidesText'),
          description: this.$t('knowledgeBase.printGuidesDescription'),
          link: 'Print guides',
          icon: 'M3,17V19H9V17H3M3,5V7H13V5H3M13,21V19H21V17H13V15H11V21H13M7,9V11H3V13H7V15H9V9H7M21,13V11H11V13H21M15,9H17V7H21V5H17V3H15V9Z'
        },
        {
          text: this.$t('knowledgeBase.cheatSheetsText'),
          description: this.$t('knowledgeBase.cheatSheetsDescription'),
          link: 'Cheat sheets',
          icon: 'M6 2C4.89 2 4 2.89 4 4V20A2 2 0 0 0 6 22H12.68A7 7 0 0 1 12 19A7 7 0 0 1 19 12A7 7 0 0 1 20 12.08V8L14 2H6M13 3.5L18.5 9H13V3.5M18 14C17.87 14 17.76 14.09 17.74 14.21L17.55 15.53C17.25 15.66 16.96 15.82 16.7 16L15.46 15.5C15.35 15.5 15.22 15.5 15.15 15.63L14.15 17.36C14.09 17.47 14.11 17.6 14.21 17.68L15.27 18.5C15.25 18.67 15.24 18.83 15.24 19C15.24 19.17 15.25 19.33 15.27 19.5L14.21 20.32C14.12 20.4 14.09 20.53 14.15 20.64L15.15 22.37C15.21 22.5 15.34 22.5 15.46 22.5L16.7 22C16.96 22.18 17.24 22.35 17.55 22.47L17.74 23.79C17.76 23.91 17.86 24 18 24H20C20.11 24 20.22 23.91 20.24 23.79L20.43 22.47C20.73 22.34 21 22.18 21.27 22L22.5 22.5C22.63 22.5 22.76 22.5 22.83 22.37L23.83 20.64C23.89 20.53 23.86 20.4 23.77 20.32L22.7 19.5C22.72 19.33 22.74 19.17 22.74 19C22.74 18.83 22.73 18.67 22.7 18.5L23.76 17.68C23.85 17.6 23.88 17.47 23.82 17.36L22.82 15.63C22.76 15.5 22.63 15.5 22.5 15.5L21.27 16C21 15.82 20.73 15.65 20.42 15.53L20.23 14.21C20.22 14.09 20.11 14 20 14H18M19 17.5C19.83 17.5 20.5 18.17 20.5 19C20.5 19.83 19.83 20.5 19 20.5C18.16 20.5 17.5 19.83 17.5 19C17.5 18.17 18.17 17.5 19 17.5Z'
        },
        { 
          text: this.$t('knowledgeBase.troubleshootingText'),
          description: this.$t('knowledgeBase.troubleshootingDescription'),
          link: 'Troubleshooting',
          icon: 'M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z'
        },
        {
          text: this.$t('knowledgeBase.glossaryText'),
          description: this.$t('knowledgeBase.glossaryDescription'),
          link: 'Glossary',
          icon: 'M12.87,15.07L10.33,12.56L10.36,12.53C12.1,10.59 13.34,8.36 14.07,6H17V4H10V2H8V4H1V6H12.17C11.5,7.92 10.44,9.75 9,11.35C8.07,10.32 7.3,9.19 6.69,8H4.69C5.42,9.63 6.42,11.17 7.67,12.56L2.58,17.58L4,19L9,14L12.11,17.11L12.87,15.07M18.5,10H16.5L12,22H14L15.12,19H19.87L21,22H23L18.5,10M15.88,17L17.5,12.67L19.12,17H15.88Z'
        },
        {
          text: this.$t('knowledgeBase.specificationsText'),
          description: this.$t('knowledgeBase.specificationsDescription'),
          link: 'Specifications',
          icon: 'M15,7H20.5L15,1.5V7M8,0H16L22,6V18A2,2 0 0,1 20,20H8C6.89,20 6,19.1 6,18V2A2,2 0 0,1 8,0M4,4V22H20V24H4A2,2 0 0,1 2,22V4H4Z'
        },
      ]
    };
  },
};
</script>
