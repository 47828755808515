<template>
  <div class="flex flex-col space-y-16 md:space-y-0 md:flex-row items-center dark:text-white">
    <div class="space-y-10">
      <div class="flex flex-col items-center">
        <svg class="w-24 h-24 fill-current" viewBox="0 0 24 24">
          <path
            d="M6,3V4.18L8.82,7H18V3H6M2.28,3L1,4.27L4.75,8C3.19,8.15 2,9.44 2,11V17H6V21H17.73L19.73,23L21,21.72L2.28,3M9.82,8L18.82,17H22V11A3,3 0 0,0 19,8H9.82M19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10M8,14H10.73L15.73,19H8V14Z"
          />
        </svg>
        <div class="font-bold text-lg" v-text="$t('contact.printerError')" />
        <router-link to="/knowledge" class="underline" v-text="$t('contact.printerText')" />
      </div>
      <div
        class="bg-gray-200 rounded-full w-64 h-64 border border-gray-300 flex flex-col items-center justify-center text-gray-700"
      >
        <svg class="w-32 h-32 text-gray-800 fill-current" viewBox="0 0 24 24">
          <path
            d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
          />
        </svg>
        <div v-text="$t('contact.callHours')" />
        <div v-text="$t('contact.callLanguages')" />
        <a href="tel:+46107500991">+46(0)10 75 00 99 1</a>
      </div>
    </div>
    <div class="flex flex-col relative md:order-first md:mr-8 lg:mr-12">
      <h3 v-text="$t('contact.contactUs')" />
      <div style="max-width: 550px">
        <p class="mt-1 mb-4" v-text="$t('contact.text')" />
        <router-link
          class="mb-4 text-black underline block"
          to="/faq"
          v-text="$t('contact.text2')"
        />
        <router-link
          class="mb-4 text-black underline block"
          to="/knowledge"
          v-text="$t('contact.text3')"
        />
      </div>
      <form id="contact" ref="contact" @submit.prevent="send">
        <div class="shadow-lg bg-white rounded-xl">
          <div class="flex">
            <div class="styled-form w-1/2">
              <input
                name="firstname"
                class="w-full rounded-tl-xl"
                type="text"
                placeholder=" "
                autocomplete="given-name"
                required
              />
              <label class="floating-label" v-text="`${$t('forms.billing.firstName')} *`" />
            </div>
            <div class="styled-form w-1/2">
              <input
                name="lastname"
                autocomplete="family-name"
                class="w-full rounded-tr-xl"
                type="text"
                placeholder=" "
                required
              />
              <label class="floating-label" v-text="`${$t('forms.billing.lastName')} *`" />
            </div>
          </div>
          <div class="styled-form w-full">
            <input
              name="email"
              autocomplete="email"
              class="w-full"
              type="email"
              placeholder=" "
              required
            />
            <label class="floating-label" v-text="`${$t('common.email')} *`" />
          </div>
          <div class="flex">
            <div class="styled-form w-1/2">
              <input
                class="w-full"
                autocomplete="organization"
                name="company"
                type="text"
                placeholder=" "
              />
              <label class="floating-label" v-text="$t('common.company')" />
            </div>
            <div class="styled-form w-1/2">
              <input
                class="w-full"
                autocomplete="tel"
                name="mobilephone"
                type="tel"
                placeholder=" "
              />
              <label class="floating-label" v-text="$t('forms.billing.cellNumber')" />
            </div>
          </div>
          <div class="flex">
            <div class="styled-form w-1/2">
              <input name="batchnumber" class="w-full" type="number" placeholder=" " />
              <label class="floating-label" v-text="$t('forms.batchNumber')" />
            </div>
            <div class="styled-form w-1/2">
              <input class="w-full" name="attachments" type="file" multiple />
            </div>
          </div>
          <div class="styled-form w-full">
            <textarea
              name="msg"
              class="w-full rounded-bl-xl rounded-br-xl"
              placeholder=" "
              rows="4"
              style="margin-bottom: -6px"
              required
            />
            <label class="floating-label" v-text="`${$t('forms.message')} *`" />
          </div>
        </div>
        <button
          class="uppercase main-button w-full mt-8"
          type="submit"
          v-text="$t('forms.send')"
          :disabled="loading"
        />
        <input type="hidden" name="form_type" value="general_contact" />
      </form>
      <div v-if="sent" class="text-green-300 my-3" v-text="$t('contact.sent')" />
      <div v-if="error" class="text-red-300 my-3" v-text="$t('errors.contactError')" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ContactForm",
  data() {
    return {
      loading: false,
      sent: false,
      error: false,
    };
  },
  methods: {
    async send() {
      try {
        this.sent = false;
        this.error = null;
        this.loading = true;
        await axios.post(
          "https://forms.hubspot.com/uploads/form/v2/7263663/e8d3e743-ade0-44bf-9f7f-5be5cd694e56",
          new FormData(this.$refs.contact)
        );
        this.sent = true;
        this.$refs.contact.reset();
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
