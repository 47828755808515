<template>
  <section class="grid grid-cols-1 lg:grid-cols-2 gap-6">
    <AccountAgreements
      class="lg:col-span-1"
      v-if="$store.getters['account/account'] && $store.getters['account/account'].agreements.length"
    />
    <AccountAddclub
      class="lg:col-span-1"
    />
      
    <!-- <div  class="lg:col-span-1">
      <h3 class="mx-4" v-text="'RE-ADD'" />
      <div class="border bg-green-600 shadow-md bg-opacity-25 p-6 relative rounded-sm">
        <div
          class="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center flex-col text-white z-10"
          v-if="!$store.getters['account/loggedIn']"
        >
          <div v-text="$t('account.needLogin')" />
          <router-link
            class="underline"
            :to="`/account/login?redirect=${encodeURIComponent($router.currentRoute.path)}`"
            v-text="$t('common.login')"
          />
        </div>
        <div class="width: 300px; height: 100px">
          Coming soon...
        </div>
      </div>
    </div> -->
  </section>
</template>
<script>
import AccountAgreements from './AccountAgreements.vue';
import AccountAddclub from './AccountAddclub.vue';

export default {
  name: 'Account',
  components: {
    AccountAgreements,
    AccountAddclub
  },
};
</script>