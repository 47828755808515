<template>
  <ImageCarousel
    :slides="sortedImages"
    :arrows=true
  >
    <template v-slot:slide="{ slide: { imgixPath, description = 'add:north' }}">
      <img
        :src="imgixClient.buildURL(imgixPath, {auto: 'format,compress', ch: 'DPR', q: 75, fit: 'clip'})"
        :srcSet="imgixClient.buildSrcSet(imgixPath, {auto: 'format,compress', ch: 'DPR', q: 75, fit: 'clip'})"
        :alt="`${description} 3D-filament`"
      />
    </template>
  </ImageCarousel>
</template>
<script>
import imgixClient from "../../services/imgixClient";
import ImageCarousel from "../shared/ImageCarousel.vue";
export default {
  name: "Images",
  components: {
    ImageCarousel
  },
  props: {
    variation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      imgixClient,
    };
  },
  computed: {
    images() {
      return this.variation && this.variation.images ? this.variation.images : [];
    },
    sortedImages() {
      const images = [...this.images.filter(({ type }) => ["image"].includes(type))].sort((a, b) => {
        if (a.thumbnail) return -1;
        if (b.thumbnail) return 1;
        return 0;
      });
      if (!images.length && this.variation) images.push({ imgixPath: "placeholder.svg" });
      return images;
    }
  }
};
</script>