<template>
  <section>
    <div class="w-full py-16 px-4">
      <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <h1 class="font-bold mb-2 border-b" v-text="$t('meta.partnerProgram.title')" />
        <h2 class="text-2xl font-light text-gray-700 max-w-2xl">
          {{ $t("partnerProgram.heading") }}
        </h2>
      </div>
    </div>
    <div class="bg-white py-16 px-4">
      <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <h3 class="font-bold text-center">{{ $t("partnerProgram.partnershipsTitle") }}</h3>
        <p class="text-2xl font-light text-gray-700 text-center max-w-4xl mx-auto mb-4">
          {{ $t("partnerProgram.partnershipsText") }}
        </p>
        <table class="w-full partner-program">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("partnerProgram.benefits") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h6>{{ $t("partnerProgram.sales") }}</h6>
                <p>{{ $t("partnerProgram.salesText") }}</p>
              </td>
              <td>
                <ul>
                  <li>{{ $t("partnerProgram.salesBullet.1") }}</li>
                  <li>{{ $t("partnerProgram.salesBullet.2") }}</li>
                  <li v-html="$t('partnerProgram.salesBullet.3')"></li>
                  <li>{{ $t("partnerProgram.salesBullet.4") }}</li>
                  <li>{{ $t("partnerProgram.salesBullet.5") }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <h6>{{ $t("partnerProgram.technical") }}</h6>
                <p>{{ $t("partnerProgram.technicalText") }}</p>
              </td>
              <td>
                <ul>
                  <li>{{ $t("partnerProgram.technicalBullet.1") }}</li>
                  <li>
                    {{ $t("partnerProgram.technicalBullet.2") }}
                  </li>
                  <li>
                    {{ $t("partnerProgram.technicalBullet.3") }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <h6>{{ $t("partnerProgram.operations") }}</h6>
                <p>{{ $t("partnerProgram.operationsText") }}</p>
              </td>
              <td>
                <ul>
                  <li>{{ $t("partnerProgram.operationsBullet.1") }}</li>
                  <li>{{ $t("partnerProgram.operationsBullet.2") }}</li>
                  <li>{{ $t("partnerProgram.operationsBullet.3") }}</li>
                  <li>{{ $t("partnerProgram.operationsBullet.4") }}</li>
                  <li class="coming">{{ $t("partnerProgram.operationsBullet.5") }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="w-full pt-16 pb-20 px-4">
      <div class="mx-auto max-w-md">
        <h4 class="font-bold text-4xl">{{ $t("partnerProgram.interested") }}</h4>
        <p class="mb-4">{{ $t("partnerProgram.form") }}</p>
        <form id="reseller" ref="reseller" @submit.prevent="send">
          <div class="shadow-lg bg-white rounded-xl">
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  name="firstname"
                  class="w-full rounded-tl-xl"
                  type="text"
                  placeholder=" "
                  autocomplete="given-name"
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.firstName')} *`" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  name="lastname"
                  autocomplete="family-name"
                  class="w-full rounded-tr-xl"
                  type="text"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.lastName')} *`" />
              </div>
            </div>
            <div class="styled-form w-full">
              <input
                name="email"
                autocomplete="email"
                class="w-full"
                type="email"
                placeholder=" "
                required
              />
              <label class="floating-label" v-text="`${$t('common.email')} *`" />
            </div>
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  class="w-full"
                  autocomplete="organization"
                  name="company"
                  type="text"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('common.company')} *`" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  class="w-full"
                  autocomplete="tel"
                  name="mobilephone"
                  type="tel"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.cellNumber')} *`" />
              </div>
            </div>
            <div class="styled-form w-full">
              <textarea
                name="msg"
                class="w-full rounded-bl-xl rounded-br-xl"
                placeholder=" "
                rows="4"
                style="margin-bottom: -6px"
                required
              />
              <label class="floating-label" v-text="`${$t('forms.message')} *`" />
            </div>
          </div>
          <button
            class="uppercase main-button w-full mt-8"
            type="submit"
            v-text="$t('forms.send')"
            :disabled="loading"
          />
          <input type="hidden" name="form_type" value="reseller" />
        </form>
        <div v-if="sent" class="text-green-300 my-3" v-text="$t('contact.sent')" />
        <div v-if="error" class="text-red-300 my-3" v-text="$t('errors.contactError')" />
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "BecomeReseller",
  data() {
    return {
      loading: false,
      sent: false,
      error: false,
    };
  },
  methods: {
    async send() {
      try {
        this.sent = false;
        this.error = null;
        this.loading = true;
        await axios.post(
          "https://forms.hubspot.com/uploads/form/v2/7263663/e8d3e743-ade0-44bf-9f7f-5be5cd694e56",
          new FormData(this.$refs.reseller)
        );
        this.sent = true;
        this.$refs.reseller.reset();
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.partner-program {
  tr {
    @apply flex;
    @apply flex-wrap;
    @apply lg:flex-nowrap;
    @apply lg:table-row;

    > th {
      @apply py-4;
      @apply pr-4;
      @apply pl-10;
      @apply text-left;
      @apply tracking-wide;
      @apply font-normal;
      @apply uppercase;
      @apply text-sm;
    }
    > td {
      @apply p-4;
      @apply text-left;
      &:first-child {
        @apply align-top;
      }
    }

    > td:last-child {
      @apply max-w-full;
      @media screen and (min-width: 1024px) {
        max-width: 30vw;
      }
    }
    > th:first-child {
      @apply hidden;
      @apply lg:table-cell;
    }
    > th:last-child {
      @apply w-full;
      @apply lg:w-auto;
    }
  }

  tr:not(:last-child),
  th {
    border-bottom: 1px solid rgb(201, 210, 226);
  }
  ul {
    @apply space-y-4;
    > li {
      @apply relative;
      @apply pl-7;

      > p {
        @apply font-light;
        @apply text-gray-700;
      }

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        @apply absolute;
        @apply left-0;
        @apply text-green-500;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(16, 185, 129)' class='w-6 h-6' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10C22 6.48 17.52 2 12 2z M10 17l-4-4l1.4-1.4l2.6 2.6l6.6-6.6 L18 9L10 17z' %3E%3C/path%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      }
      &.coming {
        @apply text-gray-400;
        ::before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(156, 163, 175)' class='w-6 h-6' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10C22 6.48 17.52 2 12 2z M10 17l-4-4l1.4-1.4l2.6 2.6l6.6-6.6 L18 9L10 17z' %3E%3C/path%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }
}

.partner-program tr > td:last-child {
  // max-width: max(30vw, 200px);
}
// .partner-program tr {
// @apply flex;
// @apply flex-wrap;
// }
</style>
