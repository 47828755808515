<template>
  <aside>
    <h6 class="text-xl font-light mb-2" v-text="'Table of content'" />
    <a
      v-for="{ level, id, text } in contents"
      :key="text"
      :href="`#${id}`"
      class="block truncate"
      :class="{
        'ml-4': level === 3,
        'ml-6': level === 4,
        'ml-8': level === 5,
        'ml-10': level === 6
      }"
      v-text="text"
    />
  </aside>
</template>

<script>
export default {
  name: 'DynamicNavigation',
  props: {
    content: {
      type: Array,
      default: () => ([])
    },
  },
  computed: {
    contents() {
      return this.getHeaders(this.content)
    }
  },
  methods: {
    getHeaders(content) {
      const headers = []
      for (let i = 0; i < content.length; i+= 1) {
        if (content[i].type === 'heading') {
          const { text } = ((content[i].content || []).find(i => i.type === 'text') || {})
          if (text) headers.push({ level: content[i].attrs.level, id: text.replace(/[^\w]/, ''), text })
        } else if (content[i].content && content[i].content.length) {
          headers.push(...this.getHeaders(content[i].content))
        }
      }
      return headers;
    }
  }
}
</script>