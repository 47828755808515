<template>
  <article class="dark:text-white">
    <div class="flex items-center mx-4">
      <svg viewBox="0 0 24 24" class="fill-current w-7 h-7 mr-1">
        <path d="M4.86,17.4c0.14,0.14,0.29,0.26,0.46,0.36c-0.24-0.18-1-0.84-0.32-2.91c0.44-1.34,1.13-2.52,2.68-4.27
          c0.07-0.08,0.15-0.16,0.22-0.24c0.03-0.03,0.06-0.06,0.09-0.09c0.04-0.05,0.09-0.09,0.13-0.14C8.16,10.07,8.2,10.03,8.23,10
          c0.04-0.04,0.07-0.07,0.11-0.1c0.04-0.04,0.09-0.08,0.13-0.12C8.5,9.74,8.53,9.72,8.56,9.69c0.05-0.04,0.1-0.09,0.15-0.13
          c0.02-0.02,0.05-0.04,0.07-0.06c0.06-0.05,0.11-0.09,0.17-0.14c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05,0.13-0.1,0.19-0.15
          c0,0,0.01-0.01,0.01-0.01c0.65-0.48,1.29-0.83,1.93-1.07v0c0.66-0.26,1.31-0.4,1.91-0.46c-0.36,0.04-0.73,0.1-1.11,0.2
          c0.56-0.08,1.34-0.04,1.67,0.76c0.55,1.31-0.51,3.58-1.34,4.8c-0.06,0.08-0.11,0.17-0.17,0.25c-0.02,0.03-0.04,0.06-0.06,0.09
          c-0.04,0.06-0.08,0.11-0.13,0.17c-0.02,0.03-0.05,0.06-0.07,0.09c-0.05,0.07-0.1,0.14-0.16,0.2c-0.01,0.02-0.03,0.03-0.04,0.05
          c-0.07,0.09-0.15,0.18-0.22,0.27c0,0-0.01,0.01-0.01,0.01c-0.07,0.09-0.15,0.18-0.23,0.26c0,0.01-0.01,0.01-0.01,0.02
          c-0.9,1.02-1.99,1.98-3.03,2.57h0.01c-0.84,0.47-1.66,0.71-2.35,0.54C5.4,17.84,5.1,17.66,4.86,17.4z M23.09,17.39L23.09,17.39
          c0,0-0.01-0.01-0.01-0.02c-0.79-1.75-1.78-3.93-2.73-6.04c-0.15-0.32-3.46-7.73-3.46-7.73S15.28-0.08,12,0
          C8.35,0.09,7.12,3.61,7.12,3.61S5.43,7.5,3.9,10.96v0.01c-0.79,1.72-2.44,5.36-2.76,6.02c-0.95,1.93-1.26,3.39-1.1,4.38
          c0.23,1.12,0.94,2.14,1.94,2.46c1.17,0.38,2.29,0.06,3.58-0.72c0.02-0.01,0.04-0.02,0.06-0.03c0.1-0.06,0.19-0.12,0.29-0.18
          c1.91-1.18,4.88-2.37,7.68-1.81c2.98,0.6,4.55,2.93,6.78,2.93c1.52,0,2.92-0.92,3.41-2.13C24.51,20.27,23.22,17.63,23.09,17.39z"
        />
      </svg>
      <h2 class="font-bold" v-text="'add:club'" />
    </div>
    <div class="shadow-md p-6 relative rounded-sm bg-white dark:bg-gray-800 bg-opacity-25">
      <div
        class="absolute inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center flex-col text-white z-10 dark"
        v-if="!$store.getters['account/loggedIn']"
      >
        <div class="text-center mb-6" v-text="$t('account.needLogin')" />
        <Login />
      </div>
      <div
        class="absolute inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center flex-col text-white z-10"
        v-else-if="!$store.getters['account/account'].addClub"
      >
        <div class="mb-4" v-text="$t('account.addClubSignUpText')" />
        <button
          class="px-4 py-2 rounded-md bg-gray-100 text-gray-800 font-medium uppercase hover:bg-white flex items-center"
          @click="joinClub"
          :disabled="joining"
        >
          <div v-text="$t('account.addClubSignUp')" />
          <div v-if="joining" class="m-auto spinner ml-4" />
        </button>
      </div>
      <div class="flex flex-col items-center">
        <div class="flex flex-wrap justify-between w-full">
          <div class="mb-4">
            <div
              class="uppercase font-bold"
              v-text="$t('account.myPoints')"
            />
            <div
              class="text-2xl leading-5"
              v-if="addClubAvailablePoints !== null"
              v-text="addClubAvailablePoints.toLocaleString('sv')"
            />
          </div>
          <div class="mb-4 bg-white text-gray-700 p-4" style="min-width: 220px">
            <div
              class="font-bold uppercase mb-2"
              v-text="$t('account.expiringPoints')"
            />
            <ul class="list-none text-sm text-gray-700">
              <li
                v-if="!addClubExpiringPoints.length"
                class="text-center bg-gray-100 py-2 italic rounded-sm"
                v-text="$t('account.noExpiringPoints')"
              />
              <li
                v-for="({ date, points }, index) in addClubExpiringPoints"
                :key="date"
                class="p-2 flex flex-wrap rounded-sm"
                :class="{
                  'bg-gray-100': index % 2 === 0,
                }"
              >
                <div class="flex-shrink-0 mr-4" v-text="date" />
                <div
                  class="flex-shrink-0 flex-grow text-right"
                  v-text="`${points.toLocaleString('sv')} p`"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full" v-if="addClubPeriod">
          <div
            class="font-bold uppercase mb-2"
            v-text="$t('account.membershipTier')"
          />
          <div class="flex justify-between flex-wrap mb-4">
            <div class="mr-2">
              <div class="font-medium" v-text="$t('addclub.currentTier')" />
              <div class="uppercase" v-text="currentLevel.name" />
            </div>
            <div>
              <div
                class="font-medium"
                v-text="$t('account.qualificationPeriod')"
              />
              <div class="flex">
                <div>{{ addClubPeriod.start | timestamp }}</div>
                <div class="mx-1" v-text="'-'" />
                <div>{{ addClubPeriod.end | timestamp }}</div>
              </div>
            </div>
          </div>
          <div class="meter mt-12 mb-6">
            <span><span /></span>
            <div
              class="absolute left-0 font-medium text-sm"
              style="top: -2.2rem"
              v-text="`${qualificationProgress}%`"
            />
            <div
              v-if="nextLevel"
              class="absolute right-0 text-sm"
              style="top: -2.2rem"
              v-html="
                `<b>${qualificationPointsLeft.toLocaleString(
                  'sv'
                )}p</b> to ${nextLevel.name.toUpperCase()}`
              "
            />
            <div
              class="absolute z-10 inset-x-0 left-0 flex justify-end"
              style="top: -0.75rem; left: -13px"
              :style="`width: max(${qualificationProgress}%, 2rem)`"
            >
              <div class="w-8 h-8 rounded-full">
                <svg
                  viewBox="0 0 24 24"
                  class="w-8 h-8 fill-current"
                >
                  <path d="M4.86,17.4c0.14,0.14,0.29,0.26,0.46,0.36c-0.24-0.18-1-0.84-0.32-2.91c0.44-1.34,1.13-2.52,2.68-4.27
                    c0.07-0.08,0.15-0.16,0.22-0.24c0.03-0.03,0.06-0.06,0.09-0.09c0.04-0.05,0.09-0.09,0.13-0.14C8.16,10.07,8.2,10.03,8.23,10
                    c0.04-0.04,0.07-0.07,0.11-0.1c0.04-0.04,0.09-0.08,0.13-0.12C8.5,9.74,8.53,9.72,8.56,9.69c0.05-0.04,0.1-0.09,0.15-0.13
                    c0.02-0.02,0.05-0.04,0.07-0.06c0.06-0.05,0.11-0.09,0.17-0.14c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05,0.13-0.1,0.19-0.15
                    c0,0,0.01-0.01,0.01-0.01c0.65-0.48,1.29-0.83,1.93-1.07v0c0.66-0.26,1.31-0.4,1.91-0.46c-0.36,0.04-0.73,0.1-1.11,0.2
                    c0.56-0.08,1.34-0.04,1.67,0.76c0.55,1.31-0.51,3.58-1.34,4.8c-0.06,0.08-0.11,0.17-0.17,0.25c-0.02,0.03-0.04,0.06-0.06,0.09
                    c-0.04,0.06-0.08,0.11-0.13,0.17c-0.02,0.03-0.05,0.06-0.07,0.09c-0.05,0.07-0.1,0.14-0.16,0.2c-0.01,0.02-0.03,0.03-0.04,0.05
                    c-0.07,0.09-0.15,0.18-0.22,0.27c0,0-0.01,0.01-0.01,0.01c-0.07,0.09-0.15,0.18-0.23,0.26c0,0.01-0.01,0.01-0.01,0.02
                    c-0.9,1.02-1.99,1.98-3.03,2.57h0.01c-0.84,0.47-1.66,0.71-2.35,0.54C5.4,17.84,5.1,17.66,4.86,17.4z M23.09,17.39L23.09,17.39
                    c0,0-0.01-0.01-0.01-0.02c-0.79-1.75-1.78-3.93-2.73-6.04c-0.15-0.32-3.46-7.73-3.46-7.73S15.28-0.08,12,0
                    C8.35,0.09,7.12,3.61,7.12,3.61S5.43,7.5,3.9,10.96v0.01c-0.79,1.72-2.44,5.36-2.76,6.02c-0.95,1.93-1.26,3.39-1.1,4.38
                    c0.23,1.12,0.94,2.14,1.94,2.46c1.17,0.38,2.29,0.06,3.58-0.72c0.02-0.01,0.04-0.02,0.06-0.03c0.1-0.06,0.19-0.12,0.29-0.18
                    c1.91-1.18,4.88-2.37,7.68-1.81c2.98,0.6,4.55,2.93,6.78,2.93c1.52,0,2.92-0.92,3.41-2.13C24.51,20.27,23.22,17.63,23.09,17.39z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mt-4 mb-8">
          <div class="mb-2">
            <div
              class="font-bold uppercase mb-2"
              v-text="$t('account.referTitle')"
            />
            <input type="hidden" id="referalLink" :value="referalLink" />
            <button class="flex items-center" @click="copyReferal">
              <svg
                class="w-5 h-5 mr-1 fill-current"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
              <div v-text="$t('account.referLink')" />
            </button>
          </div>
          <form @submit.prevent="sendReferal">
            <div class="styled-form w-64 relative">
              <input
                name="email"
                class="w-full rounded-md"
                type="email"
                v-model.trim="$v.email.$model"
                placeholder=" "
                :class="{
                  'error': $v.email.$error,
                }"
              />
              <label
                for="email"
                class="floating-label"
                v-text="$t('account.sendInvite')"
              />
              <div
                class="absolute inset-0 dark:text-white text-gray-700"
                :class="{
                  spinner: sending,
                  hidden: !sending,
                }"
              />
              <button
                type="submit"
                class="absolute right-0 top-0 mr-2 bottom-0 font-bold rounded focus:outline-none"
                :disabled="sending"
                :aria-label="$t('account.sendInvite')"
              >
                <svg
                  class="fill-current dark:text-white text-gray-700"
                  height="28"
                  width="28"
                  viewBox="0 0 24 24"
                >
                  <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                </svg>
              </button>
            </div>
            <div
              v-if="referralError"
              v-text="$t('errors.referalError')"
              class="mx-2 mt-1 text-red-400"
            />
            <div
              v-if="referralSuccess"
              v-text="$t('account.referalSuccess')"
              class="mt-1 text-green-400"
            />
          </form>
        </div>
        <div class="bg-white text-gray-700 p-4 w-full">
          <div
            class="font-bold uppercase mb-2"
            v-text="$t('account.activity')"
          />
          <ul class="list-none text-xs text-gray-700">
            <li
              v-if="!addClubActivity.length"
              class="text-center bg-gray-100 py-2 italic rounded-sm"
              v-text="$t('account.noActivity')"
            />
            <li
              v-for="({ pointId, type, orderId, eventId, created, points }, index) in addClubActivity"
              :key="pointId"
              class="p-2 flex rounded-sm"
              :class="{
                'bg-gray-100': index % 2 === 0,
              }"
            >
              <div class="flex-shrink-0">
                {{ created | timestamp }}
              </div>
              <div
                class="uppercase mx-4 w-20"
                v-text="$t(`addClubType.${type}`)"
              />
              <div class="flex-grow flex justify-end">
                <div
                  v-if="['reward', 'rewardChange', 'redemption'].includes(type)"
                  class="italic flex-grow mr-4 overflow-hidden truncate hidden sm:block"
                  v-text="`Order: ${orderId}. Event: ${eventId}`"
                />
                <div
                  class="flex-shrink-0 text-right"
                  v-text="
                    `${points >= 0 ? '+' : ''}${points.toLocaleString('sv')} p`
                  "
                />
              </div>
            </li>
          </ul>
        </div>
        <router-link
          to="/account/addclub"
          class="text-sm w-full mt-4 underline"
          v-text="$t('account.addclubDetails')"
        />
      </div>
    </div>
  </article>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import Login from './Login.vue';

export default {
  name: "AccountAddclub",
  components: {
    Login,
  },
  data() {
    return {
      email: null,
      sending: false,
      joining: false,
      referralSuccess: false,
      referralError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    account() {
      return this.$store.getters['account/account'];
    },
    addClubAvailablePoints() {
      if (!this.account) return null;
      return this.account.addClubAvailablePoints;
    },
    addClubExpiringPoints() {
      if (!this.account) return [];
      const points = this.account.addClubActivePoints
        .reduce((c, { expires, points }) => {
          const date = this.$timestamp(expires);
          const index = c.findIndex((j) => j.date === date);
          if (index === -1) {
            c.push({ date, points });
          } else {
            c[index].points += points;
          }
          return c;
        }, []);
      points.sort((a, b) => a.date.localeCompare(b.date));
      return points;
    },
    addClubPeriod() {
      if (!this.account) return null;
      return this.account.addClubPeriod;
    },
    addClubActivity() {
      if (!this.account) return [];
      return this.account.addClubActivity;
    },
    currentLevel() {
      if (!this.addClubPeriod) return null;
      return this.addClubPeriod.membership[this.$store.state.store.currency].current
    },
    nextLevel() {
      if (!this.addClubPeriod || !this.addClubPeriod.membership[this.$store.state.store.currency].next) return null;
      return this.addClubPeriod.membership[this.$store.state.store.currency].next
    },
    qualificationProgress() {
      if (!this.addClubPeriod) return null;
      if (!this.nextLevel) return 100;
      return Math.min(
        100,
        Math.round(
          (100 * this.addClubPeriod.earnedPoints[this.$store.state.store.currency])
          / this.nextLevel.threshold
        )
      );
    },
    qualificationPointsLeft() {
      if (!this.addClubPeriod) return null;
      if (!this.nextLevel) return 0;
      return Math.max(0, this.nextLevel.threshold - this.addClubPeriod.earnedPoints[this.$store.state.store.currency]);
    },
    referalLink() {
      if (!this.account) return null;
      return `https://addnorth.${process.env.VUE_APP_DOMAIN}/account?referalToken=${this.account.accountId}`;
    },
  },
  methods: {
    joinClub() {
      this.joining = true;
      this.$api.joinClub(this.$store.state.account.accountId, this.$store.state.account.referalToken)
        .then(this.$api.listAccounts)
        .then((accounts) => { this.$store.commit("account/SET_ACCOUNTS", accounts); })
        .finally(() => { this.joining = false })
    },
    copyReferal() {
      let testingCodeToCopy = document.getElementById("referalLink");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      document.execCommand("copy");
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    sendReferal() {
      this.$v.$touch();
      this.referralSuccess = false;
      this.referralError = false;
      if (!this.$v.$invalid) {
        this.sending = true;
        this.$api
          .sendReferal(this.$store.state.account.accountId, this.email, this.$store.state.store.currency, process.env.VUE_APP_DOMAIN)
          .then(() => {
            this.referralSuccess = true;
            this.email = null;
            this.$v.$reset();
          })
          .catch(() => {
            this.referralError = true;
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.meter {
  height: 8px;
  @apply relative;
  @apply w-full;
}
.meter > span {
  @apply block;
  @apply h-full;
  @apply w-full;
  @apply rounded-sm;
  @apply relative;
  @apply overflow-hidden;
  @apply bg-gray-800;
}
.meter > span:after,
.meter > span > span {
  content: "";
  @apply absolute;
  @apply inset-0;
  @apply bg-gray-800;
  z-index: 1;
  background-size: 20px 20px;
  @apply overflow-hidden;
}
.dark .meter > span,
.dark .meter > span:after,
.dark .meter > span > span {
  @apply bg-white;
}

.meter > span:after {
  @apply hidden;
}
</style>