<template>
  <section class="w-full pb-12">
    <Map
      v-if="markers.length"
      class="w-full hidden sm:block"
      style="height: 500px"
      :zoom="4"
      control
      :lat="52.108279"
      :lng="9.217"
      :markers="markers"
    />
    <div class="w-full mb-8 mt-6 dark:text-white mx-4 md:mx-8 lg:mx-12">
      <h2 class="font-medium" v-text="$t('resellers.header')" />
      <div class="grid grid-cols-12 gap-x-4 w-full">
        <div
          v-for="{ country, locations = [] } in byCountry"
          :key="country"
          class="my-2 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
        >
          <button
            class="flex items-center w-full outline-none focus:outline-none"
            @click="expanded === country ? (expanded = null) : (expanded = country)"
          >
            <div>
              <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
                <path
                  :d="
                    expanded === country
                      ? 'M19,13H5V11H19V13Z'
                      : 'M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z'
                  "
                />
              </svg>
            </div>
            <div class="font-bold mr-2" v-text="country" />
            <div
              class="flex justify-center items-center rounded-md text-gray-800 bg-gray-300"
              style="padding: 0 0.5rem"
            >
              <div class="text-xs font-normal" v-text="locations.length" />
            </div>
          </button>
          <TransitionExpand>
            <div
              v-for="{ name, address, zip, city, contact, website, websiteDisplay } in locations"
              :key="name"
              class="ml-6 text-sm font-text-gray-700 my-3"
              :class="expanded !== country ? 'hidden' : ''"
            >
              <p class="font-bold" v-text="name" />
              <p v-if="address" v-text="address" />
              <p v-if="zip" v-text="`${zip}, ${city}`" />
              <p v-if="contact" v-text="contact" />
              <a
                v-if="website"
                rel="noreferrer"
                :href="website"
                class="underline"
                target="_blank"
                v-text="websiteDisplay"
              />
            </div>
          </TransitionExpand>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Map from "../components/other/Map.vue";
import TransitionExpand from "../components/products/TransitionExpand.vue";
import imgixClient from "@/services/imgixClient";

export default {
  name: "Resellers",
  components: {
    Map,
    TransitionExpand,
  },
  data() {
    return {
      expanded: null,
    };
  },
  computed: {
    resellers() {
      return this.$store.state.content.resellers;
    },
    markers() {
      const markers = this.resellers.reduce((c, { locations, pin, zIndex = 1 }) => {
        c.push(
          ...locations.map(
            ({
              lat,
              lng,
              name = "",
              address = "",
              zip = "",
              city = "",
              contact = "",
              website = "",
              country,
            }) => ({
              lat,
              lng,
              icon: !pin
                ? null
                : {
                    url: imgixClient.buildURL(pin.imgixPath, {
                      auto: "format,compress",
                      ch: "DPR",
                      q: 45,
                      fit: "max",
                      w: 80,
                    }),
                  },
              zIndex: parseInt(zIndex),
              infoWindow: {
                content: `<div>\
                          <div>${name}</div>\
                          <div>${address}</div>\
                          <div>${zip}, ${city}</div>\
                          <div>${contact}</div>\
                          <div><a href="${website}" target="_blank">${website}</a></div>\
                        </div>`,
              },
              name,
              address,
              zip,
              city,
              contact,
              website,
              country,
            })
          )
        );
        return c;
      }, []);
      markers.sort((a, b) => a.country.localeCompare(b.country, "sv"));
      return markers;
    },
    byCountry() {
      return this.markers.reduce((c, { country, ...i }) => {
        const foundCountry = c.findIndex((j) => j.country === country);
        if (foundCountry > -1) {
          c[foundCountry].locations.push(i);
        } else {
          c.push({ country, locations: [i] });
        }
        return c;
      }, []);
    },
  },
};
</script>
