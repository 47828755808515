<template>
  <section class="w-full flex justify-center items-center dark:text-white" style="min-height: 60vh">
    <div>
      <h3 class="mb-4 font-medium" v-text="$t('store.products.searchProduct')" />
      <form
        class="flex space-x-2"
        @submit.prevent="search"
      >
        <input
          class="border rounded-md px-4 py-2 w-64 appearance-none text-gray-700 outline-none focus:outline-none"
          id="search"
          type="text"
          :placeholder="$t('store.products.searchProduct')"
          v-model="query"
        />
        <button
          class="flex-shrink-0 px-4 uppercase relative font-medium tracking-wide rounded-md py-2 bg-gray-800 dark:bg-gray-100 dark:text-gray-700 text-white hover:bg-gray-600"
          type="submit"
        >
          <svg class="w-8 h-8 fill-current" viewBox="0 0 24 24">
            <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
          </svg>
        </button>
      </form>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SearchProducts',
  data() {
    return {
      query: null
    };
  },
  methods: {
    search() {
      this.$router.push({ path: '/shop', query: { query: this.query} })
    }
  },
};
</script>
