<template>
  <section style="width: 300px">
    <div v-if="$store.getters['account/authenticated']">
      <div class="flex items-center mb-2">
        <svg class="w-6 h-6 mr-1" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
          />
        </svg>
        <h5 v-text="$t('login.loggedIn')" />
      </div>
      <div class="flex mb-4">
        <p class="font-medium mr-2" v-text="`${$t('common.email')}:`" />
        <p v-text="$store.state.account.user.email" />
      </div>
      <button
        class="flex-shrink-0 w-full main-button"
        type="button"
        v-text="$t('common.logout')"
        @click.prevent="signOut"
      />
    </div>
    <div v-else-if="!$store.getters['account/authenticated']" class="relative">
      <div v-if="loading" class="absolute inset-0 flex justify-center items-center z-10">
        <div class="spinner large h-12 w-12" />
      </div>
      <div v-if="!showingForm">
        <div class="space-y-3">
          <button
            :aria-label="$t('login.google')"
            class="flex items-center bg-red-600 text-white px-3 py-2 hover:bg-red-400 rounded-md w-full relative whitespace-nowrap text-sm"
            @click="google"
            :disabled="loading"
          >
            <div
              class="absolute inset-0 bg-red-600 text-white spinner rounded-md"
              v-if="action === 'google'"
            />
            <svg class="w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
              <path
                d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
              />
            </svg>
            {{ $t("login.google") }}
          </button>
          <button
            :aria-label="$t('login.facebook')"
            class="flex items-center bg-blue-600 text-white px-3 py-2 hover:bg-blue-400 rounded-md w-full relative whitespace-nowrap text-sm"
            @click="facebook"
            :disabled="loading"
          >
            <div
              class="absolute inset-0 bg-blue-600 text-white spinner rounded-md"
              v-if="action === 'facebook'"
            />
            <svg class="w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
              <path
                d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
              />
            </svg>
            {{ $t("login.facebook") }}
          </button>
          <button
            :aria-label="$t('login.emailPassword')"
            class="flex items-center border border-gray-400 px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md w-full whitespace-nowrap text-sm"
            @click.prevent="showForm('loginPassword')"
            :disabled="loading"
          >
            <svg class="w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
              <path
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
            {{ $t("login.emailPassword") }}
          </button>
          <button
            :aria-label="$t('login.emailLink')"
            class="flex items-center border border-gray-400 px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md w-full whitespace-nowrap text-sm"
            @click.prevent="showForm('loginLink')"
            :disabled="loading"
          >
            <svg class="w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
              <path
                d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11.35A5.8 5.8 0 0 1 11 18A6 6 0 0 1 22 14.69V6A2 2 0 0 0 20 4M20 8L12 13L4 8V6L12 11L20 6Z"
              />
            </svg>
            {{ $t("login.emailLink") }}
          </button>
        </div>
        <hr class="m-6" />
        <button
          :aria-label="$t('login.createAccount')"
          class="flex items-center border border-gray-400 px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md w-full text-sm"
          @click.prevent="showForm('create')"
          :disabled="loading"
        >
          <svg class="w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
            <path
              d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
            />
          </svg>
          {{ $t("login.createAccount") }}
        </button>
        <div class="mt-4 text-red-400" v-if="error" v-text="error" />
      </div>
      <form v-if="showingForm === 'recover'" @submit.prevent="recoverPassword">
        <h6 class="mb-2" v-text="$t('login.recoverForm')" />
        <div class="styled-form rounded-md w-full">
          <input
            name="email"
            class="rounded-md w-full"
            type="email"
            v-model.trim="$v.recover.email.$model"
            autocomplete="email"
            placeholder=" "
            :class="{
              error: $v.recover.email.$error,
            }"
          />
          <label for="email" class="floating-label" v-text="`${$t('common.email')}`" />
        </div>
        <div class="mt-4 text-red-400" v-if="error" v-text="error" />
        <div class="mt-4 text-green-400" v-if="success" v-text="success" />
        <button
          class="mt-4 uppercase w-full main-button"
          :class="action === 'recover' ? 'hover:cursor-wait' : ''"
          type="submit"
          :disabled="$v.recover.$invalid"
        >
          <div
            v-if="action === 'recover'"
            class="absolute inset-0 flex justify-center align-center"
          >
            <div class="m-auto spinner" />
          </div>
          <div :class="action === 'recover' ? 'invisible' : ''" v-text="$t('login.recoverForm')" />
        </button>
      </form>
      <form v-if="showingForm === 'loginPassword'" @submit.prevent="loginEmailPassword">
        <h6 class="mb-2" v-text="$t('login.emailPassword')" />
        <div class="styled-form rounded-md w-full mb-4">
          <input
            name="email"
            class="rounded-md w-full"
            type="email"
            v-model.trim="$v.loginPassword.email.$model"
            placeholder=" "
            autocomplete="email"
            :class="{
              error: $v.loginPassword.email.$error,
            }"
          />
          <label for="email" class="floating-label" v-text="`${$t('common.email')}`" />
        </div>
        <div class="styled-form rounded-md w-full">
          <input
            name="password"
            class="rounded-md w-full"
            type="password"
            autocomplete="current-password"
            v-model.trim="$v.loginPassword.password.$model"
            placeholder=" "
            :class="{
              error: $v.loginPassword.password.$error,
            }"
          />
          <label for="password" class="floating-label" v-text="$t('forms.password')" />
        </div>
        <div class="mt-4 text-red-400" v-if="error" v-text="error" />
        <div class="mt-4 flex items-center">
          <button
            class="uppercase w-full main-button"
            :class="action === 'loginPassword' ? 'hover:cursor-wait' : ''"
            type="submit"
            :disabled="$v.loginPassword.$invalid"
          >
            <div
              v-if="action === 'loginPassword'"
              class="absolute inset-0 flex justify-center align-center"
            >
              <div class="m-auto spinner" />
            </div>
            <div
              :class="action === 'loginPassword' ? 'invisible' : ''"
              v-text="$t('common.login')"
            />
          </button>
        </div>
      </form>
      <form v-if="showingForm === 'loginLink'" @submit.prevent="loginEmailLink">
        <h6 class="mb-2" v-text="$t('login.emailLink')" />
        <div class="styled-form rounded-md w-full mb-4">
          <input
            name="email"
            class="rounded-md w-full"
            type="email"
            autocomplete="email"
            v-model.trim="$v.loginLink.email.$model"
            placeholder=" "
            :class="{
              error: $v.loginLink.email.$error,
            }"
          />
          <label for="email" class="floating-label" v-text="`${$t('common.email')}`" />
        </div>
        <div class="mt-4 text-green-400" v-if="success" v-text="success" />
        <div class="mt-4 text-red-400" v-if="error" v-text="error" />
        <div class="mt-4 flex items-center">
          <button
            class="uppercase w-full main-button"
            :class="action === 'loginPassword' ? 'hover:cursor-wait' : ''"
            type="submit"
            :disabled="$v.loginLink.$invalid"
          >
            <div
              v-if="action === 'loginLink'"
              class="absolute inset-0 flex justify-center align-center"
            >
              <div class="m-auto spinner" />
            </div>
            <div
              :class="action === 'loginLink' ? 'invisible' : ''"
              v-text="$t('login.sendLoginLink')"
            />
          </button>
        </div>
      </form>
      <form v-if="showingForm === 'create'" @submit.prevent="createAccount">
        <h6 class="mb-2" v-text="$t('login.createAccount')" />
        <div class="styled-form rounded-md w-full mb-4">
          <input
            name="email"
            class="rounded-md w-full"
            type="email"
            v-model.trim="$v.create.email.$model"
            placeholder=" "
            autocomplete="email"
            :class="{
              error: $v.create.email.$error,
            }"
          />
          <label for="email" class="floating-label" v-text="`${$t('common.email')}`" />
        </div>
        <div class="styled-form rounded-md w-full">
          <input
            name="password"
            class="rounded-md w-full"
            type="password"
            autocomplete="new-password"
            v-model.trim="$v.create.password.$model"
            placeholder=" "
            :class="$v.create.password.$dirty && $v.create.password.$invalid ? 'error' : ''"
          />
          <label for="password" class="floating-label" v-text="$t('forms.password')" />
        </div>
        <div class="mt-4 text-red-400" v-if="error" v-text="error" />
        <div class="mt-4 text-green-400" v-if="success" v-text="success" />
        <div class="flex items-center mt-4">
          <button
            class="uppercase w-full main-button"
            :class="action === 'create' ? 'hover:cursor-wait' : ''"
            type="submit"
            :disabled="$v.create.$invalid"
          >
            <div
              v-if="action === 'create'"
              class="absolute inset-0 flex justify-center align-center"
            >
              <div class="m-auto spinner" />
            </div>
            <div :class="action === 'create' ? 'invisible' : ''" v-text="$t('common.create')" />
          </button>
        </div>
      </form>
      <div class="mt-4 flex justify-center">
        <button
          class="underline my-4 outline-none focus:outline-none"
          v-text="!showingForm ? $t('login.recoverForm') : $t('common.back')"
          @click.prevent="showForm(!showingForm ? 'recover' : null)"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import {
  auth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  GoogleAuthProvider,
  FacebookAuthProvider,
  logOut,
} from "@/services/auth";

export default {
  name: "Login",
  data() {
    return {
      loading: true,
      showingForm: null,
      loginPassword: {
        email: "",
        password: "",
      },
      loginLink: {
        email: "",
      },
      create: {
        email: "",
        password: "",
      },
      recover: {
        email: "",
      },
      action: null,
      error: null,
      success: null,
    };
  },
  computed: {
    authReady() {
      return this.$store.state.account.authReady;
    },
  },
  validations: {
    loginPassword: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
    loginLink: {
      email: {
        required,
        email,
      },
    },
    create: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
    recover: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    showForm(form) {
      this.clearMessages();
      this.$v.$reset();
      this.showingForm = form;
    },
    clearMessages() {
      this.error = null;
      this.success = null;
    },
    google() {
      this.clearMessages();
      this.action = "google";
      signInWithPopup(auth, new GoogleAuthProvider())
        .then(() => this.analyticsLogin("google"))
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    facebook() {
      this.clearMessages();
      this.action = "facebook";
      signInWithPopup(auth, new FacebookAuthProvider())
        .then(() => this.analyticsLogin("facebook"))
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    loginEmailPassword() {
      this.clearMessages();
      this.$v.$touch();
      if (this.$v.loginPassword.$invalid) return;
      this.action = "loginPassword";
      signInWithEmailAndPassword(
        auth,
        this.loginPassword.email.toLowerCase(),
        this.loginPassword.password
      )
        .then(() => {
          this.$v.$reset();
          this.analyticsLogin("emailPassword");
        })
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    loginEmailLink() {
      this.clearMessages();
      this.$v.$touch();
      if (this.$v.loginLink.$invalid) return;
      this.action = "loginLink";
      const email = this.loginLink.email.toLowerCase();
      sendSignInLinkToEmail(auth, email, {
        url: window.location.href,
        handleCodeInApp: true,
      })
        .then(() => {
          this.success = this.$t("login.loginLinkSent");
          this.$store.commit("account/SET_LOGIN_LINK_EMAIL", email);
          this.$v.$reset();
        })
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    recoverPassword() {
      this.clearMessages();
      this.$v.$touch();
      if (this.$v.recover.$invalid) return;
      this.action = "recover";
      sendPasswordResetEmail(auth, this.recover.email, {
        url: window.location.href,
      })
        .then(() => {
          this.success = this.$t("login.resetSent");
          this.$v.$reset();
        })
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    createAccount() {
      this.clearMessages();
      this.$v.$touch();
      if (this.$v.create.$invalid) return;
      this.action = "create";
      this.$api
        .createAccount({
          auth: true,
          email: this.create.email.toLowerCase(),
          password: this.create.password,
          referalToken: this.$store.state.account.referalToken,
        })
        .then(() =>
          signInWithEmailAndPassword(auth, this.create.email.toLowerCase(), this.create.password)
        )
        .then(() => {
          this.success = this.$t("login.created");
          this.$v.$reset();
          this.analyticsSignup("email");
        })
        .catch((error) => {
          this.authErrorHandler(error);
        })
        .finally(() => {
          this.action = null;
        });
    },
    authErrorHandler(error) {
      let unknownError = false;
      switch (error.code || error.message) {
        case "auth/invalid-email":
          this.error = this.$t("formValidation.invalidEmail");
          break;
        case "auth/user-disabled":
          this.error = this.$t("errors.userDisabled");
          break;
        case "auth/user-not-found":
          this.error = this.$t("errors.userNotFound");
          break;
        case "auth/wrong-password":
          this.error = this.$t("errors.wrongPassword");
          break;
        case "auth/popup-closed-by-user":
          this.error = this.$t("errors.closedPopup");
          break;
        case "auth/too-many-requests":
          this.error = this.$t("errors.tooManyRequests");
          break;
        case "auth/email-already-exists":
          this.error = this.$t("errors.emailAlreadyExists");
          break;
        case "auth/expired-action-code":
          this.error = this.$t("errors.codeExpired");
          break;
        case "auth/invalid-action-code":
          this.error = this.$t("errors.codeExpired");
          break;
        case "auth/web-storage-unsupported":
          this.error = this.$t("errors.webStorageUnsupported");
          break;
        default:
          this.error = this.$t("errors.unknownError");
          unknownError = true;
          break;
      }
      if (unknownError && window.Rollbar)
        window.Rollbar.error(`AN Login error: ${error.message}`, error);
    },
    analyticsLogin(method) {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "login",
        method,
      });
    },
    analyticsSignup(method) {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "sign_up",
        method,
      });
    },
    signOut() {
      logOut();
    },
  },
  watch: {
    authReady: {
      immediate: true,
      handler(authReady) {
        if (authReady) {
          if (isSignInWithEmailLink(auth, window.location.href)) {
            var email =
              this.$store.state.account.loginLinkEmail ||
              window.prompt(`${this.$t("login.linkDifferentDevice")}:`);
            new Promise((resolve, reject) => {
              if (!email) reject(new Error("auth/invalid-email"));
              signInWithEmailLink(auth, email, window.location.href).then(resolve).catch(reject);
            })
              .then(() => {
                this.$store.commit("account/SET_LOGIN_LINK_EMAIL", null);
                this.analyticsLogin("emailLink");
              })
              .catch(this.authErrorHandler)
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        }
      },
    },
  },
};
</script>
