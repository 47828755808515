<template>
  <div class="flex items-center">
    <div v-if="nothingSelected" class="text-yellow-500" v-text="$t('product.selectVariation')" />
    <div v-else class="text-yellow-500" v-text="$t('product.variationNotAvailable')" />
    <button
      v-if="showSuggest && !nothingSelected"
      class="flex flex-shrink-0 items-center ml-4 text-white rounded-md py-1 px-2 border"
      :class="{
        'bg-green-400 hover:cursor-not-allowed': alreadyWished,
        'bg-blue-400 hover:bg-blue-300': !alreadyWished,
      }"
      @click="suggest"
      :disabled="alreadyWished"
    >
      <div v-if="loading" class="flex justify-center align-center">
        <div class="m-auto spinner w-6 h-6" />
      </div>
      <svg v-if="!loading" class="w-6 h-6 mr-1 fill-current stroke-current" viewBox="0 0 24 24">
        <path
          v-if="!alreadyWished"
          d="M18,13H17.32L15.32,15H17.23L19,17H5L6.78,15H8.83L6.83,13H6L3,16V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V16L18,13M17,7.95L12.05,12.9L8.5,9.36L13.46,4.41L17,7.95M12.76,2.29L6.39,8.66C6,9.05 6,9.68 6.39,10.07L11.34,15C11.73,15.41 12.36,15.41 12.75,15L19.11,8.66C19.5,8.27 19.5,7.64 19.11,7.25L14.16,2.3C13.78,1.9 13.15,1.9 12.76,2.29Z"
        />
        <path
          v-else
          d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z"
        />
      </svg>
      <div v-if="alreadyWished && !loading" v-text="$t('product.wished')" />
      <div v-else-if="!alreadyWished && !loading" v-text="$t('product.wish')" />
    </button>
  </div>
</template>
<script>
export default {
  name: "NotAvailable",
  props: {
    showSuggest: {
      type: Boolean,
      default: false,
    },
    productName: {
      type: String,
      required: true,
    },
    selectedAttributes: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    nothingSelected() {
      return Object.keys(this.query).length === 0;
    },
    suggestionValue() {
      let attributes = Object.entries(this.selectedAttributes);
      attributes.sort(
        (a, b) =>
          this.$store.state.content.attributes.indexOf(a[0]) -
          this.$store.state.content.attributes.indexOf(b[0])
      );
      attributes = attributes.map(([_, value]) => value).join(" - ");
      return `${this.productName} - ${attributes}`;
    },
    alreadyWished() {
      return this.$store.state.content.wishList.includes(this.suggestionValue);
    },
  },
  methods: {
    suggest() {
      if (!this.alreadyWished) {
        this.loading = true;
        this.$api2
          .post("shop/suggestions", { product: this.suggestionValue })
          .then(() => this.$store.commit("content/WISH_PRODUCT", this.suggestionValue))
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
