<template>
  <div class="w-full">
    <form class="flex space-x-4 w-full justify-end" @submit.prevent="applyCoupon">
      <div class="flex-grow styled-form" style="max-width: 150px">
        <input
          name="coupon"
          class="w-full rounded-md"
          type="text"
          v-model.trim="code"
          placeholder=" "
          :class="{
            error: error,
            'bg-green-100': meta.couponName,
          }"
          required
        />
        <label for="coupon" class="floating-label" v-text="$t('checkout.enterCoupon')" />
      </div>
      <button
        v-if="!meta.couponName"
        class="flex-shrink-0 w-28 main-button"
        type="submit"
        :disabled="loading"
      >
        <div v-if="loading" class="absolute inset-0 flex justify-center align-center">
          <div class="m-auto spinner" />
        </div>
        <div
          :class="{
            invisible: loading,
          }"
          v-text="$t('common.apply')"
        />
      </button>
      <button
        v-else
        class="flex-shrink-0 w-28 px-3 uppercase relative font-medium tracking-wide rounded-md bg-gray-800 text-white py-2 hover:bg-gray-600"
        type="button"
        @click.prevent="clearCoupon"
        :disabled="loading"
      >
        <div v-if="loading" class="absolute inset-0 flex justify-center align-center">
          <div class="m-auto spinner" />
        </div>
        <div
          :class="{
            invisible: loading,
          }"
          v-text="$t('checkout.clearCoupon')"
        />
      </button>
    </form>
    <div v-if="error" class="text-red-400 text-sm ml-3" v-text="$t('errors.couponError')" />
  </div>
</template>
<script>
export default {
  name: "Coupon",
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      code: null,
      error: false,
    };
  },
  methods: {
    applyCoupon() {
      this.loading = true;
      this.error = false;
      this.api("checkoutCoupon", { coupon: this.code }, this.checkoutId)
        .then(({ success }) => {
          this.error = !success;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearCoupon() {
      this.loading = true;
      this.error = false;
      this.api("checkoutCoupon", { coupon: null }, this.checkoutId)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    meta: {
      immediate: true,
      handler(meta) {
        this.code = meta.couponCode;
      },
    },
  },
};
</script>
