<template>
  <div>
    <button
      class="w-full flex justify-center items-center relative main-button py-3"
      :disabled="!variation || !canBeOrdered"
      :class="{
        spinner: adding,
      }"
      @click.prevent="addToCart"
    >
      <svg
        class="h-5 w-5 focus:outline-none mr-2 fill-current"
        :class="{
          hidden: adding,
        }"
        viewBox="0 0 24 24"
      >
        <path
          d="M11,9H13V6H16V4H13V1H11V4H8V6H11M7,18A2,2 0 0,0 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20A2,2 0 0,0 7,18M17,18A2,2 0 0,0 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20A2,2 0 0,0 17,18M7.17,14.75L7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.59 17.3,11.97L21.16,4.96L19.42,4H19.41L18.31,6L15.55,11H8.53L8.4,10.73L6.16,6L5.21,4L4.27,2H1V4H3L6.6,11.59L5.25,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42C7.29,15 7.17,14.89 7.17,14.75Z"
        />
      </svg>
      <div
        class="font-medium"
        :class="{
          invisible: adding,
        }"
        v-text="$t('store.items.addToCart')"
      />
    </button>
    <Modal
      v-if="variation"
      :open="showPortal"
      @close="showPortal = false"
      :persistent="false"
      :key="variation.sku"
    >
      <div
        class="bg-white dark:bg-gray-900 rounded-lg shadow-2xl px-8 pt-6 pb-8 flex flex-col items-center relative dark:text-white"
      >
        <button
          class="absolute top-0 right-0 mt-4 mr-4 outline-none hover:cursor-pointer rounded-full"
          @click="showPortal = false"
        >
          <svg class="w-5 h-5 stroke-current stroke-2 text-white" viewBox="0 0 20 20">
            <path stroke-width="1.06" d="M16,16 L4,4" />
            <path stroke-width="1.06" d="M16,4 L4,16" />
          </svg>
        </button>
        <div class="flex items-center">
          <svg class="mr-1 h-8 w-8 fill-current text-green-400" viewBox="0 0 24 24">
            <path
              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            />
          </svg>
          <h4 class="font-medium" v-text="$t('store.items.addedToCart')" />
        </div>
        <div class="flex justify-between items-center mt-4 mb-8">
          <div class="mr-2 md:mr-4 justify-center items-center hidden sm:flex bg-white p-4">
            <img
              :src="
                imgixClient.buildURL(variation.thumbnail.src, {
                  auto: 'format,compress',
                  ch: 'DPR',
                  q: 45,
                  fit: 'clip',
                  h: 64,
                })
              "
              :alt="variation.thumbnail.description"
              class="object-contain"
              style="width: 80px; height: 64px"
            />
          </div>
          <p class="my-4 font-medium" v-text="variation.name" />
        </div>
        <button
          class="w-full mb-4 main-button outline"
          v-text="$t('store.navigation.continueShopping')"
          @click="showPortal = false"
        />
        <router-link class="w-full text-center main-button" v-text="$t('common.cart')" to="/cart" />
      </div>
    </Modal>
  </div>
</template>
<script>
import imgixClient from "../../services/imgixClient";
import Modal from "../shared/Modal.vue";

export default {
  name: "AddToCart",
  components: {
    Modal,
  },
  props: {
    quantity: {
      type: Number,
      default: 1,
    },
    variation: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showPortal: false,
      imgixClient,
      adding: false,
    };
  },
  computed: {
    canBeOrdered() {
      return this.variation?.availability?.canBeOrdered || false;
    },
  },
  methods: {
    async addToCart() {
      if (!this.canBeOrdered) return;
      try {
        this.adding = true;
        this.$store.commit("cart/ADD_TO_CART", this.variation.toCart());
        this.showPortal = true;
        this.$emit("addedToCart");
        this.$store.dispatch("shared/sendAnalytics", {
          event: "add_to_cart",
          value: (this.variation.subTotal - this.variation.totalDiscount) / 100,
          items: [this.variation.googleItem()],
        });
      } catch (error) {
        this.$rollbar.error(error);
        throw error;
      } finally {
        this.adding = false;
      }
    },
  },
  watch: {
    quantity: {
      immediate: true,
      deep: true,
      handler(qty) {
        this.variation.setQuantity(qty);
      },
    },
  },
};
</script>
