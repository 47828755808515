<template>
  <form @submit="checkForm" method="post" novalidate="true" class="flex flex-col items-end text-sm">
    <p v-text="$t('store.info.notify')" />
    <div class="styled-form relative">
      <input
        name="subscribe"
        class="w-full rounded-lg"
        type="email"
        placeholder=" "
        v-model="email"
      />
      <label class="floating-label" v-text="$t('common.email')" />
      <div
        class="absolute inset-0"
        :class="{
          spinner: loading,
          hidden: !loading,
        }"
      />
      <button
        type="submit"
        class="absolute right-0 top-0 mr-2 bottom-0 font-bold rounded focus:outline-none dark:text-white"
        :disabled="loading"
        :aria-label="$t('newsletter.subscribe')"
      >
        <svg class="fill-current" height="28" width="28" viewBox="0 0 24 24">
          <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
        </svg>
      </button>
    </div>
    <div v-if="error" v-text="error" class="mx-2 mt-1 text-red-400" />
    <div v-if="success" v-text="success" class="mt-1 text-green-400" />
  </form>
</template>
<script>
export default {
  name: "NotifyInStock",
  props: {
    listingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      loading: false,
      error: "",
      success: "",
    };
  },
  methods: {
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(e) {
      this.success = "";
      this.error = "";
      if (!this.email) {
        this.error = this.$t("formValidation.emailRequired");
      } else if (!this.validEmail(this.email)) {
        this.error = this.$t("formValidation.invalidEmail");
      } else {
        this.notify();
      }
      return e.preventDefault();
    },
    async notify() {
      this.loading = true;
      try {
        await this.$api2.post("shop/notify-instock", {
          listingId: this.listingId,
          email: this.email,
          fullfilmentCenterId: "olsremma",
        });
        this.success = this.$t("store.info.notifySuccess");
      } catch (e) {
        console.error(e);
        this.error = this.$t("errors.unknownError");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
