<template>
  <div>
    <router-link to="/">
      <img
        :src="imgixClient.buildURL('logos/an_logo.svg', {auto: 'format,compress', ch: 'DPR', q: 45, w: 152, fit: 'clip'})"
        alt="add:north 3D filaments"
        style="width: 152px; height: 40px"
        width="152"
        height="40"
      />
    </router-link>
    <h3 class="w-full mt-6 mb-4" v-text="$t('confirmation.header')" />
    <h5 class="mb-2" v-text="$t('confirmation.thanks')" />
    <div class="text-sm mb-4" v-text="$t('confirmation.processing')" />
    <div class="text-sm mb-8" v-html="$t('confirmation.orderId', { orderId })" />
    <router-link
      to="/"
      class="block px-4 uppercase relative text-center font-medium tracking-wide rounded-md py-4 bg-gray-800 border border-gray-800 text-white hover:bg-gray-600"
      v-text="$t('confirmation.backToSite')"
    />
  </div>
</template>
<script>
import imgixClient from '../../services/imgixClient';

export default {
  name: 'CheckoutConfirmation',
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imgixClient,
    }
  },
  created() {
    this.$store.commit('cart/CLEAR_CART')
  },
  metaInfo() {
    return {
      title: this.$t('confirmation.header'),
    }
  },
};
</script>
