<template>
  <section class="my-12 pb-12 flex flex-col w-full items-center dark:text-gray-100">
    <article class="flex flex-wrap w-full justify-center">
      <div class="max-w-sm xxl:max-w-lg mx-6 my-2 relative">
        <img :src="farm" alt="Origin / production of PLA" class="w-full" />
        <img :src="corn" alt="Origin / production of PLA" class="absolute right-0 bottom-0" />
      </div>
      <div class="max-w-md mx-6 my-2">
        <h3 class="font-medium" v-text="$t('sustainability.header1')" />
        <p class="mb-2 text-lg font-light" v-html="$t('sustainability.subheader1')" />
        <p class="mb-2" v-html="$t('sustainability.text1')" />
      </div>
    </article>
    <article class="flex justify-around w-full flex-wrap my-12">
      <div class="max-w-md mx-6 my-2">
        <h3 class="font-medium" v-text="$t('sustainability.header2')" />
        <p class="mb-2 text-lg font-light" v-html="$t('sustainability.subheader2')" />
        <p class="mb-2" v-html="$t('sustainability.text2')" />
      </div>
      <div class="max-w-md mx-6 my-2">
        <h3 class="font-medium" v-text="$t('sustainability.header3')" />
        <p class="mb-2 text-lg font-light" v-html="$t('sustainability.subheader3')" />
        <p class="mb-2" v-html="$t('sustainability.text3')" />
      </div>
    </article>
    <article>
      <h2 class="font-medium mx-4" v-text="$t('sustainability.whatWeDo')" />
      <div class="flex justify-center w-full flex-wrap text-gray-700">
        <div class="max-w-lg m-4 hidden xxl:block">
          <img :src="plant" alt="Recycled materials" class="w-full" />
        </div>
        <div class="max-w-lg m-4 pb-8 pr-4 pt-4 bg-white flex overflow-hidden shadow-md">
          <div class="flex-shrink-0 hidden sm:flex items-center relative mr-4">
            <div class="" style="width: 150px; height: 150px; margin-left: -40px">
              <img :src="recycle" alt="Recycled materials" class="w-full" />
            </div>
          </div>
          <div class="flex flex-col pl-4 justify-center">
            <h4 class="font-medium" v-text="$t('sustainability.actionHeader1')" />
            <p class="mb-4" v-html="$t('sustainability.actionText1')" />
            <div>
              <router-link
                class="text-white bg-gray-800 hover:text-white hover:bg-black text-lg font-semibold rounded-lg px-6 py-2"
                to="/product/rABS"
                v-text="$t('sustainability.actionButton1')"
              />
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="flex justify-center w-full flex-wrap text-gray-700">
      <article class="max-w-lg m-4 pb-8 pr-4 pt-4 bg-white flex overflow-hidden shadow-md">
        <div class="flex-shrink-0 hidden sm:flex items-center relative mr-4">
          <div class="" style="width: 150px; height: 150px; margin-left: -40px">
            <img :src="readd" alt="RE-ADD recycle" class="w-full" />
          </div>
        </div>
        <div class="flex flex-col pl-4 justify-center">
          <h4 class="font-medium" v-text="$t('sustainability.actionHeader2')" />
          <p class="mb-4" v-html="$t('sustainability.actionText2')" />
          <div>
            <router-link
              class="text-white bg-gray-800 hover:text-white hover:bg-black text-lg font-semibold rounded-lg px-6 py-2"
              to="/re-add"
              rel="noreferrer"
              v-text="$t('sustainability.actionButton2')"
            />
          </div>
        </div>
      </article>
      <article class="max-w-lg m-4 pb-8 pr-4 pt-4 bg-white flex overflow-hidden shadow-md">
        <div class="flex-shrink-0 hidden sm:flex items-center relative mr-4">
          <div class="" style="width: 150px; height: 150px; margin-left: -40px">
            <img :src="nature" alt="PLA made of Swedish cellulose" class="w-full" />
          </div>
        </div>
        <div class="flex flex-col pl-4 justify-center">
          <h4 class="font-medium" v-text="$t('sustainability.actionHeader3')" />
          <p class="mb-4" v-html="$t('sustainability.actionText3')" />
          <div>
            <router-link
              class="text-white bg-gray-800 hover:text-white hover:bg-black text-lg font-semibold rounded-lg px-6 py-2"
              to="/rnd"
              v-text="$t('sustainability.actionButton3')"
            />
          </div>
        </div>
      </article>
    </article>
  </section>
</template>
<script>
import imgixClient from '../services/imgixClient';

export default {
  name: 'Sustainability',
  data() {
    return {
      farm: imgixClient.buildURL('sustainability/farm.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 576, fit: 'clip'
      }),
      corn: imgixClient.buildURL('sustainability/corn.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 100, fit: 'clip'
      }),
      recycle: imgixClient.buildURL('sustainability/recycle.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 150, fit: 'clip'
      }),
      readd: imgixClient.buildURL('sustainability/readdlogo.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 150, fit: 'clip'
      }),
      nature: imgixClient.buildURL('sustainability/nature.png', {
        auto: 'format,compress', ch: 'DPR', q: 45, w: 150, fit: 'clip'
      }),
      plant: imgixClient.buildURL('sustainability/plant.png', {
        auto: 'format', ch: 'DPR', q: 45, w: 576, fit: 'clip'
      }),
    };
  },
};
</script>
