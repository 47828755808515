<template>
  <div class="my-3 md:mt-6 flex flex-grow justify-center" style="min-height: 50vh">
    <div class="mx-2 w-full">
      <router-link
        v-if="itemsInCart"
        to="/checkout"
        class="lg:hidden main-button flex w-full justify-center items-center"
        v-text="$t('store.navigation.goToCheckout')"
      />
      <h5
        class="font-bold mb-2 mt-4 dark:text-white"
        v-text="$tc('store.items.itemsIncart', itemsInCart, { items: itemsInCart })"
      />
      <h6 v-if="!itemsInCart" class="my-6 dark:text-white" v-text="$t('store.items.noItems')" />
      <router-link
        v-if="!itemsInCart"
        class="uppercase main-button w-full"
        v-text="$t('store.navigation.continueShopping')"
        to="/shop"
      />
      <div class="w-full flex items-center flex-col lg:flex-row lg:items-start lg:space-x-8">
        <div class="w-full">
          <ul class="w-full list-none mb-2 shadow-lg rounded-lg bg-white divide-y">
            <cart-item
              class="relative p-3 md:p-4 rounded-md flex flex-wrap justify-end overflow-visible w-full"
              v-for="(item, index) in items"
              :key="item.sku"
              :item="item"
              :index="index"
            />
          </ul>
          <div
            v-if="isBackOrder"
            class="mt-4 ml-2 pl-2 text-sm border-l-4 border-red-400"
            v-text="$t('checkout.isBackOrder')"
          />
          <button
            v-if="itemsInCart"
            @click.prevent="$store.commit('cart/CLEAR_CART')"
            class="flex items-center ml-6 mt-4 mb-8 text-gray-700 dark:text-gray-100 dark:hover:text-gray-200 hover:text-gray-500 font-medium text-sm sm:text-base"
          >
            <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
              <path
                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
              />
            </svg>
            <p class="ml-2" v-text="$t('store.items.clearCart')" />
          </button>
        </div>
        <div class="w-auto flex-shrink-0 flex flex-col">
          <ul
            class="list-none my-6 lg:mt-0 shadow-lg rounded-lg bg-white mx-auto"
            v-if="itemsInCart"
            style="min-width: 280px"
          >
            <li class="px-4 py-2 flex-shrink-0 flex justify-between border-b-2">
              <div
                class="flex-grow pr-4 font-medium text-gray-900"
                v-text="$t('store.items.total')"
              />
              <div class="flex-shrink-0 text-right">
                <div class="font-medium text-gray-900">
                  {{
                    grandTotal
                      | currency({
                        currency: $store.state.store.currency,
                        showWithVat: false,
                        precision: 2,
                      })
                  }}
                </div>
                <div
                  class="text-sm text-gray-600 leading-tight"
                  v-text="
                    $t($store.state.store.showWithVat ? 'store.vat.incVat' : 'store.vat.exVat')
                  "
                />
              </div>
            </li>
            <li class="px-4 py-1 flex justify-between text-gray-600 w-full">
              <div
                class="flex-grow pr-4"
                v-text="$tc('store.items.totalItems', itemsInCart, { items: itemsInCart })"
              />
              <div class="flex-shrink-0 text-right">
                {{
                  subTotal
                    | currency({
                      currency: $store.state.store.currency,
                      showWithVat: false,
                      precision: 2,
                    })
                }}
                <div class="text-xs text-gray-500 leading-none" v-text="$t('store.vat.exVat')" />
              </div>
            </li>
            <li class="px-4 py-1 flex justify-between text-gray-600" v-if="totalDiscount > 0">
              <div class="flex-gow pr-4" v-text="$t('common.discount')" />
              <div class="flex-shrink-0 text-right text-red-400">
                {{
                  -totalDiscount
                    | currency({
                      currency: $store.state.store.currency,
                      showWithVat: false,
                      precision: 2,
                    })
                }}
              </div>
            </li>
            <li class="px-4 py-1 flex justify-between text-gray-600">
              <div class="flex-grow pr-4" v-text="$t('store.shipping.shipping')" />
              <div class="flex-shrink-0 text-right text-xs leading-6">
                {{ $t("store.shipping.shippingAddedLater") }}
              </div>
            </li>
            <li class="px-4 py-1 flex justify-between text-gray-600">
              <div class="flex-grow pr-4 capitalize" v-text="$t('store.vat.vat')" />
              <div class="flex-shrink-0 text-right">
                {{
                  vat
                    | currency({
                      currency: $store.state.store.currency,
                      showWithVat: false,
                      precision: 2,
                    })
                }}
              </div>
            </li>
          </ul>
          <router-link
            v-if="itemsInCart"
            to="/checkout"
            class="mt-2 mb-4 main-button flex w-full justify-center items-center"
            v-text="$t('store.navigation.goToCheckout')"
          />
        </div>
      </div>
    </div>
    <!-- <pre>{{ JSON.stringify(items, null, 2) }}</pre> -->
  </div>
</template>
<script>
import CartItem from "./CartItem.vue";
import api from "@/services/api2";
import Variation from "@/services/variation2";

export default {
  name: "Cart",
  components: {
    CartItem,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    rawItems() {
      return this.$store.state.cart.products;
    },
    itemsInCart() {
      return this.$store.getters["cart/itemsInCart"];
    },
    agreements() {
      return this.$store.getters["account/agreements"];
    },
    subTotal() {
      return this.items.reduce((c, { subTotal }) => c + subTotal, 0);
    },
    totalDiscount() {
      return this.items.reduce((c, { totalDiscount }) => c + totalDiscount, 0);
    },
    isBackOrder() {
      return this.items.some((i) => i.availability.isBackOrder);
    },
    vat() {
      return this.items.reduce((c, { vat }) => c + vat, 0);
    },
    grandTotal() {
      return this.subTotal + this.vat - this.totalDiscount;
    },
  },
  methods: {
    async getItems() {
      const newItems = await Promise.all(
        this.rawItems.map(({ id, quantity }) => this.getItem(id, quantity))
      );
      this.items.splice(0, this.items.length, ...newItems.filter((i) => !!i));
      this.sendAnalytics();
    },
    async getItem(id, quantity) {
      try {
        const { data } = await api.get(`catalog/variations/${id}`, {
          params: {
            language: process.env.VUE_APP_LANGUAGE,
          },
        });
        const product = new Variation(this.$store, data, quantity);
        product.getAvailability();
        return product;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          rollbar.error(`Could not load cart view (${id}) not found in catalog`);
        } else {
          rollbar.error(error);
        }
        return null;
      }
    },
    sendAnalytics() {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "view_cart",
        value:
          this.items.reduce((c, { subTotal, totalDiscount }) => c + subTotal - totalDiscount, 0) /
          100,
        items: this.items.map((p) => p.googleCart),
      });
    },
  },
  watch: {
    rawItems: {
      immediate: true,
      handler: "getItems",
    },
  },
};
</script>
