<template>
  <div
    class="flex flex-wrap items-center"
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
  >
    <div
      v-for="({ label, url }) in refinements"
      :key="label"
      class="flex flex-wrap -mx-1 -my-1"
    >
      <div class="flex justify-center items-center align-center rounded-md text-gray-700 bg-gray-300 dark:bg-white text-xs font-normal py-2 px-3 mx-2 my-2">
        <div class="text-xs font-normal mr-1" v-text="label" />
        <router-link
          :to="url"
          class="flex justify-center items-center align-center rounded-md"
          v-slot="{ navigate, href }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            itemprop="url"
            rel="nofollow"
            aria-label="clear"
          >
            <svg class="h-4 w-4 focus:outline-none" viewBox="0 0 24 24">
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KnowledgeCurrentRefinements',
  props: {
    searchState: {
      type: Object,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
  },
  computed: {
    refinements() {
      const { query, categories, tags } = this.searchState
      const refinements = []
      if (query) refinements.push({ label: `"${query}"`, url: this.createURL({ query: null, page: 1 }) })
      if (categories.length) refinements.push({ label: categories.join(' > '), url: this.createURL({ categories: [], page: 1 }) })
      tags.forEach(tag => {
        const newTags = [...tags]
        const index = newTags.find(i => i === tag)
        newTags.splice(index, 1)
        refinements.push({ label: `#${tag}`, url: this.createURL({ tags: newTags, page: 1 }) })
      })
      return refinements
    }
  },
};
</script>
