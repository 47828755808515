<template>
  <article>
    <div
      v-if="searching"
      class="h-40 flex justify-center items-center pb-10 max-w-full dark:text-white"
    >
      <div class="spinner large inline text-sm pt-12" v-text="`${$t('common.loading')}...`" />
    </div>
    <div v-else class="w-full">
      <div v-if="!results.length" class="text-center dark:text-white">
        <div class="my-6" v-text="$t('shop.noResults')" />
      </div>
      <div class="flex justify-center">
        <div
          :class="{
            'space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-3 md:gap-4 xxl:grid-cols-3':
              grid === 'gallery',
            'w-full space-y-3': grid !== 'gallery',
          }"
        >
          <component
            v-for="(product, index) in results"
            :key="product.objectID"
            :is="productComponent"
            :product="product"
            :index="index"
            listId="shop_list"
            listName="Shop list"
          />
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import ProductCardDefault from "../store/ProductCardDefault.vue";
import ProductCardHorizontal from "../store/ProductCardHorizontal.vue";
import ProductCardHorizontalTight from "../store/ProductCardHorizontalTight.vue";

export default {
  name: "SearchResults",
  components: {
    ProductCardDefault,
    ProductCardHorizontal,
    ProductCardHorizontalTight,
  },
  props: {
    searchState: {
      type: Object,
      required: true,
    },
    searching: {
      type: Boolean,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      analyticsDelay: null,
    };
  },
  computed: {
    grid() {
      return this.searchState.grid;
    },
    productComponent() {
      if (this.grid === "list") return "ProductCardHorizontal";
      if (this.grid === "compact") return "ProductCardHorizontalTight";
      return "ProductCardDefault";
    },
  },
  methods: {
    sendAnalytics(items) {
      if (items.length) {
        if (this.analyticsDelay) clearTimeout(this.analyticsDelay);
        this.analyticsDelay = setTimeout(() => {
          this.$store.dispatch("shared/sendAnalytics", {
            event: "view_item_list",
            item_list_name: "Shop list",
            item_list_id: "shop_list",
            items: items.map((product, index) =>
              product.googleItem("Shop list", "shop_list", index)
            ),
          });
        }, 1000);
      }
    },
  },
  watch: {
    results: {
      immediate: true,
      handler(items) {
        this.sendAnalytics(items);
      },
    },
  },
  jsonld() {
    if (!this.results.length) return {};
    return [
      {
        "@context": "http://schema.org/",
        "@type": "ItemList",
        itemListElement: this.results.map((item, position) => ({
          "@type": "ListItem",
          position,
          url: `https://addnorth.${process.env.VUE_APP_DOMAIN}/${item.productLink}`,
        })),
      },
      ...this.results.map((product) => product.jsonld),
    ];
  },
};
</script>
