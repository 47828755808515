var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-full flex flex-col items-center pb-12"},[_c('ContactForm',{staticClass:"my-8 px-8 rounded-lg"}),_c('div',{staticClass:"flex w-full mb-8 md:order-first justify-center"},[_c('div',{staticClass:"flex-grow hidden md:block"},[_c('Map',{staticClass:"w-full h-full",attrs:{"markers":[
          {
            lat: 57.63545979504315,
            lng: 13.620028565780558,
            icon: {
              url: _vm.imgixClient.buildURL('mapMarkers/addnorth_black.png', {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                fit: 'clip',
                w: 30,
              }),
            },
          },
          {
            lat: 59.3418553,
            lng: 18.0375072,
            icon: {
              url: _vm.imgixClient.buildURL('mapMarkers/addnorth_black.png', {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                fit: 'clip',
                w: 30,
              }),
            },
          },
        ]}})],1),_c('div',{staticClass:"py-2 px-2 md:pl-6 md:pr-12 dark:text-gray-100"},[_c('SocialMedia'),_c('h4',{staticClass:"font-medium mt-1"},[_vm._v("Add North 3D AB")]),_c('div',{staticClass:"mt-1 font-text-gray-700"},[_c('p',{staticClass:"text-sm",domProps:{"textContent":_vm._s(`${_vm.$t('forms.billing.orgNbr')}: 559063-0504`)}}),_c('p',{staticClass:"text-sm",domProps:{"textContent":_vm._s(`${_vm.$t('forms.billing.vatNbr')}: SE559063050401`)}}),_c('p',{staticClass:"font-bold mt-6",domProps:{"textContent":_vm._s(_vm.$t('contact.office'))}}),_c('p',[_vm._v("Torsgatan 48B")]),_c('p',[_vm._v("113 37, Stockholm")]),_c('p',[_vm._v(_vm._s(_vm.$t("contact.sweden")))]),_c('p',{staticClass:"font-bold mt-6"},[_vm._v(_vm._s(_vm.$t("contact.production")))]),_c('p',[_vm._v("Ulricehamnsvägen 11")]),_c('p',[_vm._v("514 62, Ölsremma")]),_c('p',[_vm._v(_vm._s(_vm.$t("contact.sweden")))]),_c('div',{staticClass:"text-xs mt-6"},[_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.$t("contact.invoicingAddress")))]),_c('p',[_vm._v("Add North 3D AB")]),_c('p',[_vm._v("Box 4, FE-35194")]),_c('p',[_vm._v("737 21, Fagersta")]),_c('p',[_vm._v(_vm._s(_vm.$t("contact.sweden")))])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }