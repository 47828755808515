<template>
  <div class="my-2 sm:my-4 rounded-md border px-2 sm:px-4 divide-y">
    <div
      v-for="{ title, text, link, linkText, logout } in summaries"
      :key="title"
      class="py-2 flex items-center"
    >
      <div class="text-gray-500 text-sm flex-shrink-0" style="width: 135px" v-text="title" />
      <div class="flex flex-shrink flex-grow overflow-hidden">
        <div class="flex-shrink flex-grow text-xs" v-text="text" />
        <router-link
          v-if="link"
          class="text-xs text-gray-500 underline flex-shrink-0 ml-2"
          :to="link"
          v-text="linkText"
        />
        <div
          v-if="logout"
          class="text-xs text-gray-500 underline flex-shrink-0 ml-2 hover:cursor-pointer"
          @click="signOut"
          v-text="$t('common.logout')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { logOut } from "@/services/auth";

export default {
  name: "CheckoutSummary",
  props: {
    checkoutId: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      default: null,
    },
    details: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    completedSteps: {
      type: Array,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
  },
  computed: {
    summaries() {
      const summaries = [];
      this.completedSteps.forEach((step) => {
        if (step === "account") {
          summaries.push({
            step,
            title: this.$t("common.account"),
            text: !this.meta.accountId ? this.$t("common.guest") : this.meta.accountName,
            link: !this.meta.accountId ? `/checkout/${this.checkoutId}/account` : null,
            linkText: this.$t("common.login"),
            logout: this.meta.accountId ? true : null,
          });
        } else if (step === "address") {
          const {
            company,
            email,
            firstName,
            lastName,
            address1,
            address2,
            zip,
            state,
            city,
            country,
          } = this.details.billing;
          let billingText = this.info.company ? company : `${firstName} ${lastName}`;
          billingText += `, ${address1}`;
          if (address2) billingText += `, ${address2}`;
          billingText += `, ${zip}`;
          if (["US", "CA", "AU"].includes(country) && state) billingText += `, ${state}`;
          billingText += `, ${city}`;
          billingText += `, ${email}`;
          summaries.push({
            step,
            title: this.$t(
              !this.details.differentShipping ? "common.address" : "store.shipping.billingDetails"
            ),
            text: billingText,
            link: this.step !== "address" ? `/checkout/${this.checkoutId}/address` : null,
            linkText: this.$t("common.change"),
          });
          if (this.details.differentShipping) {
            const {
              company,
              email,
              firstName,
              lastName,
              address1,
              address2,
              zip,
              state,
              city,
              country,
            } = this.details.shipping;
            let billingText = this.info.company ? company : `${firstName} ${lastName}`;
            billingText += `, ${address1}`;
            if (address2) billingText += `, ${address2}`;
            billingText += `, ${zip}`;
            if (["US", "CA", "AU"].includes(country) && state) billingText += `, ${state}`;
            billingText += `, ${city}`;
            billingText += `, ${email}`;
            summaries.push({
              step,
              title: this.$t("store.shipping.shippingDetails"),
              text: billingText,
              link: this.step !== "address" ? `/checkout/${this.checkoutId}/address` : null,
              linkText: this.$t("common.change"),
            });
          }
        }
        if (step === "shipping") {
          summaries.push({
            step,
            title: this.$tc("store.shipping.shippingMethod", 0),
            text: `${
              this.meta.shippingOption.carrierName === "Email"
                ? ""
                : this.meta.shippingOption.carrierName
            } ${this.meta.shippingOption.serviceName}${
              this.options.pickupAgentName ? `, ${this.options.pickupAgentName}` : ""
            }`,
            link: this.step !== "shipping" ? `/checkout/${this.checkoutId}/shipping` : null,
            linkText: this.$t("common.change"),
          });
        }
        if (step === "payment") {
          summaries.push({
            step,
            title: this.$tc("store.payment.paymentMethod", 0),
            text: this.$t(`store.payment.options.${this.meta.paymentMethod.id}`),
            link: this.step !== "payment" ? `/checkout/${this.checkoutId}/payment` : null,
            linkText: this.$t("common.change"),
          });
        }
      });
      return summaries;
    },
  },
  methods: {
    signOut() {
      logOut().then(() => this.api("checkoutAccount", { accountType: "guest" }, this.checkoutId));
    },
  },
};
</script>
