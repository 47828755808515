<template>
  <section class="w-full text-white">
    <div
      class="knowledge-container flex justify-center"
      :class="{
        'collapsed': collapsed
      }"
    >
      <div class="flex flex-col items-center">
        <h1 class="font-bold leading-none whitespace-normal text-center flex-shrink" v-text="text" />
        <time v-if="publishedDate" class="py-2 font-light text-lg whitespace-normal flex-shrink" :datetime="publishedDate" v-text="publishedDate" />
        <div
          v-if="tags.length"
          class="flex flex-wrap justify-center"
        >
          <router-link
            v-for="tag in tags"
            :key="tag"
            :to="`/knowledge/browse?tags=${tag}`"
            rel="nofollow"
            class="px-2 py-1 m-1 text-xs font-medium uppercase rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-700 dark:bg-gray-500 dark:hover:bg-gray-600 dark:text-white"
            v-text="tag"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'KnowledgeBase',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Knowledge base'
    },
    publishedDate: {
      type: String,
      default: null
    },
    tags: {
      type: Array,
      default: () => ([])
    },
  },
};
</script>
<style scoped>
.knowledge-container {
  padding: calc(2rem + 30px) 0 calc(2rem) 0;
}
.knowledge-container.collapsed {
  padding: calc(1rem + 30px) 0 calc(1rem) 0;
}
@media (min-width: 1024px) {
  .knowledge-container {
    padding: calc(4rem + 40px) 0 calc(4rem) 0;
  }
  .knowledge-container.collapsed {
    padding: calc(4rem + 40px) 0 calc(4rem) 0;
  }
}

body:not(.dark) .knowledge-container {
  background: rgb(31,41,55);
  background: linear-gradient(90deg, rgba(31,41,55,1) 0%, rgba(75,85,99,1) 100%);
}
</style>
